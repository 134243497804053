import { MEASUREMENT_PROTOCOL_STEPS } from '../../config/constants';

// eslint-disable-next-line
export default {
  translation: {
    dummy: 'DE Dummy',
    misc: {
      pdfDropdown: {
        measurementProtocolPdf: 'Aufmaßbogen PDF',
        initialAnalysis: 'Erstanalyse',
        initialAnalysisPdf: 'Erstanalyse PDF',
        signedProtocol: 'Unterschriebener Aufmaßbogen',
        downloadPdf: 'PDF Herunterladen',
        notConcluded: 'Bitte Aufmaßbogen abschließen',
        current: 'Zwischenstand',
      },
    },
    snackbar: {
      formSubmitSuccess: 'Aufmaßbogen erfolgreich aktualisiert!',
      formSubmitError:
        'Aufmaßbogen konnte nicht aktualisiert werden! Auf Fehler prüfen.',
      stepError: 'Fehler im Tab',
      formFirstSubmitError: 'Aufmaßbogen konnte nicht erstellt werden!',
      alertTitleSuccess: 'Erfolg!',
      alertTitleError: 'Achtung!',
    },
    MP: {
      yes: 'Ja',
      no: 'Nein',
      roofStructuresVisualDocumentation: 'Fotos/Videos bearbeiten',
      step: {
        [MEASUREMENT_PROTOCOL_STEPS.CONSUMPTION]: 'Verbrauchsdaten',
        [MEASUREMENT_PROTOCOL_STEPS.PROPERTY]: 'Grundstück',
        [MEASUREMENT_PROTOCOL_STEPS.ROOF]: 'Dach',
        [MEASUREMENT_PROTOCOL_STEPS.OBSTACLE]: 'Hindernisse',
        [MEASUREMENT_PROTOCOL_STEPS.SCAFFOLD]: 'Gerüst',
        [MEASUREMENT_PROTOCOL_STEPS.ENERGY_STORAGE_AND_METER]:
          'Speicher & Zähler',
        [MEASUREMENT_PROTOCOL_STEPS.CONNECTIONS]: 'Anschlüsse',
        [MEASUREMENT_PROTOCOL_STEPS.CURRENT_CHECKLIST]: 'Checkliste AC/DC',
        [MEASUREMENT_PROTOCOL_STEPS.CONCLUSION]: 'Abschluss',
      },
      dialogs: {
        filePreview: 'Dateiansicht',
        downloadFile: 'Datei herunterladen',
        downloadVideo: 'Zum Anschauen bitte das Video herunterladen',
        deleteFile: 'Datei löschen',
        editFile: 'Datei bearbeiten',
        save: 'Speichern',
        cancel: 'Abbrechen',
        namingError: 'Name konnte nicht geändert werden',
      },
      header: {
        mpCompleted: 'Aufmaßbogen abgeschlossen',
        mpInProgress: 'Aufmaßbogen in Arbeit',
        mpNotStarted: 'Aufmaßbogen nicht angefangen',
      },
      // whole sales-app translation file pasted under this line
      error: {
        default: 'Etwas ist schiefgelaufen',
        noConnection: 'Keine Internetverbindung',
        permissionMic:
          'Bitte geben Sie der App die Berechtigung, das Mikrofon zu benutzen',
        permissionSpeechRecognition:
          'Bitte geben Sie der App die Erlaubnis, die Spracherkennung zu verwenden',
        permissionLocation:
          'Bitte geben Sie der App die Berechtigung, auf die Standortdaten zuzugreifen',
        userNotAllowed: 'Kontotyp inkompatibel',
        leadNotFound: 'Lead konnte nicht gefunden werden',
        contactsSync: {
          permission:
            'Bitte geben Sie der App die Berechtigung, auf Ihre Kontakte zuzugreifen',
          notFound: 'Kein Kontakt gefunden',
          deleteFailed: 'Kontakt konnte nicht gelöscht werden',
        },
        fileOpenFailed: 'Datei konnte nicht geöffnet werden.',
        fileExists:
          'Datei existiert bereits. Die Datei wird nicht hochgeladen.',
        numberNotRecognized: 'Nummer konnt nicht erkannt werden',
        photoTooBig:
          'Das ausgewählte Foto ist zu groß, es sollte kleiner als %{size} sein.',
        videoTooBig:
          'Das ausgewählte Video ist zu groß, es sollte kleiner als %{size} sein.',
        fileTooBig:
          'Die ausgewählte Datei ist zu groß, es sollte kleiner als %{size} sein.',
        fileDeleteFailed: 'Datei konnte nicht gelöscht werden.',
      },
      success: {
        signatureAdded: 'Unterschrift erfolgreich hinzugefügt!',
        numberRecognized: 'Nummer erfolgreich erkannt',
      },
      notification: {
        test: 'Test notification',
      },
      entity: {
        name: {
          CALENDAR_ENTRY: 'Termin',
          IMAGE: 'Bild',
          PHONE_NUMBER: 'Kontakt',
          ADDRESS: 'Termin',
          MAP: 'Luftbild',
          LOCAL_BINARY_FILE: 'Datei',
          MISC_FILE: 'Datei',
          NOTE: 'Notiz',
          FILE: 'Datei',
        },
        action: {
          success: {
            create: '%{entity} wurde erfolgreich erstellt',
            update: '%{entity} wurde erfolgreich bearbeitet',
            delete: '%{entity} wurde wurde erfolgreich gelöscht',
          },
          error: {
            create: '%{entity} konnte nicht erstellt werden',
            update: '%{entity} konnte nicht bearbeitet werden',
            delete: '%{entity} konnte nicht gelöscht werden',
          },
        },
      },
      validation: {
        emailOpenFail: 'E-Mail-Client konnte nicht geöffnet werden',
        endDateBeforeStartDate:
          'Der Beginn des Termins darf nicht nach seinem Ende sein',
        invalidEmailAddress: 'Ungültige E-Mail-Adresse',
        invalidPhoneNumber: 'Ungültige Telefonnummer',
        leadPhaseChangeOnlyAllowedForOpenLeads:
          'Änderung der Leadphase ist nur für offene Leads erlaubt',
        leadPhaseFillInMandatoryFields: 'Bitte die Pflichtfelder ausfüllen',
        lengthZip: 'Maximal 5 Zeichen',
        numberRequired: 'Ungültige Nummer',
        onlyIntegerNumbers: 'Nur ganzzahlige Werte',
        onlyIntegerNumberFrom1: 'Nur ganzzahlige Werte größer 0',
        onlyIntegerNumbersFrom0to5: 'Nur ganzzahlige Werte zwischen 0 und 5',
        onlyIntegerNumbersFrom1to5: 'Nur ganzzahlige Werte zwischen 1 und 5',
        onlyIntegerNumbersFrom0to10: 'Nur ganzzahlige Werte zwischen 0 und 10',
        onlyIntegerNumbersFrom1to10: 'Nur ganzzahlige Werte zwischen 1 und 10',
        onlyIntegerNumbersFrom10to30:
          'Nur ganzzahlige Werte zwischen 10 und 30',
        onlyIntegerNumbersFrom1to50: 'Nur ganzzahlige Werte zwischen 1 und 50',
        onlyIntegerNumbersFrom1to100:
          'Nur ganzzahlige Werte zwischen 1 und 100',
        onlyIntegerNumbersFrom0to100:
          'Nur ganzzahlige Werte zwischen 0 und 100',
        onlyIntegerNumbersFrom0to1000:
          'Nur ganzzahlige Werte zwischen 0 und 1000',
        onlyIntegerNumbersFrom0to2000:
          'Nur ganzzahlige Werte zwischen 0 und 2000',
        onlyIntegerNumbersFrom0to100000:
          'Nur ganzzahlige Werte zwischen 0 und 100000',
        onlyIntegerNumbersFrom0to300000:
          'Nur ganzzahlige Werte zwischen 0 und 300000',
        onlyIntegerNumbersFrom0to90: 'Nur ganzzahlige Werte zwischen 0 und 90',
        onlyNumbersFrom0to30000noDecimal:
          'Nur ganzzahlige Werte zwischen 0 und 30000',
        onlyNumbersFrom0to100000noDecimal:
          'Nur ganzzahlige Werte zwischen 0 und 100000',
        onlyNumbersFrom0to100: 'Wert muss zwischen 0 und 100 liegen',
        onlyNumbersFrom10to100oneDecimal: 'Nur Werte zwischen 10,0 und 100,0',
        onlyNumbersFrom1to100oneDecimal: 'Nur Werte zwischen 1,0 und 100,0',
        onlyNumbersFrom0to100oneDecimal: 'Nur Werte zwischen 0,0 und 100,0',
        onlyNumbersFrom0to150oneDecimal: 'Nur Werte zwischen 0,0 und 150,0',
        onlyNumbersFrom0to100twoDecimal: 'Nur Werte zwischen 0,00 und 100,00',
        onlyNumbersFrom1to100twoDecimal: 'Nur Werte zwischen 1,00 und 100,00',
        onlyNumbersFrom0to1000twoDecimal: 'Nur Werte zwischen 0,00 und 1000,00',
        onlyNumbersFrom0to10000twoDecimal:
          'Nur Werte zwischen 0,00 und 10.000,00',
        onlyNumbersFrom0to100000twoDecimal:
          'Nur Werte zwischen 0,00 und 100.000,00',
        positiveNumberRequired: 'Nur positive Werte',
        required: 'Benötigt',
        atLeastOneRequired: 'Mindestens 1 benötigt',
        emptyMessage: 'Leere Nachricht ist nicht erlaubt',
        oneRequired: 'Eine Info benötigt',
        textLength: 'Maximal 50 Zeichen',
        textLengthRequired50: 'Benötigt. Maximal 50 Zeichen',
        textLengthRequired100: 'Benötigt. Maximal 100 Zeichen',
        textLengthRequired200: 'Benötigt. Maximal 200 Zeichen',
        textLengthRequired500: 'Benötigt. Maximal 500 Zeichen',
        textLength100: 'Maximal 100 Zeichen',
        textLengthNonRequired500: 'Maximal 500 Zeichen',
        textLength1500: 'Maximal 1500 Zeichen',
        validate0to100: 'Der Wert sollte zwischen 0 und 100 liegen',
        validate0to200: 'Der Wert sollte zwischen 0 und 200 liegen',
        onlyNumbersFrom10to100: 'Der Wert sollte zwischen 10 und 100 liegen',
        onlyIntegerNumbersFrom10to100:
          'Der Wert sollte zwischen 10 und 100 liegen',
        validateValueMustBeTrue: 'Benötigt',
        measurementProtocol: {
          visualRepresentationInPropertyStepNeeded:
            'Zuerst Foto/Skizze im Grundstück benötigt',
          visualRepresentationAndDeliveryZoneNeeded:
            'Foto/Skizze mit Lieferzone benötigt',
          visualRepresentationNeeded: 'Foto/Skizze benötigt',
          visualDocumentationNeeded: 'Visuelle Dokumentation benötigt',
          visualRepresentationInStepsNeeded:
            'Foto/Skizze in anderen Schritten benötigt',
          visualRepresentationScaffoldNeeded:
            'Foto/Skizze und Gerüstlängen zwischen 0,00 und 100,00 benötigt',
          adjustmentsToSolarThermalSystem: 'Benötigt',
          abortMeasurementProtocol:
            'Die Anlage kann nicht gebaut werden. Der Aufmaßbogen muss abgebrochen werden.',
          finishDrawing: 'Beenden oder löschen Sie die Zeichnung',
        },
      },
      action: {
        login: 'Einloggen',
        logout: 'Logout',
        submit: 'Bestätigen',
        save: 'Speichern',
        close: 'Schließen',
        openDetails: 'Details öffnen',
        upload: 'Hochladen',
        download: 'Herunterladen',
        delete: 'Löschen',
        back: 'Zurück',
        continue: 'Fortsetzen',
        proceed: 'Weiter',
        finish: 'Fertig',
        sync: 'Jetzt Synchronisieren',
        record: 'Aufnahme starten',
        recordLimit: '(maximal eine Minute)',
        playAudio: 'Audioaufnahme abspielen',
        addDescription: 'Beschreibung angeben',
        addAudio: 'Audioaufnahme hinzufügen',
        addAddress: 'Anschrift hinzufügen',
        addPhoneNumber: 'Telefonnummer hinzufügen',
        addEmailAddress: 'E-Mail-Adresse hinzufügen',
        add: 'Hinzufügen',
        manageNotifications: 'Benachrichtigungen verwalten',
        searchAddress: 'Adresse suchen (Str., Nr., PLZ)',
        applyFilter: 'Filter anwenden',
        addSatelliteImage: 'Luftbild hinzufügen',
        showVisualRepresentation: 'Luftbild ansehen',
        select: 'Auswählen',
        edit: 'Bearbeiten',
        cancel: 'Abbrechen',
        stop: 'Stoppen',
        downloadAll: 'Alle herunterladen',
        skip: 'Überspringen',
        send: 'Senden',
      },
      title: {
        focus: 'Fokus',
        leads: 'Leads',
        activities: 'Aktivitäten',
        map: 'Karte',
        focusHeader: 'Dein Terminplan',
        leadsHeader: 'Deine Leads',
        leadDetails: 'Dein Lead',
        activitiesHeader: 'Deine Aktivitäten',
        settings: 'Einstellungen',
        audioRecorder: 'Audioaufnahme hinzufügen',
        calendarEntryDetails: 'Termin Details',
        calendarEntriesOverdue: 'Überfällige Termine',
        confirmationEmail: 'Optionale Terminbestätigung',
        emails: 'E-Mails',
        calendar: 'Kalender',
        mailbox: 'Postfach',
        info: 'Info',
        timeline: 'Zeitleiste',
        files: 'Dateien',
        fileEdit: 'Datei bearbeiten',
        addAddress: 'Ort hinzufügen',
        createCalendarEntry: 'Neuer Termin',
        noteDetails: 'Notiz Details',
        theme: 'Theme',
        lastSync: 'Zuletzt: ',
        syncing: 'Wird synchronisiert...',
        filter: 'Filter',
        leadEdit: 'Lead bearbeiten',
        calendarEntryEdit: 'Termin bearbeiten',
        noteEdit: 'Notiz bearbeiten',
        editMeasurementProtocol: 'Aufmaßbogen bearbeiten',
        startMeasurementProtocol: 'Aufmaßbogen starten',
      },
      theme: {
        device: 'Geräteeinstellung',
        light: 'Light Modus',
        dark: 'Dark Modus',
        dialogTitle: 'Theme-Einstellungen',
      },
      dialog: {
        activity: 'Aktivität',
        addOrEditSatelliteImage: 'Luftbild anlegen / ändern',
        call: 'Anrufen',
        confirm: 'Bestätigen',
        confirmLowerValue:
          'Sicher, dass die Anzahl verringert werden soll? Bestehende Daten können durch diese Aktion verloren gehen.',
        chooseFile: 'Datei auswählen',
        choosePhotos: 'Fotos auswählen',
        chooseVideos: 'Videos auswählen',
        chooseStatus: 'Status auswählen',
        choosePhotosOrVideosFromGallery: 'Aus Galerie auswählen',
        choosePhotosOrVideosFromFiles: 'Aus Dateien auswählen',
        chooseSatelliteImageAddress: 'Adresse für Luftbild auswählen',
        chooseVisualRepresentation: 'Adresse für Luftbild auswählen',
        create: 'Neu Anlegen',
        createEntry: 'Neuer Termin',
        createLead: 'Neuer Lead',
        createNote: 'Neue Notiz',
        file: 'Foto / Datei',
        message: 'Nachricht',
        distribution: 'Vertrieb',
        phone: 'Telefon',
        sms: 'SMS',
        takePhoto: 'Foto aufnehmen',
        takeVideo: 'Video aufnehmen',
        whatsApp: 'WhatsApp',
        writeMessage: 'Nachricht schreiben',
        newAddress: 'Neue Adresse',
        confirmLogoutTitle: 'Bist du sicher, dass du dich ausloggen möchtest?',
        confirmLogoutMessage:
          'Es sind Daten / Dateien vorhanden, die noch nicht erfolgreich übertragen wurden. Ein Ausloggen führt dazu, dass diese Daten / Dateien verloren gehen und neu hochgeladen werden müssen.',
        newMessage: 'Neue Interne Nachricht an Planer',
      },
      deleteDialog: {
        titleStart: 'Bist du sicher, dass du diese',
        altTitleStart: 'Bist du sicher, dass du diesen',
        titleEnd: 'löschen möchtest?',
        subtitleStart: 'Nach dem Löschen kann die',
        altSubtitleStart: 'Nach dem Löschen kann der',
        subtitleEnd:
          'nicht mehr wiederhergestellt werden. Trotzdem fortfahren?',
        address: 'Adresse',
        calendarEntry: 'Termin',
        email: 'E-Mail-Adresse',
        file: 'Datei',
        note: 'Notiz',
        phoneNumber: 'Telefonnummer',
        delete: 'löschen',
      },
      warningDialog: {
        unsavedChangesTitle:
          'Bist du sicher, dass du diese Ansicht verlassen möchtest?',
        unsavedChangesSubtitle:
          'Wenn du jetzt fortfährst, werden deine Änderungen nicht gespeichert. Möchtest du trotzdem fortfahren?',
        unsavedChangesConfirm: 'Ohne Speichern fortfahren',
        lowerValueTitle:
          'Bist du sicher, dass die Anzahl verringert werden soll?',
        lowerValueSubtitle:
          'Bestehende Daten können durch diese Aktion verloren gehen.',
        lowerValueConfirm: 'Dennoch fortfahren',
        unfinishedInitialAnalysisTitle: 'Erstanalyse abschließen',
        unfinishedInitialAnalysisSubtitle:
          'Für diesen Phasenwechsel ist eine vollständige Erstanalyse notwendig.',
        bigProjectTitle: 'Bitte Eingaben überprüfen!',
        bigProjectSubtitle: 'Ist der Lead ein Großprojekt mit ≥ 30 kWp?',
        bigProjectConfirm: 'Ja, Großprojekt speichern',
        bigProjectCancel: 'Nein',
      },
      eventButton: {
        done: 'Termin erledigt',
        overdue: 'Überfällig erledigt',
        open: 'Termin öffnen',
      },
      date: {
        name: 'Datum',
        start: 'Start',
        end: 'Ende',
        format: 'dd.MM.yyyy',
        formatShort: 'MM.yyyy',
        formatWithoutZero: 'd.M.yyyy',
        timeFormat: 'HH:mm',
        dateTimeFormat: 'dd.MM.yyyy HH:mm',
        dateTimeFormatWithSeconds: 'dd.MM.yyyy HH:mm:ss',
        today: 'Heute',
        sunday: 'Sonntag',
        monday: 'Montag',
        tuesday: 'Dienstag',
        wednesday: 'Mittwoch',
        thursday: 'Donnerstag',
        friday: 'Freitag',
        saturday: 'Samstag',
        allDay: 'Ganztägig',
        mon: 'Mo',
        tue: 'Di',
        wed: 'Mi',
        thu: 'Do',
        fri: 'Fr',
        sat: 'Sa',
        sun: 'So',
      },
      calendarEntry: {
        address: 'Anschrift',
        allDay: 'Ganzer Tag',
        audio: 'Audioaufnahme',
        emptyFocusSection: 'Keine Termine für diesen Tag',
        entriesForDate: 'Ihre Termine für den %{date}',
        lead: 'Lead',
        leadNotChangeable: 'Lead kann nicht geändert werden',
        nothingOverdue: 'Keine überfälligen Termine',
        overdue: 'Überfälliger Termin |||| Überfällige Termine',
        period: 'Zeitraum',
        recordButtonLimit: 'Aufnahme starten (maximal eine Minute)',
        recording: 'Aufnahme läuft (maximal eine Minute)',
        type: {
          call: 'Anruf',
          email: 'E-Mail',
          task: 'Aufgabe',
          meeting: 'Meeting',
          offerMeeting: 'Angebotsbesprechung',
          measurementMeeting: 'Aufmaß',
        },
        unknownOwner: 'Unbekannter Besitzer',
        selectAddress: 'Adresse für Termin Auswählen',
        create: 'Der Termin wurde angelegt!',
      },
      lead: {
        addAdditionalEmailAddress: 'Weitere E-Mail-Adresse hinzufügen',
        addAdditionalPhoneNumber: 'Weitere Telefonnummer hinzufügen',
        address: 'Kundenadresse',
        basicFeeElectricity: 'Licht Grundgebühr (Brutto) pro Jahr (in Euro)',
        basicFeeHeat: 'Wärme Grundgebühr (Brutto) pro Jahr (in Euro)',
        business: 'Arbeit',
        contact: 'Kontaktdaten',
        currentLocation: 'Aktueller Standort',
        customerData: 'Kundendaten',
        customerType: 'Kundentyp',
        desiredBSSCapacity: 'Gewünschter Speicher / -größe (in kWh)',
        desiredPVOutput: 'Gewünschte Leistung der Anlage (in kWp)',
        distanceGroundGutter: 'Distanz Boden-Dachrinne (in m)',
        distanceGutterRidge: 'Distanz Traufe-First (in m)',
        done: 'Erledigt',
        emptyTimeline: 'Keine Termine für diesen Lead',
        familyAndFriendsEnergyConsumption:
          'Stromverbrauch Freunde und Familie pro Jahr (in kWh)',
        heatElectricity: 'Wärme Stromverbrauch pro Jahr (in kWh)',
        heatElectricityMeterNumber: 'Wärme Stromzählernummer',
        smartHome: 'Installation Smart Home (Homematic)',
        smartHomeAnswer1: 'Kein Interesse',
        smartHomeAnswer2: 'Nur Heizkörper',
        smartHomeAnswer3: 'Nur Fußbodenheizung',
        smartHomeAnswer4: 'Heizkörper und Fußbodenheizung',
        houseElectricity: 'Licht Stromverbrauch pro Jahr (in kWh)',
        houseElectricityMeterNumber: 'Licht Stromzählernummer',
        housing: 'Immobiliendaten',
        housingDescription: 'Immobilienbezeichnung',
        idLink: 'ID Link',
        leadInfo: 'Lead Info',
        length: 'Länge',
        linkCopied: 'Link wurde kopiert!',
        loadProfile: 'Lastprofil',
        lost: 'Verloren',
        mm: 'mm',
        measurementProtocol: 'Aufmaßbogen',
        measurementProtocolStart: 'Aufmaßbogen starten',
        measurementProtocolContinue: 'Aufmaßbogen fortsetzen',
        measurementProtocolShow: 'Aufmaßbogen anzeigen',
        name: 'Kundenname',
        open: 'Offen',
        source: {
          ownLead: 'Eigener Lead',
          fairLead: 'Messe Lead',
          contactPage: 'Kontaktseite',
        },
        planned: 'Vereinbart',
        prename: 'Vorname',
        priceHeat: 'Wärme Strompreis (Brutto) pro kWh (in Cent)',
        priceLight: 'Licht Strompreis (Brutto) pro kWh (in Cent)',
        private: 'Privat',
        roofAlign: 'Dachausrichtung',
        roofPitchDegree: 'Dachneigung (in Grad)',
        roofTileCoverage: 'Ziegel-Deckmaß Länge x Breite (in mm)',
        roofTileLength: 'Ziegel-Deckmaß Länge (in mm)',
        roofTileType: 'Dachziegelart',
        roofType: 'Dachform',
        roofTileWidth: 'Ziegel-Deckmaß Breite (in mm)',
        sales: 'Vertriebler',
        salutation: 'Anrede',
        secondaryAddress: 'Abweichende Immobilienadresse',
        solarEdgeWidthM: 'Breite der Solarseite des Daches (in m)',
        surname: 'Nachname',
        title: 'Titel',
        uploaded: 'Hochgeladen',
        width: 'Breite',
        won: 'Gewonnen',
        congratulations: 'Herzlichen Glückwunsch!',
        wonALead: 'Du hast einen Lead Gewonnen',
        deleteContactPrompt: {
          question:
            'Sollen die Kontaktinformationen auf dem Gerät gelöscht werden?',
          yes: 'Ja',
          no: 'Nein',
        },
        keepUp: 'Weiter so!',
        created: 'Lead wurde erfolgreich erstellt!',
      },
      leadFileEdit: {
        title: 'Dateiname ändern',
        previousLabel: 'Bisheriger Dateiname',
        newLabel: 'Neuer Dateiname',
        currentName: 'Dataiename',
      },
      email: {
        newEmails: 'Neue E-Mail |||| Neue E-Mails',
        noNewEmails: 'Keine neuen E-Mails',
      },
      measurementProtocolSteps: {
        property: {
          headerTitle: 'Grundstück',
          title: 'Nachbargrundstück',
          propertyNeighborDistanceLawsKnown:
            'Die Vorgaben der Landesbauordnung sind hinsichtlich des Abstands zum Nachbargrundstück bekannt.',
          propertyNeighborDistanceKept:
            'Wird Abstand zum Nachbargrundstück eingehalten?',
          neighboringProperty: 'Nachbargrundstück',
          aerialPhotoSketch: 'Luftbild / Skizze',
          plantDelivery: 'Lieferung der Anlage',
          annotatedSatelliteImage: 'Luftbild inklusive aller Markierungen',
          yes: 'Ja',
          no: 'Nein',
          customerBearsRisk:
            'ich beauftrage trotzdem EKD mit dem Bau der Anlage. Der Auftraggeber stellt den Auftragnehmer frei und trägt das Risiko allein.',
          abortProcess: 'Anlage kann nicht gebaut werden.',
          propertyUnderMonumentalProtectionTitle: 'Denkmalschutz',
          propertyUnderMonumentalProtectionQuestion:
            'Einschränkungen durch Denkmal- oder Ensembleschutz?',
          propertyUnderMonumentalProtectionYes:
            'der Auftraggeber übernimmt die Verantwortung über die Genehmigung durch das Denkmalschutzamt.',
          deliveryZoneTitle: 'Lieferung der Anlage',
          deliveryZoneSubTitle: 'Anlieferzone',
          propertyHasDeliveryZone:
            'Es steht eine Anlieferzone für 3-4 Paletten zur Verfügung.',
          markDeliveryZone: 'Bitte Anlieferzone im Luftbild markieren.',
          markPosition: 'Zum Markieren gewünschte Stelle tippen.',
          propertyHasSafeStorageFacility:
            'Ist eine sichere (Schutz vor Regen und Diebstahl) Lagermöglichkeit vorhanden?',
          googleMapsPhoto: 'Luftbild mit Google Maps',
          sketchOrDronePhoto: 'Foto von Skizze/Drohnenaufnahme',
          makeSketch: 'Skizze in der App anfertigen',
          showDeliveryZone: 'Anlieferzone anzeigen',
          editDeliveryZone: 'Anlieferzone bearbeiten',
          addDeliveryZone: 'Anlieferzone hinzufügen',
          storageFacilityAtGroundLevelTitle: 'Lagermöglichkeit ebenerdig',
          storageFacilityAtGroundLevel:
            'Kann die Lagermöglichkeit ebenerdig (keine starke Neigung, Bordsteinkante oder Treppe) angefahren werden?',
          storageFacilityAtGroundLevelVisualDocumentation:
            'Foto-/Videodokumentation',
        },
        obstacles: {
          headerTitle: 'Hindernisse für Gerüstbau',
          addImage: 'Hausfoto hinzufügen',
          info: 'Bitte Hindernisse in der Luftbild markieren.',
          obstacles: 'Hindernisse',
          additionalCosts: 'Mehrkosten können entstehen.',
          editObstacles: 'Hindernisse bearbeiten',
          addObstacles: 'Hindernisse hinzufügen',
          showObstacles: 'Hindernisse anzeigen',
          obstaclesExist:
            'Sind Bäume/Häuser/Störobjekte (West, Süd oder Ost) vorhanden, die höher als die Dachrinne bzw. im Umkreis von maximal 20 m sind?',
          addPhotosVideos: 'Fotos/Videos hinzufügen',
          markObstacles: 'Bitte Hindernisse im Luftbild markieren.',
          tapToMark: 'Zum Markieren gewünschte Stelle tippen.',
          drawObstacles: 'Hindernisse einzeichnen',
          ventilationPipesExist: 'Sind Be- und Entlüftungsrohre vorhanden?',
          ventilationPipeRelocationByCustomerKnownCheckbox:
            'Es ist bekannt, falls eine Versetzung erforderlich oder gewünscht ist, dass diese durch den Auftraggeber auszuführen ist.',
          unmentionedShadingNotPartOfOfferKnownTitle:
            'Alle nicht aufgeführten Verschattungen sind nicht Bestandteil des Angebots.',
          unmentionedShadingNotPartOfOfferKnownCheckbox:
            'Es ist bekannt, dass alle nicht aufgeführten Verschattungen nicht Bestandteil des Angebots sind.',
          radioYes: 'Ja',
          radioNo: 'Nein',
          obstacleVisualDocumentation: 'Foto-/Videodokumentation',
        },
        roof: {
          radioAnswerRoofTileAttachmentMortar: 'vermörtelt',
          radioAnswerRoofTileAttachmentClamp: 'geklammert',
          radioAnswerRoofTileAttachmentGlued: 'geklebt',
          radioAnswerRoofTileAttachmentCardboardDock: 'auf Pappdocken',
          radioAnswerRoofTileAttachmentSlate: 'Kunst-/Naturschiefer',
          radioAnswerRoofTileAttachmentPantile: 'Hohlpfanne',
          initialData: 'Ausgangsdaten Dach',
          mainBuilding: 'Hauptgebäude',
          secondaryBuilding: 'Nebengebäude',
          buildingsCountTextBold: 'Gebäudeanzahl',
          buildingsCountText: 'Menge zu belegender Gebäude',
          flatRoofQuestion: 'Handelt es sich bei dem Dach um ein Flachdach?',
          photographTheFlatRoofAreaBeforeIndex: 'Bitte für ',
          photographTheFlatRoofAreaAfterIndex:
            ' eine Aufnahme machen, auf der die gesamte Dachfläche zu erkennen ist.',
          photographTheRoofAreaBeforeIndex:
            'Bitte eine Aufnahme machen, auf der ',
          photographTheRoofAreaAfterIndex: ' vollständig zu erkennen ist.',
          distanceGroundGutterTextBold: 'Traufenhöhe',
          distanceGroundGutterText: 'Distanz Boden-Traufe eingeben',
          distanceGroundGutter: 'Distanz Boden-Traufe eingeben',
          roofCoveringTitle: 'Dacheindeckung',
          roofCovering: 'Um welche Art von Dacheindeckung handelt es sich?',
          roofType: 'Um welche Art von Dacheindeckung handelt es sich?',
          brick: 'Ziegel',
          trapezoidalRoof: 'Trapezblech',
          roofCoveringTrapezoidalMaterialSize:
            'Material und Größe der Dacheindeckung',
          prefa: 'PREFA',
          icopal: 'Icopal',
          other: 'Sonstige',
          bitumen: 'Bitumen',
          gravel: 'Kies oder Grünfläche',
          hohlpfanne: 'Hohlpfanne',
          pappdocken: 'Pappdocken',
          schiefer: 'Schiefer',
          otherRoofCovering: 'Sonstige Dacheindeckung',
          otherRoofStructures: 'Sonstige Dachaufbauten',
          roofStructuresExist: 'Sind Dachaufbauten vorhanden?',
          whichRoofStructuresExistQuestion:
            'Welche Dachaufbauten lassen sich vorfinden?',
          roofTrussByCustomerKnown:
            'Der Dachstuhl kann nicht eingesehen werden. Der technische Dachaufbau erfolgt durch den Auftraggeber und ist diesem bekannt.',
          roofCoveringTilesLoose:
            'Die Ziegel (Dacheindeckung) sind lose gelegt?',
          roofCoveringTilesNotApparent: 'Nicht ersichtlich',
          roofCoveringAdditionalCostKnown:
            'Es ist bekannt, dass Mehrkosten in Höhe von 150,– € zzgl. MwSt. pro kWp anfallen.',
          chooseRoofTileAttachment: 'Befestigung auswählen',
          notPossible: 'Nicht möglich, ',
          brickMeasure: 'Ziegelmaß',
          brickCoverage: 'Ziegeldeckmaß (sichtbarer Teil)',
          executionSubstructure: 'Ausführung Unterkonstruktion',
          facilityCanNotBeBuilt: 'Anlage kann nicht gebaut werden:',
          addPhotoOrVideo: 'Bitte Foto-/Videodokumentation ergänzen.',
          costMayArise: 'Mehrkosten können entstehen.',
          checkboxLabel:
            'Die Arten der Ausführung für die Unterkonstruktion mit Überstand sind bekannt und zulässig.',
          roofStructureTitle: 'Dachaufbauten',
          roofTrussVisibleBold: 'Dachstuhl',
          roofTrussVisibleQuestion: 'Kann der Dachstuhl eingesehen werden?',
          beamDimensions: 'Eingabe Balkenmaße (in cm)',
          barSpacing: 'a) Balkenabstand',
          barWidth: 'b) Balkenbreite',
          barHeight: 'c) Balkenhöhe',
          material: 'Material',
          roofTrussBeamMaterialWood: 'Holz',
          roofTrussBeamMaterialMetal: 'Metall',
          roofTrussBeamMaterialOther: 'Sonstiges',
          broad: 'Breite',
          height: 'Höhe',
          radioYes: 'Ja',
          radioNo: 'Nein',
          enterMaterials: 'Material eingeben',
          roofSurfaces: 'Dachflächen',
          roofAreaVisualDocumentation: 'Fotodokumentation Dachfläche',
          roofAmount: 'Menge zu belegender Dachflächen',
          roofAreaBold: 'Flächengröße',
          roofAreaLengthLabel: 'Länge der Dachfläche',
          roofAreaWidthLabel: 'Breite der Dachfläche',
          roofAreaTiltLabel: 'Gradzahl der Neigung',
          cableTitle: 'DC-Kabelverlegung',
          outerFacadeCable:
            'Ist eine DC-Kabelführung an der Außenfassade möglich?',
          roofSkinCable: 'Ist eine DC-Kabelführung durch die Dachhaut möglich?',
          cableLength:
            'Beträgt die DC-Kabelführung von Hauseintritt bis Speicher-/Wechselrichterstandort mehr als 10 m?',
          storageDistance:
            'Wie weit ist das Gebäude mit dem Speicher/Zähler entfernt?',
          earthworkKnownQuestion:
            'Es ist bekannt, dass ggf. hierbei Erdarbeiten notwendig sind. Diese sind vom Auftraggeber durchzuführen.',
          cableQuestionYesCheckboxLabel:
            'Es ist bekannt, dass hierbei Mehrkosten für den DC-Überspannungsschutz in Höhe von 599,– € zzgl. Mehrwertsteuer anfallen. Mögliche Erdarbeiten sind durch den Auftraggeber zu übernehmen.',
          aluminiumLength: 'Aluminium größer/gleich 0,7 mm Stärke',
          steelLength: 'Stahl größer/gleich 0,5 mm Stärke',
          aluminiumNotBuildable: 'Aluminium kleiner 0,7 mm Stärke',
          steelNotBuildable: 'Stahl kleiner 0,5 mm Stärke',
          flashUnitExists: 'Blitzanlage',
          satelliteDishExists: 'Satellitenschüssel',
          pipesExist: 'Rohre',
          otherRoofStructuresExist: 'Sonstige',
          parapetExistsQuestion: 'Attika vorhanden?',
          parapetSizes: 'Größe (in cm)',
          parapetWidthLabel: 'Breite (a)',
          parapetHeightLabel: 'Höhe (b)',
          dcCableRoutingAlongRoofCladdingVisualDocumentation:
            'Fotos/Videos bearbeiten',
          dcCableRoutingAlongOutsideFacadeVisualDocumentation:
            'Fotos/Videos bearbeiten',
          flatRoofVisualDocumentation: 'Fotos/Videos bearbeiten',
        },
        scaffold: {
          obstaclesToScaffoldSafetyExist:
            'Sind Hindernisse (z. B. Teich, Kellertreppe, Beete, Graben) vorhanden, die einen sicheren Stand des Gerüsts verhindern?',
          yes: 'Ja',
          no: 'Nein',
          obstacle: 'Hindernisse',
          scaffoldPosition: 'Gerüst Position',
          scaffoldLength: 'Länge Gerüst',
          scaffoldObstacleImageIdsSubtitle: 'Mehrkosten können entstehen.',
          scaffoldObstacleImageIdsItem: 'Fotos/Videos hinzufügen',
          buildingFacadeInsulated: 'Ist die Gebäudefassade gedämmt?',
          anchoring: 'Gerüstverankerung',
          anchoragePossiblyNeededKnown:
            'Es ist bekannt, dass falls die Traufhöhe mehr als vier Meter beträgt, eine Verankerung in der Gebäudefassade notwendig sein könnte.',
          isScaffoldFootprintOnPublicFootpath:
            'Befindet sich die Stellfläche des Gerüsts auf öffentlichen Gehwegen?',
          scaffoldOnPublicFootpathFrontendTitle:
            'Würde die Gerüstaufstellung eine öffentliche Zufahrt (wie z. B. eine Tiefgarage) blockieren?',
          isScaffoldBlockingPublicAccess:
            'Würde die Gerüstaufstellung eine öffentliche Zufahrt (wie z. B. eine Tiefgarage) blockieren?',
          scaffoldNotFromEkdConditionsKnown:
            'Es ist bekannt, dass der Auftraggeber selbst ein berufgenossenschaftskonformes (BG-konformes) Gerüst stellen muss. Bei unzureichender Gerüststellung können Mehrkosten in Rechnung gestellt werden. Im Falle, dass der Auftraggeber das Gerüst stellt, können längere Standzeiten durch Montageverzögerungen nicht in Rechnung gestellt werden.',
          scaffoldFromEkd:
            'Wird das Gerüst durch Energiekonzepte Deutschland gestellt?',
          scaffoldSide: 'Auf welchen Seiten ist das Gerüst geplant?',
          markScaffolding: 'Bitte Gerüst im Luftbild markieren.',
          showScaffold: 'Gerüst anzeigen',
          editScaffold: 'Gerüst bearbeiten',
          addScaffold: 'Gerüst hinzufügen',
          markPosition: 'Zum Markieren gewünschte Stelle tippen.',
          moreNotes: 'Weitere Notizen und Hinweise für den Gerüstbauer',
          notesForScaffolder: 'Hier Notizen eingeben',
          scaffoldLengthsA: 'Länge Gerüst',
          enterLength: 'Länge eingeben',
          permitByCustomerKnown:
            'Es ist bekannt, dass notwendige Genehmigungen durch den Auftraggeber einzuholen sind.',
          scaffoldPublicObstacleVisualDocumentation: 'Foto-/Videodokumentation',
          scaffoldObstacleVisualDocumentation: 'Foto-/Videodokumentation',
        },
        consumption: {
          outputAndStorage: 'Leistung und Speicher',
          propertyDesiredPvOutputKwp: 'Gewünschte Leistung der DC-Anlage',
          propertyDesiredPvOutputKwpPlaceholder: 'Leistung',
          propertyDesiredBssCapacityKwh: 'Gewünschte Speichergröße',
          propertyDesiredBssCapacityKwhPlaceholder: 'Speichergröße',
          propertyDesiredBss: 'Gewünschter Speicher',
          propertyDesiredBssPlaceholder: 'Gewünschter Speicher',
          light: 'Licht',
          propertyHouseElectricityConsumptionKwhPerYear:
            'Licht Stromverbrauch pro Jahr',
          propertyHouseElectricityConsumptionKwhPerYearPlaceholder:
            'Licht Stromverbrauch',
          propertyHouseElectricityPriceCentsPerKwh:
            'Licht Strompreis (Brutto) pro kWh',
          propertyHouseElectricityPriceCentsPerKwhPlaceholder:
            'Licht Strompreis',
          propertyHouseElectricityBasicFeeEuroPerYear:
            'Licht Grundgebühr (Brutto) - Jahreswert',
          propertyHouseElectricityBasicFeeEuroPerYearPlaceholder:
            'Licht Grundgebühr',
          propertyHouseElectricityMeterNumber: 'Licht Stromzählernummer',
          propertyHouseElectricityMeterNumberPlaceholder:
            'Licht Stromzählernummer',
          warmth: 'Wärme',
          propertyHeatElectricityConsumptionKwhPerYear:
            'Wärme Stromverbrauch pro Jahr',
          propertyHeatElectricityConsumptionKwhPerYearPlaceholder:
            'Wärme Stromverbrauch',
          propertyHeatElectricityPriceCentsPerKwh:
            'Wärme Strompreis (Brutto) pro kWh',
          propertyHeatElectricityPriceCentsPerKwhPlaceholder:
            'Wärme Strompreis',
          propertyHeatElectricityBasicFeeEuroPerYear:
            'Wärme Grundgebühr (Brutto) - Jahreswert ',
          propertyHeatElectricityBasicFeeEuroPerYearPlaceholder:
            'Wärme Grundgebühr',
          propertyHeatElectricityMeterNumber: 'Wärme Stromzählernummer',
          propertyHeatElectricityMeterNumberPlaceholder:
            'Wärme Stromzählernummer',
          loadProfile: 'Lastprofil',
          propertyLoadprofileId: 'Lastprofil',
          private: 'Privat',
          commercial: 'Gewerblich',
          yearly: 'Jahreswert',
          monthly: 'Monatswert',
        },
        conclusion: {
          areaOfResponsibility: 'Verantwortungsbereich',
          date: 'Datum',
          location: 'Ort',
          signature: 'Unterschrift',
          customerSignature: 'Unterschrift Kunde',
          addSignature: 'Unterschrift hinzufügen',
          editSignature: 'Unterschrift bearbeiten',
          selectDate: 'Bitte Datum wählen',
          assemblyWithEkdComponentsKnown:
            'Hiermit bestätige ich zur Kenntnis genommen zu haben, dass die Installationsunternehmen der Energiekonzepte Deutschland GmbH einen konformen Anschlusspunkt errichten, welcher den technischen Anschlussbedingungen des Netzbetreibers entspricht. Das beauftragte Installationsunternehmen verbaut lediglich die durch den Kunden bei der Energiekonzepte Deutschland GmbH erworbenen Komponenten. Das Erneuern der Bestandselektrik gehört nicht zum Leistungsumfang der Energiekonzepte Deutschland GmbH, dies obliegt dem Kunden.',
        },
        energyStorageAndMeter: {
          multilinePlaceholder: 'Bitte beschreiben',
          energyStorageAndMeterTitle: 'Speicher und Zählerschrank',
          whichFloor:
            'Auf welcher Etage ist der geplante Standort für den Speicher/Wechselrichter?',
          secondFloor: '2. Etage',
          firstFloor: '1. Etage',
          groundFloor: 'Erdgeschoss',
          basement: 'Keller',
          meterCabinetFloor:
            'Auf welcher Etage befindet sich der Zählerschrank?',
          locationStorage: 'Standort Speicher',
          wholeStorageShouldBeVisible:
            'Gesamter Standort des Speichers muss erkennbar sein.',
          wholeMeterCabinetShouldBeVisible:
            'Gesamter Zählerschrank und Zähler sollte erkennbar sein.',
          isAtticAccessEnough:
            'Beträgt der Zugang zum Raum des Speichers mindestens 80 x 55 cm?',
          isDistanceEnough:
            'Bitte bestätigen, dass die Abstände für den Speicher eingehalten werden.',
          energyStorageWaterMeterDistanceSufficientCheckbox:
            'Abstand zum Wasserzähler beträgt mindestens 0,50 - 0,80 m (frontal)',
          energyStorageHouseConnectionBoxDistanceSufficientCheckbox:
            'Abstand zum Hausanschlusskasten beträgt mindestens 1,20 m (frontal)',
          energyStorageWaterMeterCabinetDistanceSufficientCheckbox:
            'Abstand zum Zählerschrank beträgt mindestens 1,20 m (frontal)',
          spaceRequirements:
            'Die Platzanforderungen (siehe Info Button) werden eingehalten.',
          standingAgainstTheWall: 'stehend und an Wand',
          onlyOnWall: 'nur an Wand',
          onlyStanding: 'nur stehend',
          energyStorageRequirementsHighPressureGasPipeChangeKnownCheckbox:
            'Es ist bekannt, dass sich bei Hochdruck-Gasleitungen die Mindestabstände auf 0,40 m bei Kreuzungen bzw. 1,50 m bei Parallelverlegungen vergrößern. Eine Verringerung der Abstände ist nur nach Abstimmung mit dem Beuftragten des Netzbetreibers möglich.',
          canLocationMaintainTemperature:
            'Der Standort kann eine Betriebstemperatur von +0 °C bis +40 °C einhalten.',
          meterCabinet: 'Zählerschrank und Zähler',
          isMeterCabinetSizeEnough:
            'Ist ein Zählerschrank mit einem Mindestmaß von 60 x 110 cm verbaut?',
          isAdditionalMeterCabinetNeeded:
            'Sind zusätzliche Unter-/Zwischenzähler notwendig?',
          additionalEnergySubMetersCount:
            'Anzahl zusätzlich notwendiger Unter-/Zwischenzähler',
          isThereAnEquipotentialBondingBar:
            'Ist eine Potentialausgleichsschiene inklusive Erdungsanlage vorhanden?',
          notApparent: 'Nicht ersichtlich',
          meterConcept: 'Zählerkonzept',
          electricityLength: 'Ist mehr als ein Stromzähler vorhanden?',
          otherElectricityMeters:
            'Sind weitere Stromzähler über 5 kW (elektrische Ladung) vorhanden?',
          finalEnergyMeterInformationKnownCheckbox:
            'Es ist bekannt, dass die Montagefirma das Bauvorhaben laut angegebenem Zählerkonzept realisiert. Die Zusammenlegung erfolgt unmittelbar vor dem Stromzählerwechsel durch den Netzbetreiber. Wenn der Netzbetreiber für die Montage des neuen Stromzählers die Anwesenheit des seitens der Energiekonzepte Deutschland beauftragten Installationsunternehmens wünscht, ist der Auftraggeber verpflichtet, die dadurch entstandenen Mehrkosten der Energiekonzepte Deutschland bis zur Höhe von 350,– € zzgl. MwSt. gegen Nachweis zu erstatten. Im Zuge der AC-Montage erfolgt die Prüfung des Hausanschlusskastens. Hier kann es in seltenen Fällen dazu kommen, dass dieser ausgetauscht/erneuert werden muss. Die Kosten gegenüber dem Netzbetreiber sind durch den Auftraggeber zu tragen.',
          radioYes: 'Ja',
          radioNo: 'Nein',
          storageMountPlace:
            'Kann der Speicher/Wechselrichter im Raum des Zählerschranks montiert werden?',
          toTwoRoomsAway: '1-2 Räume entfernt',
          moreThanTwoRoomsAway: 'mehr als 2 Räume entfernt',
          isCableLengthEnough:
            'Ist eine Kabellänge zwischen Speicher und Zähler von mehr als 20 m erforderlich?',
          energyStorageAndMeterCableDistanceCostsKnownCheckbox:
            'Es ist bekannt, dass je nach Kabellänge Mehrkosten entstehen: \n20-30 m: 550,– € zzgl. Mehrwertsteuer \n30-40 m: 1.100,– € zzgl. Mehrwertsteuer \n40-50 m: 1.650,– € zzgl. Mehrwertsteuer \n> 50 m: 55,– € zzgl. Mehrwertsteuer',
          isThereAdditionalSpace:
            'Ist Platz für einen zusätzlichen Zählerschrank (Mindestmaß 60 x 110 cm) vorhanden?',
          inputNumber: 'Eingabe Anzahl',
          piece: 'Stück',
          additionalEnergySubMeterCostsKnownCheckbox:
            'Es ist bekannt, dass Mehrkosten in Höhe von 500,– € pro Stück zzgl. MwSt. entstehen.',
          additionalCostsForEquipotentialBondingRailKnownCheckbox:
            'Es ist bekannt, dass Mehrkosten in Höhe von 1.000,– € zzgl. MwSt. entstehen können.',
          energyMetersCount: 'Vorhandene Stromzähler Anzahl',
          canMergeElectricityMeters:
            'Sollen alle Stromzähler auf einen Stromzähler zusammengelegt werden?',
          whichElectricityMetersAreAffected:
            'Welche Stromzähler sind betroffen?',
          energyMeterElectronicCarAffectedCheckbox: 'E-Auto',
          energyMeterPoolAffectedCheckbox: 'Pool',
          energyMeterElectronicNightStorageHeatingAffectedCheckbox:
            'Nachtspeicherheizung',
          energyMeterElectronicHeatPumpAffectedCheckbox: 'Wärmepumpe',
          energyMeterGeyserAffectedCheckbox: 'Durchlauferhitzer',
          energyMeterOtherAffectedCheckbox: 'Sonstige',
          outputPerYear: 'Leistung pro Jahr',
          sonstige: 'Sonstige',
          availableElectricityMeters: 'Welche Stromzähler sind vorhanden?',
          otherHighChargeEnergyMeterOtherHouseholdCheckbox: 'Weiterer Haushalt',
          otherHighChargeEnergyMeterBusinessCheckbox: 'Gewerbe',
          otherHighChargeEnergyMeterElectronicHeatPumpCheckbox: 'Wärmepumpe',
          otherHighChargeEnergyMeterGeyserCheckbox: 'Durchlauferhitzer',
          energyStorageSleepModeBeyondOperatingTemperatureKnownCheckbox:
            'Es ist bekannt, dass die Empfehlung der EKD für das Aufstellen des Systems zwischen +0 °C bis +30 °C liegt und bei längeren Unter-/ Überschreiten dieses Temperaturbereichs zu einer Leistungsreduzierung führen kann.',
          energyMeterExtraCostWhenCombinedKnownCheckbox:
            'Es ist bekannt, dass bei einer Zusammenlegung von mehr als drei Stromzählern Mehrkosten in Höhe von 8.900,– € zzgl. MwSt. entstehen.',
          energyMeterOtherAffected: 'Sonstige betroffene Stromzähler',
          energyMeterOtherUnaffectedDescription:
            'Beschreibung verbleibender Zähler',
          energyMeterOtherAffectedDescription:
            'Beschreibung weiterer Stromzähler über 5 kW',
          energyStorageMeterVisualDocumentation: 'Fotodokumentation Speicher',
          spaceForAdditionalEnergyMeterCabinetVisualDocumentation:
            'Fotodokumentation Zählerschrank',
          energyMeterCabinetVisualDocumentation: 'Fotos/Videos bearbeiten',
        },
        currentChecklist: {
          checklist: 'Checklist',
          photovoltaicSystemExists:
            'Ist eine Photovoltaikanlage bereits vorhanden?',
          powerGeneratingPlantExists:
            'Sind weitere Erzeugungsanlagen vorhanden?',
          solarThermalSystemExists: 'Solarthermie vorhanden?',
          wallboxDesired: 'Ist eine Wallbox gewünscht?',
          internetConnection: 'Internetanschluss',
          customerDlanExists: 'Ist D-LAN seitens des Auftraggebers vorhanden?',
          internetConnectionForEnergyStorageRequiredKnown:
            'Es ist bekannt, dass ein Internetanschluss (LAN) im Raum des Speichers vorhanden sein muss.',
          powerInverterManufacturer: 'Wechselrichterhersteller',
          powerInverterType: 'Wechselrichtertyp',
          powerInverterCount: 'Anzahl Wechselrichter',
          powerInverterOutput: 'Wechselrichterleistung',
          existingPhotovoltaicSystemCommissioningDate: 'Inbetriebnahme / Datum',
          existingPhotovoltaicSystemPeakOutput: 'Größe der Anlage:',
          existingPhotovoltaicSystemExcessFeedIn: 'Art der Einspeisung?',
          excessFeedIn: 'Überschusseinspeisung',
          fullFeed: 'Volleinspeisung',
          existingPhotovoltaicSystemShouldGetIntegrated:
            'Soll diese in den Eigenverbrauch integriert werden? (Achtung: Es entstehen Zusatzkosten für Integration.)',
          heatPumpSystemExists: 'Wärmepumpe vorhanden?',
          heatPumpSgReadyInterfaceExists:
            'Ist eine SG-Ready-Schnittstelle vorhanden?',
          existingHeatPumpSystemShouldGetIntegrated:
            'Sollen die Voraussetzungen für die Einbindung der Wärmepumpe in das Energiemanagementsystem geschaffen werden?',
          adjustmentsToHeatPumpSystemAdditionalCostKnownTitle:
            'Es fallen Zusatzkosten in Höhe von 149,- € (netto) an',
          windPowerPlantExists:
            'Welche Anlagen sind vorhanden? (Achtung: Es entstehen Zusatzkosten für Integration.)',
          windkraft: 'Windkraft',
          blockheizkraftwerk: 'Blockheizkraftwerk',
          sonstiges: 'Sonstiges',
          otherPowerPlantDescription: 'Sonstiges',
          existingGeneratingPlantShouldGetMonitoringIntegratedTitle:
            'Soll die Erzeugungsanlage ins Monitoring integriert werden?',
          generatingPlantMonitoringAdditionalCostKnownTitle:
            'Es entstehen Mehrkosten in Höhe von 95,-€ (netto)',
          otherPowerPlantDescriptionTitle:
            'Beschreibung sonstige Erzeugungsanlagen',
          adjustmentsToSolarThermalSystemKnown:
            'Es ist dem Auftraggeber bekannt, dass der eventuelle Versatz bzw. der Rückbau inkl. Entsorgung durch den Auftraggeber geschieht.',
          infoBold: 'Bitte Wallbox im Luftbild markieren.',
          infoText: 'Zum Markieren gewünschte Stelle tippen.',
          listItem: 'Wallbox hinzufügen',
          smartHome: 'Ist eine Smart Home (Homematic) Installation gewünscht?',
          notInterested: 'Kein Interesse',
          heaterOnly: 'Nur Heizkörper',
          windowsCount: 'Anzahl der Fenster',
          heatersCount: 'Anzahl der Heizkörper',
          valvesCount: 'Anzahl der Ventile',
          photoVideoTitle: 'Bitte Foto-/Videodokumentation ergänzen.',
          photoVideoValvesSubtitle1:
            'Hersteller der Ventile muss erkennbar sein.',
          photoVideoSubtitle1: 'Hersteller muss erkennbar sein.',
          photoVideoSubtitle2:
            'Pro Verteiler soll jeweils ein Foto hinterlegt werden.',
          photoVideoLabel: 'Fotos/Videos bearbeiten',
          heatingCircuitDistributorsCount: 'Anzahl der Heizkreisverteiler',
          roomControllersCount: 'Anzahl der Raumregler',
          underfloorHeatingOnly: 'Nur Fußbodenheizung',
          heaterAndUnderfloorHeating: 'Heizkörper und Fußbodenheizung',
          distanceWallboxEnergyMeterCabinetSufficient:
            'Beträgt der Abstand zum Zählerschrank mehr als 10 m (keine Luftlinie)?',
          distanceWallboxEnergyMeterCabinetTitle:
            'Ungefähre Entfernung zum Zählerschrank',
          distanceWallboxEnergyMeterCabinet: 'Ungefähre Entfernung',
          distanceWallboxEnergyMeterCabinetAdditionalCostsKnown:
            'Es ist dem Auftraggeber bekannt, dass Mehrkosten in Höhe von 40,– € pro Meter zzgl. MwSt. entstehen.',
          customerDlanModel: 'Modell',
          showWallbox: 'Wallbox anzeigen',
          editWallbox: 'Wallbox bearbeiten',
          addWallbox: 'Wallbox hinzufügen',
          dlanConnectionWithPowerlineKnown:
            'Es ist bekannt, dass die Anbindung über einen D-LAN Powerline-Adapter erfolgt. Falls ein anderer Powerline-Adapter vorhanden ist, können ggf. Kommunikationsprobleme entstehen.',
          valvesVisualDocumentation: 'Fotodokumentation Ventile',
          heatingCircuitDistributorsVisualDocumentation:
            'Fotos/Videos bearbeiten',
          roomControllersVisualDocumentation: 'Fotos/Videos bearbeiten',
        },
        connections: {
          houseConnectionBox: 'Hausanschlusskasten (HAK)',
          hakSubtitle: 'HAK',
          totalHakNumber:
            'Wie viele HAK befinden sich insgesamt auf dem Grundstück und im Haus?',
          hakLocation: 'Wo befindet sich der HAK',
          houseConnectionBoxVisualDocumentation: 'Fotodokumentation HAK',
          atHome: 'am Haus',
          inTheHouse: 'im Haus',
          atThePropertyLine: 'an der Grundstücksgrenze',
          addPhotoVideo: 'Bitte Foto-/Videodokumentation ergänzen.',
          photographTheFuseSizeBeforeIndex: 'Bitte für ',
          photographTheFuseSizeAfterIndex:
            ' eine Nahaufnahme machen und die Sicherungsgröße fotografieren.',
          editPhotoVideo: 'Fotos/Videos bearbeiten',
          hakFreelyAccessibleBeforeIndex: 'Ist HAK',
          hakFreelyAccessibleAfterIndex:
            'frei zugänglich bzw. ist Baufreiheit gegeben?',
          isHakFreelyAccessible:
            'Ist der HAK frei zugänglich bzw. ist Baufreiheit gegeben?',
          radioYes: 'Ja',
          radioNo: 'Nein',
          lineType: 'Freileitung / Erdkabel',
          whatIsTheCableType:
            'Sind Freileitungen an der Gebäudefassade oder Erdkabel vorhanden?',
          whereIsHakOneLocatedBeforeIndex:
            'Auf welcher Etage und in welchem Raum befindet sich der HAK',
          whereIsHakOneLocatedAfterIndex: '?',
          hakLocationInHouseDescription:
            'Auf welcher Etage und in welchem Raum befindet sich der HAK?',
          describe: 'Bitte beschreiben',
          openOnAssemblyDateKnownCheckbox:
            'Es ist dem Auftraggeber bekannt, dass der HAK am Montagetermin zugänglich und geöffnet sein muss. Mehrkosten, die ggf. anfallen können, gehen zu Lasten des Auftraggebers.',
          isolationApplicationRequiredByCustomerKnownCheckbox:
            'Es ist bekannt, dass bei einer Freileitung eine Isolierung (falls nicht vorhanden) durch den Auftraggeber beim Netzbetreiber zu beantragen ist. Dies muss zwingend vor der DC-Montage der PV-Anlage stattfinden.',
          newConnectionBoxApplicationRequiredByCustomerKnownCheckbox:
            'Es ist bekannt, dass der Auftraggeber einen neuen HAK beim Netzbetreiber beantragen muss und dafür die Kosten trägt.',
          additionalCostMayArise: 'Mehrkosten können entstehen.',
          undergroundCable: 'Erdkabel (a)',
          overheadLine: 'Freileitung (b)',
          overheadLineVisualDocumentation: 'Fotos/Videos bearbeiten',
          undergroundCableFrontendLabel: 'Erdkabel',
          overheadLineFrontendLabel: 'Freileitung',
        },
      },
      note: {
        assignedSales: 'zugeordneter Sales',
        calendarEntryType: 'Termintyp',
        description: 'Beschreibung',
        dropdown: 'Dropdown',
        information: 'Informationen',
        leadNotChangeable: 'Lead kann nicht geändert werden',
        management: 'Management',
        noteType: 'Notiztyp',
        selectLead: 'Lead auswählen',
        type: {
          note: 'Notiz',
          closeNote: 'Notiz, um Termin zu erledigen',
          reopenNote: 'Notiz, um Termin zu öffnen',
          minuteCall: 'Anrufprotokoll',
          minuteMeeting: 'Meetingprotokoll',
        },
        relatedCalendarEntry: 'Zugehöriger Termin',
        created: 'Notiz wurde erfolgreich erstellt!',
      },
      leads: {
        leadsFromMyLeads: 'Leads aus "Meine Leads"',
        myLeads: 'Meine Leads',
        searchMyLeads: 'Meine Leads durchsuchen…',
        nearMe: 'Meine offenen Leads in der Nähe',
        noNearLeads: 'Keine Leads in der Nähe',
      },
      leadPhase: {
        all: 'Alle',
        callLater: 'Später anrufen',
        delay: 'Verzögerung',
        firstContact: 'Erstkontakt',
        leadFrom: 'Leads aus',
        leads: 'Leads',
        measureDate: 'Aufmaßtermin',
        myLeads: 'Meine Leads',
        notReached: 'Keinen Erreicht',
        offerDone: 'Angebot fertig',
        offerInProcess: 'Angebot in Bearbeitung',
        offerMeeting: 'Angebotsbesprechung',
        searchMyLeads: 'Meine Leads durchsuchen…',
        worth: 'Wert',
      },
      login: {
        about: 'Impressum',
        privacyProtection: 'Datenschutz',
        welcome: 'Willkommen',
      },
      filter: {
        sort: 'Sortierung der Leads',
        allEntries: 'Alle Einträge',
        onlyCalls: 'Anruf',
        onlyEmails: 'E-Mail',
        onlyTasks: 'Aufgabe',
        onlyMeeting: 'Meeting',
        onlyMeasurementMeeting: 'Aufmaß',
        allTime: 'Gesamte Zeit',
        today: 'Heute',
        me: 'Ich',
        other: 'Andere Sales',
        surname: 'Nachname: A-Z',
        surnameDsc: 'Nachname: Z-A',
        createdAt: 'Aktivität: Alt-Neu',
        createdAtDsc: 'Aktivität: Neu-Alt',
        entryType: 'Aktivitätsart',
        time: 'Zeitraum',
        owner: 'Vertriebler',
        searchOwner: 'Vertriebler durchsuchen...',
        onlyImages: 'Nur Bilder',
        onlyFiles: 'Nur Dateien',
        urgent: 'Überfällig',
        state: 'Status',
        all: 'Alles',
        allMember: 'Ganzes Team',
        searchUsers: 'Suche nach Sales',
        fileType: 'Dateityp',
      },
      imageCategories: {
        noCategory: 'Nicht kategorisiert',
        roofBuilding: 'Dach/Gebäude',
        energyMeter: 'Zählerschrank/Zähler',
        locationStorage: 'Standort Speicher/Innenraum',
        locationWallbox: 'Standort Wallbox/Kabelverlegung',
        houseConnectionBox: 'Hausanschlusskasten (HAK)',
        obstacle: 'Hindernis',
        scaffoldObstacle: 'Gerüst Hindernis',
        scaffoldPublicObstacle: 'Gerüst Öffentliches Hindernis',
        storageMeter: 'Speicherzähler',
        meterCabinet: 'Zählerschrank',
        spaceForAdditionalEnergyMeterCabinet:
          'Platz für einen zusätzlichen Zählerschrank ',
        valve: 'Ventile',
        heatingCircuitDistributor: 'Heizkreisverteiler',
        roomController: 'Raumregler',
        roofArea: 'Dachfläche',
        flatRoof: 'Flachdach',
        roofStructures: 'Dachaufbauten',
        overheadLine: 'Freileitung',
        dcCableRoutingAlongOutsideFacade: 'DC-Kabelführung Außenfassade',
        dcCableRoutingAlongRoofCladding: 'DC-Kabelführung Dachhaut',
        storageFacilityAtGroundLevel: 'Lagermöglichkeit ebenerdig',
      },
      timelineDoneFilter: {
        allEntries: 'Alle Einträge',
        onlyCalendarEntries: 'Nur Termine',
        onlyNotes: 'Nur Notizen',
        onlyMessages: 'Nur interne Nachrichten',
      },
      addressSelect: {
        selectedAddress: 'Ausgewählte Adresse',
        manualEntry: 'Zur manuellen Eingabe',
        autocompleteEntry: 'Zur automatischen Eingabe',
        street: 'Straße',
        number: 'Nr.',
        zip: 'PLZ',
        locality: 'Ort',
        country: 'Land',
        minSearch: '- Mindestens 5 Buchstaben -',
      },
      labelListItem: {
        mobile: 'Mobil',
        private: 'Privat',
        work: 'Arbeit',
        placeholderPhoneNumber: 'Telefonnummer angeben',
        placeholderEmailAddress: 'E-Mail-Adresse eingeben',
      },
      satelliteImage: {
        savePhoto: 'Foto nur speichern',
        addDrawing: 'Zeichnung hinzufügen',
        addText: 'Fügen Sie einen Text hinzu',
        addTextPlaceholder: 'Schreiben, dann Karte klicken',
        image: 'Luftbild',
        removeAllLines: 'alle Linien entfernen',
        removeAllTexts: 'alle Texte entfernen',
        satelliteImageAdded: 'Satellitenbild wurde erfolgreich erstellt!',
        satelliteImageUpdated: 'Satellitenbild wurde erfolgreich bearbeitet!',
      },
      imageFile: {
        image: 'Bild',
        photo: 'Foto',
        satelliteImage: 'Luftbild',
        customImage: 'Luftbild',
        sketch: 'Skizze',
        of: 'vom',
        changeCategory: 'Kategorie ändern',
        chooseCategory: 'Kategorie wählen',
      },
      misc: {
        of: 'von',
        done: 'erledigt',
        description: 'Beschreibung',
        optional: 'optional',
        s2tUnavailable: 'Speech-To-Text ist nicht verfügbar',
        information: 'Informationen',
        details: 'Details',
        status: 'Status',
        version: 'Version',
        app: 'App',
        amount: 'Anzahl',
        unspecified: 'Nicht angegeben',
        search: 'Leads durchsuchen...',
        unkownTeam: 'Unbekanntes Team',
        searchSales: 'Sales suchen',
        notYetAvailable: 'Noch nicht verfügbar',
        empty: 'leer',
        linkToMiddleware: 'Bitte melden sie sich in der Leads-Middleware an',
        relatedLead: 'Zugehöriger Lead',
        appUpdateInfo: 'Neue App-Version ist verfügbar',
        downloadUpdate: 'Klicken Sie hier, um die Anwendung zu aktualisieren',
        toggleAnimationGif: 'Glückwunsch-Animation bei gewonnenen Leads',
        changesSaved: 'Änderungen erfolgreich gespeichert.',
        moreInfo: 'Mehr Info',
        shouldSaveAllLeadsToContacts:
          'Kontakte für alle eigenen offenen Leads erstellen',
      },
      measurementProtocol: {
        yes: 'Ja',
        no: 'Nein',
        comment: 'Kommentar',
        addPhotoVideo: 'Foto/Video hinzufügen',
        addPhoto: 'Foto hinzufügen',
        invalidImageType: 'Ungültiger Dateityp ausgewählt',
        imageUploadError: 'Fehler beim Hoachladen der Datei',
        editPhotosVideos: 'Fotos/Videos bearbeiten',
        addPhotosVideos: 'Fotos/Videos hinzufügen',
        showPhotosVideos: 'Fotos/Videos anzeigen',
        addVisualDocumentation: 'Bitte Foto-/Videodokumentation ergänzen',
        addVisualDocumentationReadOnly: 'Foto-/Videodokumentation',
        additionalCosts: 'Mehrkosten können entstehen.',
        itemPlaceholder: 'Standort Wallbox/Kabelverlegung',
        next: 'Weiter',
        goToHomeScreen: 'Zurück zur Startseite',
        neighboringProperty: 'Nachbargrundstück',
        governmentRegulationsSpecifications:
          'Die Vorgaben der Landesbauordnung sind hinsichtlich des Abstands zum Nachbargrundstück bekannt.',
        isTheDistanceKept: 'Wird Abstand zum Nachbargrundstück eingehalten?',
        facilityCannotBeBuilt:
          'Die Anlage kann nicht gebaut werden. Der Aufmaßbogen muss abgebrochen werden.',
        initialAnalysis: 'Nur Erstanalysebogen ausgeben?',
        initialAnalysisProgressIndicatorNotFinished:
          'Erstanalyse in Bearbeitung',
        initialAnalysisProgressIndicatorFinished:
          'Erstanalyse in erfolgreich abgeschlossen',
        inDistanceFromTheNeighbour:
          'Die Vorgaben der Landesbauordnung sind hinsichtlich des Abstands zum Nachbargrundstück bekannt.',
        measurementItems: {
          consumption: 'Verbrauchsdaten',
          conclusion: 'Abschluss',
          connection: 'Anschlüsse',
          checklist: 'Checkliste AC/DC',
          roof: 'Dach',
          scaffold: 'Gerüst',
          property: 'Grundstück',
          obstacle: 'Hindernisse',
          storage: 'Speicher & Zähler',
          energyStorageAndMeter: 'Speicher & Zähler',
        },
        visualRepresentation: {
          title: 'Luftbild / Skizze',
          add: 'Luftbild/Skizze hinzufügen',
          edit: 'Luftbild/Skizze bearbeiten',
          provide:
            'An dieser Stelle bitte ein repräsentatives Luftbild anfertigen oder eine Skizze zur Verfügung stellen',
        },
        visualRepresentationOnImage: {
          title: 'Foto / Skizze',
        },
        dialog: {
          backButton: 'Zurück',
          goHomeButton: 'Zur Startseite',
          errorDialogTitle: 'Anlage kann nicht gebaut werden!',
          errorDialogMessage:
            'Bitte überprüfe, ob die Angaben korrekt sind. Andernfalls kann der Aufmaßbogen nicht abgeschlossen werden.',
          initialTitle: 'Erstanalyse',
          initialMessage:
            'Die Erstanalyse dient dir dazu, zeitnah ein qualitativ gutes Angebot zu bekommen. Solltest du das komplette Beratungsprotokoll später ausfüllen, werden die Daten übernommen.',
          addEditItemsTitle: 'Elemente hinzufügen/bearbeiten',
          addEditItemsMessage:
            'Zum Hinzufügen von Elementen einfach die gewünschte Stelle antippen. Zum Verschieben das Element gedrückt halten. Zum Löschen das Element antippen und im Pop-Up die gewünschte Funktion wählen.',
          saveVisualRepresentation: 'Luftbild/Skizze hinterlegen',
          saveVisualRepresentationExplanation:
            'An dieser Stelle kannst du ein Luftbild, eine Drohnenaufnahme oder eine Skizze hinterlegen. Optional hast du die Möglichkeit, bereits alle sichtbaren Elemente zu ergänzen. Hierfür das gewünschte Element aus der oberen Leiste auswählen und dann auf dem hinterlegten Bild markieren. In den weiteren Schritten des Beratungsprotokolls kannst du die einzelnen Elemente ergänzen oder bearbeiten.',
          commentTitle: 'Kommentar - ',
          commentPlaceholder: 'Kommentar',
          consumption: {
            photographyTitle: 'Zählernummer scannen',
            photographyMessage:
              'Hier kannst du Fotos von den vorhandenen Stromzählern machen, woraufhin die Nummer gescannt wird und automatisch in das entsprechende Feld eingetragen wird. Bitte achte darauf, dass nur die Zählernummer zu sehen ist und diese gut erkennbar ist. Die Zählernummern sind wichtig für die Vollmachten bei den Netzbetreibern.',
          },
          roof: {
            mainBuildingTitle: 'Hauptgebäude & Nebengebäude',
            mainBuildingMessage:
              'Gib hier bitte zunächst das Hauptgebäude an, in dem Zähler und Speicher installiert werden sollen. Eventuelle Nebengebäude, z. B. eine Garage, sind bitte danach aufzuzählen.',
            brickMeasureTitle: 'Ziegeldeckmaß',
            brickMeasureMessage:
              'Die Breite des Ziegels ist die einfache Breite eines Dachziegels. Das Ziegeldeckmaß bezeichnet hingegen nur den sichtbaren Teil des Dachziegels, der nicht von anderen Dachziegeln verdeckt wird.',
            executionSubstructureTitle: 'Ausführung Unterkonstruktion',
            executionSubstructureImageLabelA:
              'Mögliche Art der Ausführung Nr.1',
            executionSubstructureImageLabelB:
              'Mögliche Art der Ausführung Nr.2',
            roofStructuresTitle: 'Dachaufbauten',
            roofStructuresMessage:
              'Hier kannst du Hinweise zu vorhandenen Dachaufbauten, wie Schornsteinen, Dachfenstern, Entlüftungsrohren etc., eingeben und mit einem Foto dokumentieren. Diese werden bei der Planung berücksichtigt.',
          },
          obstacle: {
            obstacleTitle: 'Hindernisse',
            obstacleMessage:
              'Bitte schau dir die Umgebung des Daches an und notiere mögliche Objekte, die zu einer Verschattung der Anlage führen könnten. Dazu zählen beispielsweise hohe Bäume, Nachbargebäude und Ähnliches.',
          },
          scaffold: {
            scaffoldObstacleTitle: 'Hindernisse',
            scaffoldObstacleMessage:
              'Hier werden die Hindernisse für die Aufstellung der Gerüste dokumentiert. Dies hilft den Montagepartnern, sich vorzubereiten.',
            publicWalkwayTitle: 'Öffentlicher Gehweg',
            publicWalkwayMessage:
              'Das obere Bild zeigt, dass ein zu naher öffentlicher Gehweg die Gerüststellung behindern könnte. In diesem Fall sind ggf. Genehmigungen erforderlich, die der Auftraggeber einholen muss.',
          },
          energyStorageAndMeter: {
            spaceRequirementsTitle: 'Platzanforderungen Speicher',
            spaceRequirementsImageLabelA: 'Aufstellung',
            spaceRequirementsImageLabelB: 'Wandmontage',
            bondingBarTitle: 'Potentialausgleichsschiene',
          },
          connections: {
            hakPhotoDocumentationDialogTitle: 'Fotodokumentation HAK',
            hakPhotoDocumentationDialogText:
              'Bitte den Hausanschlusskasten, wenn möglich, offen fotografieren und so, dass die Sicherungsgrößen, mindestens 35 Ampere, erkennbar sind.',
          },
          deleteDialog: {
            description:
              'Nach dem Löschen können die Markierungen nicht mehr wiederhergestellt werden. Trotzdem fortfahren?',
            confirm: 'Markierungen löschen',
            cancel: 'Zurück',
            mode: {
              roof: 'Bist du sicher, dass du alle Dach-Markierungen löschen möchtest?',
              door: 'Bist du sicher, dass du alle Tür-Markierungen löschen möchtest?',
              scaffold:
                'Bist du sicher, dass du alle Gerüst-Markierungen löschen möchtest?',
              danger:
                'Bist du sicher, dass du alle Hindernis-Markierungen löschen möchtest?',
              truck:
                'Bist du sicher, dass du alle Anlieferzonen-Markierungen löschen möchtest?',
              wallbox:
                'Bist du sicher, dass du alle Wallbox-Markierungen löschen möchtest?',
            },
          },
        },
        buttons: {
          save: 'Speichern',
          done: 'Fertig',
          block: 'Abschließen',
          finishedAlready: 'Abgeschlossen',
        },
        snackBarSuccessMessage: 'Abschnitt erfolgreich abgeschlossen',
        snackBarFinalSuccessMessage: 'Aufmaßbogen erfolgreich abgeschlossen',
      },
      confirmationEmail: {
        title:
          'E-Mail-Adresse des Leads auswählen, an welchen die Terminbestätigung versendet werden soll:',
        noEmail: 'Keine E-Mail-Adresse vorhanden am Lead',
        confirmationToastText: 'Der Termin wurde angelegt!',
      },
      globalSearch: {
        searchMinLettersNeeded: 'mindestens 3 Buchstaben eingeben',
        searchResultsEmpty: 'keine Ergebnisse gefunden',
      },
      message: {
        title: 'Interne Nachricht an Planer',
        messageDetails: 'Notiz Details',
        published: 'Gesendet',
        planning: 'Projektplanung',
        messageToPlanner: 'Interne Nachricht an Planer',
        messageFromPlanner: 'Interne Nachricht vom Planer',
        created: 'Nachricht erfolgreich erstellt!',
      },
      countries: {
        AF: 'Afghanistan',
        AX: 'Åland',
        AL: 'Albanien',
        DZ: 'Algerien',
        AS: 'Amerikanisch-Samoa',
        AD: 'Andorra',
        AO: 'Angola',
        AI: 'Anguilla',
        AQ: 'Antarktis',
        AG: 'Antigua und Barbuda',
        AR: 'Argentinien',
        AM: 'Armenien',
        AW: 'Aruba',
        AU: 'Australien',
        AT: 'Österreich',
        AZ: 'Aserbaidschan',
        BS: 'Bahamas',
        BH: 'Bahrain',
        BD: 'Bangladesch',
        BB: 'Barbados',
        BY: 'Weißrussland',
        BE: 'Belgien',
        BZ: 'Belize',
        BJ: 'Benin',
        BM: 'Bermuda',
        BT: 'Bhutan',
        BO: 'Bolivien',
        BA: 'Bosnien und Herzegowina',
        BW: 'Botswana',
        BR: 'Brasilien',
        IO: 'Britisches Territorium im Indischen Ozean',
        BN: 'Brunei Darussalam',
        BG: 'Bulgarien',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        KH: 'Kambodscha',
        CM: 'Kamerun',
        CA: 'Kanada',
        CV: 'Kap Verde',
        KY: 'Kaimaninseln',
        CF: 'Zentralafrikanische Republik',
        TD: 'Tschad',
        CL: 'Chile',
        CN: 'China',
        CX: 'Weihnachtsinsel',
        CC: 'Kokosinseln',
        CO: 'Kolumbien',
        KM: 'Komoren',
        CG: 'Republik Kongo',
        CD: 'Kongo',
        CK: 'Cookinseln',
        CR: 'Costa Rica',
        CI: 'Elfenbeinküste',
        HR: 'Kroatien',
        CU: 'Kuba',
        CY: 'Zypern',
        CZ: 'Tschechische Republik',
        DK: 'Dänemark',
        DJ: 'Dschibuti',
        DM: 'Dominica',
        DO: 'Dominikanische Republik',
        EC: 'Ecuador',
        EG: 'Ägypten',
        SV: 'El Salvador',
        GQ: 'Äquatorialguinea',
        ER: 'Eritrea',
        EE: 'Estland',
        ET: 'Äthiopien',
        FK: 'Falklandinseln',
        FO: 'Färöer',
        FJ: 'Fidschi',
        FI: 'Finnland',
        FR: 'Frankreich',
        GF: 'Französisch-Guayana',
        PF: 'Französisch-Polynesien',
        GA: 'Gabun',
        GM: 'Gambia',
        GE: 'Georgien',
        DE: 'Deutschland',
        GH: 'Ghana',
        GI: 'Gibraltar',
        GR: 'Griechenland',
        GL: 'Grönland',
        GD: 'Grenada',
        GP: 'Guadeloupe',
        GU: 'Guam',
        GT: 'Guatemala',
        GG: 'Guernsey',
        GN: 'Guinea',
        GW: 'Guinea-Bissau',
        GY: 'Guyana',
        HT: 'Haiti',
        VA: 'Vatikanstadt',
        HN: 'Honduras',
        HK: 'Hongkong',
        HU: 'Ungarn',
        IS: 'Island',
        IN: 'Indien',
        ID: 'Indonesien',
        IR: 'Iran',
        IQ: 'Irak',
        IE: 'Irland',
        IM: 'Insel Man',
        IL: 'Israel',
        IT: 'Italien',
        JM: 'Jamaika',
        JP: 'Japan',
        JE: 'Jersey',
        JO: 'Jordanien',
        KZ: 'Kasachstan',
        KE: 'Kenia',
        KI: 'Kiribati',
        KP: 'Nordkorea',
        KR: 'Südkorea',
        KW: 'Kuwait',
        KG: 'Kirgisistan',
        LA: 'Laos',
        LV: 'Lettland',
        LB: 'Libanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libyen',
        LI: 'Liechtenstein',
        LT: 'Litauen',
        LU: 'Luxemburg',
        MO: 'Macao',
        MG: 'Madagaskar',
        MW: 'Malawi',
        MY: 'Malaysia',
        MV: 'Malediven',
        ML: 'Mali',
        MT: 'Malta',
        MH: 'Marshallinseln',
        MQ: 'Martinique',
        MR: 'Mauretanien',
        MU: 'Mauritius',
        YT: 'Mayotte',
        MX: 'Mexiko',
        FM: 'Mikronesien',
        MD: 'Moldawien',
        MC: 'Monaco',
        MN: 'Mongolei',
        ME: 'Montenegro',
        MS: 'Montserrat',
        MA: 'Marokko',
        MZ: 'Mosambik',
        MM: 'Myanmar',
        NA: 'Namibia',
        NR: 'Nauru',
        NP: 'Nepal',
        NL: 'Niederlande',
        NC: 'Neukaledonien',
        NZ: 'Neuseeland',
        NI: 'Nicaragua',
        NE: 'Niger',
        NG: 'Nigeria',
        NU: 'Niue',
        NF: 'Norfolkinsel',
        MP: 'Nördliche Marianen',
        NO: 'Norwegen',
        OM: 'Oman',
        PK: 'Pakistan',
        PW: 'Palau',
        PA: 'Panama',
        PG: 'Papua-Neuguinea',
        PY: 'Paraguay',
        PE: 'Peru',
        PH: 'Philippinen',
        PN: 'Pitcairninseln',
        PL: 'Polen',
        PT: 'Portugal',
        PR: 'Puerto Rico',
        QA: 'Katar',
        RO: 'Rumänien',
        RU: 'Russische Föderation',
        RW: 'Ruanda',
        RE: 'Réunion',
        BL: 'Saint-Barthélemy',
        SH: 'St. Helena',
        KN: 'St. Kitts und Nevis',
        LC: 'St. Lucia',
        MF: 'Saint-Martin',
        PM: 'Saint-Pierre und Miquelon',
        VC: 'St. Vincent und die Grenadinen',
        WS: 'Samoa',
        SM: 'San Marino',
        ST: 'São Tomé und Príncipe',
        SA: 'Saudi-Arabien',
        SN: 'Senegal',
        RS: 'Serbien',
        SC: 'Seychellen',
        SL: 'Sierra Leone',
        SG: 'Singapur',
        SK: 'Slowakei',
        SI: 'Slowenien',
        SB: 'Salomonen',
        SO: 'Somalia',
        ZA: 'Südafrika',
        SS: 'Südsudan',
        GS: 'Südgeorgien und die Südlichen Sandwichinseln',
        ES: 'Spanien',
        LK: 'Sri Lanka',
        SD: 'Sudan',
        SR: 'Suriname',
        SJ: 'Svalbard und Jan Mayen',
        SZ: 'Swasiland',
        SE: 'Schweden',
        CH: 'Schweiz',
        SY: 'Syrien, Arabische Republik',
        TW: 'Taiwan',
        TJ: 'Tadschikistan',
        TZ: 'Tansania, Vereinigte Republik',
        TH: 'Thailand',
        TL: 'Osttimor',
        TG: 'Togo',
        TK: 'Tokelau',
        TO: 'Tonga',
        TT: 'Trinidad und Tobago',
        TN: 'Tunesien',
        TR: 'Türkei',
        TM: 'Turkmenistan',
        TC: 'Turks- und Caicosinseln',
        TV: 'Tuvalu',
        UG: 'Uganda',
        UA: 'Ukraine',
        AE: 'Vereinigte Arabische Emirate',
        GB: 'Vereinigtes Königreich',
        US: 'Vereinigte Staaten von Amerika',
        UY: 'Uruguay',
        UZ: 'Usbekistan',
        VU: 'Vanuatu',
        VE: 'Venezuela',
        VN: 'Vietnam',
        VG: 'Britische Jungferninseln',
        VI: 'Amerikanische Jungferninseln',
        WF: 'Wallis und Futuna',
        YE: 'Jemen',
        ZM: 'Sambia',
        ZW: 'Simbabwe',
      },
    },
  },
};
