// @ts-nocheck

/* eslint-disable import/no-cycle */
import {
  validateFloatNumberOneDecimal10to100,
  validateFloatNumberTwoDecimal0to100,
  validateFloatNumberTwoDecimal0to100k,
  validateFloatNumberTwoDecimal0to1000,
  validateFloatNumberTwoDecimal1to100,
  validateIntNumber0to90,
  validateIntNumber0to300k,
  validateIntNumber0to2000,
  validateTextMaxLength50,
  validateTrueOrFalse,
} from '../../lib/configValidation';
import {
  CABLE_ROUTING_TYPE_OPTIONS,
  MEASUREMENT_PROTOCOL_NUMBER_PROPERTIES,
  MEASUREMENT_PROTOCOL_ROOF_AREA_NUMBER_PROPERTIES,
  MEASUREMENT_PROTOCOL_ROOF_NUMBER_PROPERTIES,
} from './measurementProtocol';

export * from './measurementProtocol';

export const LINKS = {
  idLink: 'https://www.leads-middleware.de/login/lead.php?lead=',
} as const;

export const OS = {
  IOS: 'ios',
  ANDROID: 'android',
  WINDOWS: 'windows',
} as const;

export const ENVIRONMENT = {
  DEV: 'dev',
  TEST3: 'test3',
  STAGING1: 'staging1',
  PRODUCTION: 'production',
};

export const DEFAULT_PHONE_NUMBER_COUNTRY_CODE = 'DE';

// ID of 'Angebot in Bearbeitung' leadphase
export const FIRST_LEADPHASE_ID_WITH_INITIAL_ANYLYSIS_NEEDED = 8;

export const ENTITY = {
  ADDRESS: 'ADDRESS',
  AUDIO_CONTENT: 'AUDIO_CONTENT',
  CALENDAR_ENTRY: 'CALENDAR_ENTRY',
  DEVICE: 'DEVICE',
  EMAIL_ADDRESS: 'EMAIL_ADDRESS',
  FILE: 'FILE',
  IMAGE_ID: 'IMAGE_ID',
  IMAGE: 'IMAGE',
  LEAD_ID: 'LEAD_ID',
  LEAD: 'LEAD',
  MESSAGE: 'MESSAGE',
  MISC_FILE_ID: 'MISC_FILE_ID',
  MISC_FILE: 'MISC_FILE',
  NOTE: 'NOTE',
  PHONE_NUMBER: 'PHONE_NUMBER',
  TEAM: 'TEAM',
  USER_ROLE: 'USER_ROLE',
  USER: 'USER',
  // lookup
  AREATYPE: 'AREATYPE',
  LEADPHASE: 'LEADPHASE',
  LEADPHASE_TRANSITION: 'LEADPHASE_TRANSITION',
  LEADSOURCE: 'LEADSOURCE',
  LOADPROFILE: 'LOADPROFILE',
  ROOFTILETYPE: 'ROOFTILETYPE',
  ROOFTYPE: 'ROOFTYPE',
  SALUTATION: 'SALUTATION',
  ZIP_AREA_ROOF_COVERING: 'ZIP_AREA_ROOF_COVERING',
  LEAD_LOST_REASON: 'LEAD_LOST_REASON',
  // local only
  LOCAL_BINARY_FILE: 'LOCAL_BINARY_FILE',
  LOCAL_CONTACT_RECORD: 'LOCAL_CONTACT_RECORD',
  LOCAL_LEAD_QUERIED: 'LOCAL_LEAD_QUERIED',
  // MP
  MEASUREMENT_PROTOCOL: 'MEASUREMENT_PROTOCOL',
  MEASUREMENT_PROTOCOL_ID: 'MEASUREMENT_PROTOCOL_ID',
  MEASUREMENT_PROTOCOL_ROOF: 'MEASUREMENT_PROTOCOL_ROOF',
  MEASUREMENT_PROTOCOL_ROOF_ID: 'MEASUREMENT_PROTOCOL_ROOF_ID',
  MEASUREMENT_PROTOCOL_ROOF_AREA: 'MEASUREMENT_PROTOCOL_ROOF_AREA',
  MEASUREMENT_PROTOCOL_ROOF_AREA_ID: 'MEASUREMENT_PROTOCOL_ROOF_AREA_ID',
  MEASUREMENT_PROTOCOL_HOUSE_CONNECTION_BOX:
    'MEASUREMENT_PROTOCOL_HOUSE_CONNECTION_BOX',
  MEASUREMENT_PROTOCOL_HOUSE_CONNECTION_BOX_ID:
    'MEASUREMENT_PROTOCOL_HOUSE_CONNECTION_BOX_ID',
} as const;

export const ROUTES = {
  LOGINSTACK: {
    LOGIN: 'LOGINSTACK_LOGIN',
    NOT_ALLOWED_USER: 'LOGINSTACK_NOT_ALLOWED_USER',
    UPDATE_NEEDED: 'UPDATE_NEEDED',
  },
  TAB: {
    FOCUS: 'TAB_FOCUS',
    LEADS: 'TAB_LEADS',
    ACTIVITIES: 'TAB_ACTIVITIES',
    MAP: 'TAB_MAP',
  },
  HOMESTACK: {
    ADDRESS_SELECT: 'ADDRESS_SELECT',
    AUDIO_RECORDER: 'HOMESTACK_AUDIO_RECORDER',
    CALENDAR_ENTRY_CREATE: 'HOMESTACK_CALENDAR_ENTRY_CREATE',
    CALENDAR_ENTRY_DETAILS: 'HOMESTACK_CALENDAR_ENTRY_DETAILS',
    CALENDAR_ENTRY_CONFIRMATION_EMAIL: 'CALENDAR_ENTRY_CONFIRMATION_EMAIL',
    CALENDAR_ENTRIES_OVERDUE: 'HOMESTACK_CALENDAR_ENTRIES_OVERDUE',
    CALENDAR: 'HOMESTACK_CALENDAR',
    DESCRIPTION: 'HOMESTACK_DESCRIPTION',
    EMAIL_DETAILS: 'HOMESTACK_EMAIL_DETAILS',
    EMAILS: 'HOMESTACK_EMAILS',
    IMAGE_PREVIEW: 'IMAGE_PREVIEW',
    LEAD_CREATE: 'HOMESTACK_LEAD_CREATE',
    LEAD_DETAILS_EDIT: 'HOMESTACK_LEAD_DETAILS_EDIT',
    LEAD_DETAILS: 'HOMESTACK_LEAD_DETAILS',
    LEAD_DETAIL_FILE_EDIT: 'LEAD_DETAIL_FILE_EDIT',
    LEAD_SELECT: 'HOMESTACK_LEAD_SELECT',
    LEAD_INFO: 'HOMESTACK_LEAD_INFO_SECTION',
    MENU: 'HOMESTACK_MENU',
    NOTE_CREATE: 'HOMESTACK_NOTE_CREATE',
    NOTE_DETAILS: 'HOMESTACK_NOTE_DETAILS',
    SALES_SELECT: 'SALES_SELECT',
    TABS: 'HOMESTACK_TABS',
    MEASUREMENT_PROTOCOL: 'HOMESTACK_MEASUREMENT_PROTOCOL',
    MEASUREMENT_PROTOCOL_STEP: 'MEASUREMENT_PROTOCOL_STEP',
    MEASUREMENT_PROTOCOL_STEP_PROPERTY: 'MEASUREMENT_PROTOCOL_STEP_PROPERTY',
    MEASUREMENT_PROTOCOL_STEP_SCAFFOLD: 'MEASUREMENT_PROTOCOL_STEP_SCAFFOLD',
    MEASUREMENT_PROTOCOL_STEP_OBSTACLE: 'MEASUREMENT_PROTOCOL_STEP_OBSTACLE',
    MEASUREMENT_PROTOCOL_STEP_ROOF: 'MEASUREMENT_PROTOCOL_STEP_ROOF',
    MEASUREMENT_PROTOCOL_STEP_CONSUMPTION:
      'MEASUREMENT_PROTOCOL_STEP_CONSUMPTION',
    MEASUREMENT_PROTOCOL_STEP_CONCLUSION:
      'MEASUREMENT_PROTOCOL_STEP_CONCLUSION',
    MEASUREMENT_PROTOCOL_STEP_ENERGY_STORAGE_AND_METER:
      'MEASUREMENT_PROTOCOL_STEP_ENERGY_STORAGE_AND_METER',
    MEASUREMENT_PROTOCOL_STEP_CHECKLIST: 'MEASUREMENT_PROTOCOL_STEP_CHECKLIST',
    MEASUREMENT_PROTOCOL_STEP_CONNECTIONS:
      'MEASUREMENT_PROTOCOL_STEP_CONNECTIONS',
    MEASUREMENT_PROTOCOL_VISUAL_DOCUMENTATION:
      'HOMESTACK_MEASUREMENT_PROTOCOL_VISUAL_DOCUMENTATION',
    PROPERTY_VISUAL_REPRESENTATION: 'PROPERTY_VISUAL_REPRESENTATION',
    PROPERTY_VISUAL_REPRESENTATION_ON_IMAGE:
      'PROPERTY_VISUAL_REPRESENTATION_ON_IMAGE',
    PROPERTY_VISUAL_REPRESENTATION_SKETCH:
      'PROPERTY_VISUAL_REPRESENTATION_SKETCH',
    MEASUREMENT_PROTOCOL_SIGNATURE: 'MEASUREMENT_PROTOCOL_SIGNATURE',
    MESSAGE_CREATE: 'HOMESTACK_MESSAGE_CREATE',
    MESSAGE_DETAILS: 'HOMESTACK_MESSAGE_DETAILS',
  },
  ACTIVITIES: {
    ACTIVITIES: 'ACTIVITIES_ACTIVITIES',
    CALENDAR: 'ACTIVITIES_CALENDAR',
    MAILBOX: 'ACTIVITIES_MAILBOX',
  },
  LEAD: {
    INFO: 'LEAD_INFO',
    TIMELINE: 'LEAD_TIMELINE',
    FILES: 'LEAD_FILES',
  },
} as const;

export const DETAILS_SCREENS_ROUTES_TO_ENTITIES_MAP = {
  [ROUTES.HOMESTACK.CALENDAR_ENTRY_DETAILS]: ENTITY.CALENDAR_ENTRY,
  [ROUTES.HOMESTACK.NOTE_DETAILS]: ENTITY.NOTE,
  [ROUTES.HOMESTACK.LEAD_DETAILS]: ENTITY.LEAD,
} as const;

export const BACKEND_API = {
  LEADS_MIDDLEWARE: 'LEADS_MIDDLEWARE',
  SALES_BACKEND: 'SALES_BACKEND',
} as const;

export const EVENT_SOURCE = {
  APP: 'APP',
  USER: 'USER',
} as const;

export const LOG_LEVEL = {
  debug: 'debug',
  info: 'info',
  warn: 'warn',
  error: 'error',
} as const;

export const MEDIA_TYPE = {
  PHOTO: 'photo',
  VIDEO: 'video',
  MIXED: 'mixed',
} as const;

export const IMAGE_PROCESSING = {
  QUALITY: 0.5,
  HEIC_TO_JPG_CONVERSION_QUALITY: 0.5,
} as const;

export const LOG_KEY = {
  api: 'API',
  app: { saga: 'APP_SAGA', navigate: 'APP_NAVIGATE', linking: 'APP_LINKING' },
  versionCheck: 'VERSION_CHECK',
  audio: { record: 'AUDIO_RECORD', speechToText: 'SPEECH_TO_TEXT' },
  binary: {
    upload: 'UPLOAD',
    video_compression: 'VIDEO_COMPRESSION',
  },
  backend: {
    binaryUpload: 'BACKEND_BINARY_UPLOAD',
    configuration: 'BACKEND_CONFIGURATION',
    sync: 'BACKEND_SYNC',
    syncBinary: 'BACKEND_SYNC_BINARY',
    syncDelete: 'BACKEND_SYNC_DELETE',
    syncFormChange: 'BACKEND_SYNC_FORM_CHANGE',
    syncHandlePushedChange: 'BACKEND_SYNC_HANDLE_PUSHED_CHANGE',
    syncPushChange: 'BACKEND_SYNC_PUSH_CHANGE',
    onDemand: 'BACKEND_ON_DEMAND',
  },
  tracking: {
    analytics: 'ANALYTICS',
    crashlytics: 'CRASHLYTICS',
    performanceMonitoring: 'PERFORMANCE_MONITORING',
  },
  calculation: {
    sectionFormatCalendarEntries: 'SECTION_FORMAT_CALCULATION_ENTRIES',
  },
  calendar: {
    calculate: 'CALENDAR_CALCULATE',
    reducer: 'CALENDAR_REDUCER',
    selector: 'CALENDAR_SELECTOR',
  },
  db: {
    changedValuesFilter: 'DB_CHANGED_VALUES_FILTER',
    changeNotify: 'DB_CHANGE_NOTIFY',
    default: 'DB',
    deleteForEntity: 'DB_DELETE_FOR_ENTITY',
    formSaga: 'DB_FORM_SAGA',
    persist: 'DB_PERSIST',
    query: 'DB_QUERY',
    queryBuilder: 'DB_QUERY_BUILDER',
    querySaga: 'DB_QUERY_SAGA',
    selectIsStepDone: 'SELECT_IS_STEP_DONE',
    transactions: 'DB_TRANSACTIONS',
  },
  downloadService: 'DOWNLOAD_SERVICE',
  fileStatus: 'FILE_STATUS',
  jsApi: 'JS_API',
  map: {
    snapshot: 'MAP_SNAPSHOT',
  },
  navigation: 'NAVIGATION',
  storage: 'STORAGE',
  ui: {
    display: 'UI_DISPLAY',
    typeError: 'UI_TYPE_ERROR',
  },
  uploadService: 'UPLOAD_SERVICE',
  notification: {
    fcmDeviceToken: 'FCM_DEVICE_TOKEN',
  },
  measurementProtocol: {
    form: {
      roofArea: 'MEASUREMENT_PROTOCOL_ROOF_AREA',
      stepMissing: 'MEASUREMENT_PROTOCOL_FORM_STEP_MISSING',
    },
  },
  location: 'LOCATION',
  getTextFromPhoto: 'GET_TEXT_FROM_PHOTO',
} as const;

export const NAVIGATE_TYPE = {
  TO: 'to',
  PUSH: 'push',
  POP: 'pop',
  REPLACE: 'replace',
} as const;

export const LEAD_STATE = {
  OPEN: 'OPEN',
  LOST: 'LOST',
  WON: 'WON',
} as const;

export const URLS = {
  ABOUT: 'https://www.ekd-solar.de/impressum/',
  PRIVACY_PROTECTION: 'https://www.ekd-solar.de/datenschutzerklaerung/',
  MIDDLEWARE: 'https://leads-middleware.de',
} as const;

export const THEME_OPTION = {
  LIGHT: 'light',
  DARK: 'dark',
  DEVICE: 'device',
} as const;

export const MESSAGE_TYPE = {
  TO_PLANNER: 'TO_PLANNER',
  FROM_PLANNER: 'FROM_PLANNER',
} as const;

export const NOTE_TYPE = {
  NOTE: {
    label: 'note.type.note',
    value: 'NOTE',
  },
  MINUTE_CALL: {
    label: 'note.type.minuteCall',
    value: 'MINUTE_CALL',
  },
  MINUTE_MEETING: {
    label: 'note.type.minuteMeeting',
    value: 'MINUTE_MEETING',
  },
} as const;

export const NOTE_CONTEXT_TYPE = {
  USER_CREATE: 'USER_CREATE',
  CALENDAR_DONE: 'CALENDAR_DONE',
  CALENDAR_REOPEN: 'CALENDAR_REOPEN',
} as const;

export const SATELLITE_IMAGE_EDIT_MODE = {
  DRAWING: 'DRAWING',
  WRITING: 'WRITING',
} as const;

export const LEAD_FILTER_ENTRY_TYPE = {
  ALL: {
    label: 'filter.allEntries',
    value: 'ALL',
  },
  MEASUREMENT_MEETING: {
    label: 'filter.onlyMeasurementMeeting',
    value: 'MEASUREMENT_MEETING',
  },
  OFFER_MEETING: {
    label: 'calendarEntry.type.offerMeeting',
    value: 'OFFER_MEETING',
  },
  CALL: {
    label: 'filter.onlyCalls',
    value: 'CALL',
  },
  EMAIL: {
    label: 'filter.onlyEmails',
    value: 'EMAIL',
  },
  TASK: {
    label: 'filter.onlyTasks',
    value: 'TASK',
  },
  MEETING: {
    label: 'filter.onlyMeeting',
    value: 'MEETING',
  },
} as const;

export const LEAD_TIMELINE_DONE_FILTER_ENTRY_TYPE = {
  ALL: {
    label: 'timelineDoneFilter.allEntries',
    value: 'ALL',
  },
  CALENDAR_ENTRY: {
    label: 'timelineDoneFilter.onlyCalendarEntries',
    value: 'CALENDAR_ENTRY',
  },
  NOTE: {
    label: 'timelineDoneFilter.onlyNotes',
    value: 'NOTE',
  },
  MESSAGE: {
    label: 'timelineDoneFilter.onlyMessages',
    value: 'MESSAGE',
  },
} as const;

export const FILTER_TYPES = {
  sort: 'SORT',
  owner: 'OWNER',
  state: 'STATE',
  time: 'TIME',
  type: 'TYPE',
} as const;

export const FILE_TYPE_FILTER = {
  ALL: { label: 'filter.allEntries', value: 'ALL' },
  MISCFILE: { label: 'filter.onlyFiles', value: 'MISCFILE' },
  IMAGE: { label: 'filter.onlyImages', value: 'IMAGE' },
} as const;

export const IMAGE_CATEGORIES = {
  NO_CATEGORY: 'NO_CATEGORY',
  ROOF_BUILDING: 'ROOF_BUILDING',
  ENERGY_METER: 'ENERGY_METER',
  LOCATION_STORAGE: 'LOCATION_STORAGE',
  LOCATION_WALLBOX: 'LOCATION_WALLBOX',
  HOUSE_CONNECTION_BOX: 'HOUSE_CONNECTION_BOX',
  MEASUREMENT_PROTOCOL_ANNOTATED_SATELLITE_IMAGE:
    'MEASUREMENT_PROTOCOL_ANNOTATED_SATELLITE_IMAGE',
  MEASUREMENT_PROTOCOL_CUSTOM_IMAGE: 'MEASUREMENT_PROTOCOL_CUSTOM_IMAGE',
  MEASUREMENT_PROTOCOL_ANNOTATED_CUSTOM_IMAGE:
    'MEASUREMENT_PROTOCOL_ANNOTATED_CUSTOM_IMAGE',
  MEASUREMENT_PROTOCOL_STORAGE_FACILITY_AT_GROUND_LEVEL:
    'MEASUREMENT_PROTOCOL_STORAGE_FACILITY_AT_GROUND_LEVEL',
  MEASUREMENT_PROTOCOL_OBSTACLE: 'MEASUREMENT_PROTOCOL_OBSTACLE',
  MEASUREMENT_PROTOCOL_SCAFFOLD_OBSTACLE:
    'MEASUREMENT_PROTOCOL_SCAFFOLD_OBSTACLE',
  MEASUREMENT_PROTOCOL_SCAFFOLD_PUBLIC_OBSTACLE:
    'MEASUREMENT_PROTOCOL_SCAFFOLD_PUBLIC_OBSTACLE',
  MEASUREMENT_PROTOCOL_ENERGY_STORAGE_METER:
    'MEASUREMENT_PROTOCOL_ENERGY_STORAGE_METER',
  MEASUREMENT_PROTOCOL_ENERGY_METER_CABINET:
    'MEASUREMENT_PROTOCOL_ENERGY_METER_CABINET',
  MEASUREMENT_PROTOCOL_SPACE_FOR_ADDITIONAL_ENERGY_METER_CABINET:
    'MEASUREMENT_PROTOCOL_SPACE_FOR_ADDITIONAL_ENERGY_METER_CABINET',
  MEASUREMENT_PROTOCOL_VALVE: 'MEASUREMENT_PROTOCOL_VALVE',
  MEASUREMENT_PROTOCOL_HEATING_CIRCUIT_DISTRIBUTOR:
    'MEASUREMENT_PROTOCOL_HEATING_CIRCUIT_DISTRIBUTOR',
  MEASUREMENT_PROTOCOL_ROOM_CONTROLLER: 'MEASUREMENT_PROTOCOL_ROOM_CONTROLLER',
  MEASUREMENT_PROTOCOL_ROOF_AREA: 'MEASUREMENT_PROTOCOL_ROOF_AREA',
  MEASUREMENT_PROTOCOL_FLAT_ROOF: 'MEASUREMENT_PROTOCOL_FLAT_ROOF',
  MEASUREMENT_PROTOCOL_ROOF_STRUCTURES: 'MEASUREMENT_PROTOCOL_ROOF_STRUCTURES',
  MEASUREMENT_PROTOCOL_DC_CABLE_ROUTING_ALONG_OUTSIDE_FACADE:
    'MEASUREMENT_PROTOCOL_DC_CABLE_ROUTING_ALONG_OUTSIDE_FACADE',
  MEASUREMENT_PROTOCOL_DC_CABLE_ROUTING_ALONG_ROOF_CLADDING:
    'MEASUREMENT_PROTOCOL_DC_CABLE_ROUTING_ALONG_ROOF_CLADDING',
  MEASUREMENT_PROTOCOL_HOUSE_CONNECTION_BOX:
    'MEASUREMENT_PROTOCOL_HOUSE_CONNECTION_BOX',
  MEASUREMENT_PROTOCOL_OVERHEAD_LINE: 'MEASUREMENT_PROTOCOL_OVERHEAD_LINE',
  MEASUREMENT_PROTOCOL_SIGNATURE: 'MEASUREMENT_PROTOCOL_SIGNATURE',
} as const;

export const MISCFILE_CATEGORIES = {
  NO_CATEGORY: 'NO_CATEGORY',
  ROOF_BUILDING: 'ROOF_BUILDING',
  ENERGY_METER: 'ENERGY_METER',
  LOCATION_STORAGE: 'LOCATION_STORAGE',
  LOCATION_WALLBOX: 'LOCATION_WALLBOX',
  HOUSE_CONNECTION_BOX: 'HOUSE_CONNECTION_BOX',
  MEASUREMENT_PROTOCOL_OBSTACLE: 'MEASUREMENT_PROTOCOL_OBSTACLE',
  MEASUREMENT_PROTOCOL_SCAFFOLD_OBSTACLE:
    'MEASUREMENT_PROTOCOL_SCAFFOLD_OBSTACLE',
  MEASUREMENT_PROTOCOL_SCAFFOLD_PUBLIC_OBSTACLE:
    'MEASUREMENT_PROTOCOL_SCAFFOLD_PUBLIC_OBSTACLE',
  MEASUREMENT_PROTOCOL_ENERGY_STORAGE_METER:
    'MEASUREMENT_PROTOCOL_ENERGY_STORAGE_METER',
  MEASUREMENT_PROTOCOL_ENERGY_METER_CABINET:
    'MEASUREMENT_PROTOCOL_ENERGY_METER_CABINET',
  MEASUREMENT_PROTOCOL_SPACE_FOR_ADDITIONAL_ENERGY_METER_CABINET:
    'MEASUREMENT_PROTOCOL_SPACE_FOR_ADDITIONAL_ENERGY_METER_CABINET',
  MEASUREMENT_PROTOCOL_VALVE: 'MEASUREMENT_PROTOCOL_VALVE',
  MEASUREMENT_PROTOCOL_HEATING_CIRCUIT_DISTRIBUTOR:
    'MEASUREMENT_PROTOCOL_HEATING_CIRCUIT_DISTRIBUTOR',
  MEASUREMENT_PROTOCOL_ROOM_CONTROLLER: 'MEASUREMENT_PROTOCOL_ROOM_CONTROLLER',
  MEASUREMENT_PROTOCOL_ROOF_AREA: 'MEASUREMENT_PROTOCOL_ROOF_AREA',
  MEASUREMENT_PROTOCOL_FLAT_ROOF: 'MEASUREMENT_PROTOCOL_FLAT_ROOF',
  MEASUREMENT_PROTOCOL_ROOF_STRUCTURES: 'MEASUREMENT_PROTOCOL_ROOF_STRUCTURES',
  MEASUREMENT_PROTOCOL_DC_CABLE_ROUTING_ALONG_OUTSIDE_FACADE:
    'MEASUREMENT_PROTOCOL_DC_CABLE_ROUTING_ALONG_OUTSIDE_FACADE',
  MEASUREMENT_PROTOCOL_DC_CABLE_ROUTING_ALONG_ROOF_CLADDING:
    'MEASUREMENT_PROTOCOL_DC_CABLE_ROUTING_ALONG_ROOF_CLADDING',
  MEASUREMENT_PROTOCOL_HOUSE_CONNECTION_BOX:
    'MEASUREMENT_PROTOCOL_HOUSE_CONNECTION_BOX',
  MEASUREMENT_PROTOCOL_OVERHEAD_LINE: 'MEASUREMENT_PROTOCOL_OVERHEAD_LINE',
  MEASUREMENT_PROTOCOL_STORAGE_FACILITY_AT_GROUND_LEVEL:
    'MEASUREMENT_PROTOCOL_STORAGE_FACILITY_AT_GROUND_LEVEL',
} as const;

export const IMAGE_CATEGORIES_LABELS = {
  NO_CATEGORY: 'imageCategories.noCategory',
  ROOF_BUILDING: 'imageCategories.roofBuilding',
  ENERGY_METER: 'imageCategories.energyMeter',
  LOCATION_STORAGE: 'imageCategories.locationStorage',
  LOCATION_WALLBOX: 'imageCategories.locationWallbox',
  HOUSE_CONNECTION_BOX: 'imageCategories.houseConnectionBox',
  MEASUREMENT_PROTOCOL_OBSTACLE: 'imageCategories.obstacle',
  MEASUREMENT_PROTOCOL_SCAFFOLD_OBSTACLE: 'imageCategories.scaffoldObstacle',
  MEASUREMENT_PROTOCOL_SCAFFOLD_PUBLIC_OBSTACLE:
    'imageCategories.scaffoldPublicObstacle',
  MEASUREMENT_PROTOCOL_ENERGY_STORAGE_METER: 'imageCategories.storageMeter',
  MEASUREMENT_PROTOCOL_ENERGY_METER_CABINET: 'imageCategories.meterCabinet',
  MEASUREMENT_PROTOCOL_SPACE_FOR_ADDITIONAL_ENERGY_METER_CABINET:
    'imageCategories.spaceForAdditionalEnergyMeterCabinet',
  MEASUREMENT_PROTOCOL_VALVE: 'imageCategories.valve',
  MEASUREMENT_PROTOCOL_HEATING_CIRCUIT_DISTRIBUTOR:
    'imageCategories.heatingCircuitDistributor',
  MEASUREMENT_PROTOCOL_ROOM_CONTROLLER: 'imageCategories.roomController',
  MEASUREMENT_PROTOCOL_ROOF_AREA: 'imageCategories.roofArea',
  MEASUREMENT_PROTOCOL_FLAT_ROOF: 'imageCategories.flatRoof',
  MEASUREMENT_PROTOCOL_ROOF_STRUCTURES: 'imageCategories.roofStructures',
  MEASUREMENT_PROTOCOL_DC_CABLE_ROUTING_ALONG_OUTSIDE_FACADE:
    'imageCategories.dcCableRoutingAlongOutsideFacade',
  MEASUREMENT_PROTOCOL_DC_CABLE_ROUTING_ALONG_ROOF_CLADDING:
    'imageCategories.dcCableRoutingAlongRoofCladding',
  MEASUREMENT_PROTOCOL_HOUSE_CONNECTION_BOX:
    'imageCategories.houseConnectionBox',
  MEASUREMENT_PROTOCOL_OVERHEAD_LINE: 'imageCategories.overheadLine',
  MEASUREMENT_PROTOCOL_STORAGE_FACILITY_AT_GROUND_LEVEL:
    'imageCategories.storageFacilityAtGroundLevel',
} as const;

export const IMAGE_CATEGORIES_FILTER = {
  NO_CATEGORY: {
    value: IMAGE_CATEGORIES.NO_CATEGORY,
    label: IMAGE_CATEGORIES_LABELS.NO_CATEGORY,
  },
  ROOF_BUILDING: {
    value: IMAGE_CATEGORIES.ROOF_BUILDING,
    label: IMAGE_CATEGORIES_LABELS.ROOF_BUILDING,
  },
  ENERGY_METER: {
    value: IMAGE_CATEGORIES.ENERGY_METER,
    label: IMAGE_CATEGORIES_LABELS.ENERGY_METER,
  },
  LOCATION_STORAGE: {
    value: IMAGE_CATEGORIES.LOCATION_STORAGE,
    label: IMAGE_CATEGORIES_LABELS.LOCATION_STORAGE,
  },
  LOCATION_WALLBOX: {
    value: IMAGE_CATEGORIES.LOCATION_WALLBOX,
    label: IMAGE_CATEGORIES_LABELS.LOCATION_WALLBOX,
  },
  HOUSE_CONNECTION_BOX: {
    value: IMAGE_CATEGORIES.HOUSE_CONNECTION_BOX,
    label: IMAGE_CATEGORIES_LABELS.HOUSE_CONNECTION_BOX,
  },
} as const;

export const LEAD_TYPE_I18N = {
  BUSINESS: 'lead.business',
  PRIVATE: 'lead.private',
} as const;

export const PHONE_CONNECTION_TYPE = {
  MOBILE: 'MOBILE',
  LANDLINE: 'LANDLINE',
} as const;

// TODO: also integrate property_goal, property_recent_distribution_board, property_roof_align, property_roof_pitch_degree
export const HOUSE_PROPERTIES = {
  BASIC_FEE_HEAT: 'BASIC_FEE_HEAT',
  BASIC_FEE_ELECTRICITY: 'BASIC_FEE_ELECTRICITY',
  DESIRED_BS_CAPACITY: 'DESIRED_BS_CAPACITY',
  DESIRED_PV_OUTPUT: 'DESIRED_PV_OUTPUT',
  DISTANCE_GROUND_GUTTER: 'DISTANCE_GROUND_GUTTER',
  DISTANCE_GUTTER_RIDGE: 'DISTANCE_GUTTER_RIDGE',
  HEAT_ELECTRICITY: 'HEAT_ELECTRICITY',
  HOUSE_ELECTRICITY: 'HOUSE_ELECTRICITY',
  HOUSE_ELECTRICITY_METER_NUMBER: 'HOUSE_ELECTRICITY_METER_NUMBER',
  HEAT_ELECTRICITY_METER_NUMBER: 'HEAT_ELECTRICITY_METER_NUMBER',
  LOADPROFILE: 'LOADPROFILE',
  PRICE_HEAT: 'PRICE_HEAT',
  PRICE_LIGHT: 'PRICE_LIGHT',
  ROOF_ALIGN: 'ROOF_ALIGN',
  ROOF_PITCH_DEGREE: 'ROOF_PITCH_DEGREE',
  ROOF_TYPE: 'ROOF_TYPE',
  ROOF_TILE_COVERAGE: 'ROOF_TILE_COVERAGE',
  ROOF_TILE_DIMENSIONS: 'ROOF_TILE_DIMENSIONS',
  ROOF_TILE_TYPE: 'ROOF_TILE_TYPE',
  SOLAR_EDGE_WIDTH_M: 'SOLAR_EDGE_WIDTH_M',
  SMART_HOME: 'SMART_HOME',
} as const;

export const SMART_HOME_VALUES = {
  NOT_INTERESTED: { value: 'NOT_INTERESTED', label: 'Kein Interesse' },
  HEATER_ONLY: { value: 'HEATER_ONLY', label: 'Nur Heizkörper' },
  UNDERFLOOR_HEATING_ONLY: {
    value: 'UNDERFLOOR_HEATING_ONLY',
    label: 'Nur Fußbodenheizung',
  },
  HEATER_AND_UNDERFLOOR_HEATING: {
    value: 'HEATER_AND_UNDERFLOOR_HEATING',
    label: 'Heizkörper und Fußbodenheizung',
  },
} as const;

export const HOUSE_PROPERTIES_MAPPING = {
  [HOUSE_PROPERTIES.ROOF_TYPE]: {
    edit: 'rooftype_id',
    content: 'rooftype_name',
    isTextField: true,
    label: 'lead.roofType', // Dachform
    keyboardType: 'default',
  },
  [HOUSE_PROPERTIES.ROOF_ALIGN]: {
    content: 'roof_align',
    label: 'lead.roofAlign', // Dachausrichtung
    isTextField: true,
    validation: validateTextMaxLength50,
    keyboardType: 'default',
  },
  [HOUSE_PROPERTIES.ROOF_PITCH_DEGREE]: {
    content: 'roof_pitch_degree',
    label: 'lead.roofPitchDegree', // Dachneigung (in Grad)
    validation: validateIntNumber0to90,
    keyboardType: 'numeric',
  },
  [HOUSE_PROPERTIES.DISTANCE_GUTTER_RIDGE]: {
    content: 'distance_gutter_ridge_m',
    label: 'lead.distanceGutterRidge', // Distanz Traufe-First (in m)
    validation: validateFloatNumberTwoDecimal0to100,
    keyboardType: 'numeric',
  },
  [HOUSE_PROPERTIES.DISTANCE_GROUND_GUTTER]: {
    content: 'distance_ground_gutter_m',
    label: 'lead.distanceGroundGutter', // Distanz Boden-Dachrinne (in m)
    validation: validateFloatNumberTwoDecimal0to100,
    keyboardType: 'numeric',
  },
  [HOUSE_PROPERTIES.SOLAR_EDGE_WIDTH_M]: {
    content: 'solar_edge_width_m',
    label: 'lead.solarEdgeWidthM', // Breite der Solarseite des Daches (in m)
    validation: validateFloatNumberTwoDecimal1to100,
    keyboardType: 'numeric',
  },
  [HOUSE_PROPERTIES.ROOF_TILE_TYPE]: {
    edit: 'rooftiletype_id',
    content: 'rooftiletype_name',
    isTextField: true,
    label: 'lead.roofTileType', // Dachziegelart
    keyboardType: 'default',
  },
  [HOUSE_PROPERTIES.ROOF_TILE_COVERAGE]: {
    content: 'roof_tile_coverage',
    label: 'lead.roofTileCoverage', // Ziegel-Deckmaß Länge x Breite (in mm)
    isDoubleField: true,
    keyboardType: 'default',
  },
  [HOUSE_PROPERTIES.ROOF_TILE_DIMENSIONS]: {
    content: 'roof_tile_length_mm|roof_tile_width_mm',
    label: 'lead.roofTileCoverage', // Ziegel-Deckmaß Länge x Breite (in mm)
    isDoubleField: true,
    validation: [validateIntNumber0to2000, validateIntNumber0to2000],
    keyboardType: 'numeric',
  },
  [HOUSE_PROPERTIES.DESIRED_PV_OUTPUT]: {
    content: 'desired_pv_output_kwp',
    label: 'lead.desiredPVOutput', // Gewünschte Leistung der Anlage (in kWp)
    validation: validateFloatNumberTwoDecimal0to100k,
    keyboardType: 'numeric',
  },
  [HOUSE_PROPERTIES.DESIRED_BS_CAPACITY]: {
    content: 'desired_bss_capacity_kwh',
    label: 'lead.desiredBSSCapacity', // Gewünschter Speicher / -größe (in kWh)
    isTextField: true,
    validation: validateTextMaxLength50,
    keyboardType: 'numbers-and-punctuation',
  },
  [HOUSE_PROPERTIES.HOUSE_ELECTRICITY]: {
    content: 'house_electricity_consumption_kwh_per_year',
    label: 'lead.houseElectricity', // Licht Stromverbrauch pro Jahr (in kWh)
    validation: validateIntNumber0to300k,
    keyboardType: 'numeric',
  },
  [HOUSE_PROPERTIES.PRICE_LIGHT]: {
    content: 'house_electricity_price_cents_per_kwh',
    label: 'lead.priceLight', // Licht Strompreis (Brutto) pro kWh (in Cent)
    validation: validateFloatNumberOneDecimal10to100,
    keyboardType: 'numeric',
  },
  [HOUSE_PROPERTIES.BASIC_FEE_ELECTRICITY]: {
    content: 'house_electricity_basic_fee_euro_per_year',
    label: 'lead.basicFeeElectricity', // Licht Grundgebühr (Brutto) pro Jahr (in Euro)
    validation: validateFloatNumberTwoDecimal0to1000,
    keyboardType: 'numeric',
  },
  [HOUSE_PROPERTIES.HOUSE_ELECTRICITY_METER_NUMBER]: {
    content: 'house_electricity_meter_number',
    label: 'lead.houseElectricityMeterNumber', // Licht Stromzählernummer
    isTextField: true,
    validation: validateTextMaxLength50,
    keyboardType: 'default',
  },
  [HOUSE_PROPERTIES.HEAT_ELECTRICITY]: {
    content: 'heat_electricity_consumption_kwh_per_year',
    label: 'lead.heatElectricity', // Wärme Stromverbrauch pro Jahr (in kWh)
    validation: validateIntNumber0to300k,
    keyboardType: 'numeric',
  },
  [HOUSE_PROPERTIES.PRICE_HEAT]: {
    content: 'heat_electricity_price_cents_per_kwh',
    label: 'lead.priceHeat', // Wärme Strompreis (Brutto) pro kWh (in Cent)
    validation: validateFloatNumberOneDecimal10to100,
    keyboardType: 'numeric',
  },
  [HOUSE_PROPERTIES.BASIC_FEE_HEAT]: {
    content: 'heat_electricity_basic_fee_euro_per_year',
    label: 'lead.basicFeeHeat', // Wärme Grundgebühr (Brutto) pro Jahr (in Euro)
    validation: validateFloatNumberTwoDecimal0to1000,
    keyboardType: 'numeric',
  },
  [HOUSE_PROPERTIES.HEAT_ELECTRICITY_METER_NUMBER]: {
    content: 'heat_electricity_meter_number',
    label: 'lead.heatElectricityMeterNumber', // Wärme Stromzählernummer
    isTextField: true,
    validation: validateTextMaxLength50,
    keyboardType: 'default',
  },
  [HOUSE_PROPERTIES.LOADPROFILE]: {
    edit: 'loadprofile_id',
    content: 'loadprofile_name',
    isTextField: true,
    label: 'lead.loadProfile', // Lastprofil
    keyboardType: 'default',
  },
  [HOUSE_PROPERTIES.SMART_HOME]: {
    content: 'smart_home',
    label: 'lead.smartHome', // Homematic
    isTextField: true,
    keyboardType: 'default',
    radioOptions: [
      {
        value: SMART_HOME_VALUES.NOT_INTERESTED.value,
        label: SMART_HOME_VALUES.NOT_INTERESTED.label,
      },
      {
        value: SMART_HOME_VALUES.HEATER_ONLY.value,
        label: SMART_HOME_VALUES.HEATER_ONLY.label,
      },
      {
        value: SMART_HOME_VALUES.UNDERFLOOR_HEATING_ONLY.value,
        label: SMART_HOME_VALUES.UNDERFLOOR_HEATING_ONLY.label,
      },
      {
        value: SMART_HOME_VALUES.HEATER_AND_UNDERFLOOR_HEATING.value,
        label: SMART_HOME_VALUES.HEATER_AND_UNDERFLOOR_HEATING.label,
      },
    ],
  },
} as const;

export const HOUSE_PROPERTIES_VIEW_REMOVED = [
  HOUSE_PROPERTIES.ROOF_TILE_DIMENSIONS,
];

export const HOUSE_PROPERTIES_EDIT_REMOVED = [
  HOUSE_PROPERTIES.ROOF_TILE_COVERAGE,
];

export const FORMNAME = {
  LEADS_FILTER: 'LEADS_FILTER',
  LEAD_CREATE: 'LEAD_CREATE',
  LEAD_SELECT: 'LEAD_SELECT',
  LEAD_DETAILS_EDIT: 'LEAD_DETAILS_EDIT',
  LEAD_DETAILS_TIMELINE: 'LEAD_DETAILS_TIMELINE',
  LEAD_DETAILS_FILES: 'LEAD_DETAILS_FILES',
  LEAD_DETAILS_FILE_EDIT: 'LEAD_DETAILS_FILE_EDIT',
  LEAD_DETAILS_IMAGE_EDIT: 'LEAD_DETAILS_IMAGE_EDIT',
  CALENDAR_ENTRY_DETAILS: 'CALENDAR_ENTRY_DETAILS',
  CALENDAR_ENTRY_CREATE: 'CALENDAR_ENTRY_CREATE',
  CALENDAR_ENTRY_EDIT: 'CALENDAR_ENTRY_EDIT',
  CALENDAR_ENTRY_DELETE: 'CALENDAR_ENTRY_DELETE',
  NOTE_CREATE: 'NOTE_CREATE',
  ADDRESS_SELECT: 'ADDRESS_SELECT',
  ACTIVITIES: 'ACTIVITIES',
  NOTE_DETAILS: 'NOTE_DETAILS',
  NOTE_DETAILS_EDIT: 'NOTE_DETAILS_EDIT',
  IMAGES_CREATE: 'IMAGES_CREATE',
  IMAGE_DELETE: 'IMAGE_DELETE',
  ADDRESS_DELETE: 'ADDRESS_DELETE',
  MISCFILE_CREATE: 'MISCFILE_CREATE',
  MISCFILE_DELETE: 'MISCFILE_DELETE',
  SATELLITE_IMAGE: 'SATELLITE_IMAGE',
  SALES_SELECT: 'SALES_SELECT',
  GLOBAL_SEARCH: 'GLOBAL_SEARCH',
  MEASUREMENT_PROTOCOL: 'MEASUREMENT_PROTOCOL',
  MEASUREMENT_PROTOCOL_META: 'MEASUREMENT_PROTOCOL_META',
  MESSAGE_CREATE: 'MESSAGE_CREATE',
  SIGNATURE_FORM: 'SIGNATURE_FORM',
} as const;

export const DRAWING_VALUES = {
  ROOF: 'ROOF',
  DOOR: 'DOOR',
  TRUCK: 'TRUCK',
  DANGER: 'DANGER',
  SCAFFOLD: 'SCAFFOLD',
  WALLBOX: 'WALLBOX',
} as const;

export const DRAWING_MARKERS_VALUES = {
  DOOR: 'DOOR',
  DANGER: 'DANGER',
  WALLBOX: 'WALLBOX',
  SCAFFOLD: 'SCAFFOLD',
} as const;

export const DRAWING_MODES = {
  MARKER: 'MARKER',
  LINE: 'LINE',
  POLYGON: 'POLYGON',
} as const;

export const SATELLITE_IMAGE_SELECT_OPTIONS = {
  SAVE_PHOTO: {
    label: 'satelliteImage.savePhoto',
    value: 'SAVE_PHOTO',
  },
  ADD_DRAWING: {
    label: 'satelliteImage.addDrawing',
    value: 'ADD_DRAWING',
  },
} as const;

export const SATELLITE_IMAGE_DRAWING_COLORS = {
  BLUE: '#00BAFF',
  GREEN: '#0CFF01',
  PURPLE: '#FF01FC',
  RED: '#EE1C25',
  ORANGE: '#FF7E00',
  YELLOW: '#FFFC00',
  WHITE: '#FFFFFF',
} as const;

export const SATELLITE_IMAGE_BACKGROUND_COLOR = '#2C2C2C';

export const FORM_CHANGE_PERSIST_TYPE = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
} as const;

export const PHONE_NUMBER_TYPE = {
  PRIVATE: {
    label: 'labelListItem.private',
    value: 'PRIVATE',
  },
  BUSINESS: {
    label: 'labelListItem.work',
    value: 'BUSINESS',
  },
} as const;

export const EMAIL_ADDRESS_TYPE = {
  PRIVATE: {
    label: 'labelListItem.private',
    value: 'PRIVATE',
  },
  BUSINESS: {
    label: 'labelListItem.work',
    value: 'BUSINESS',
  },
} as const;

export const PLATFORM_FILE_TYPE = {
  [OS.ANDROID]: 'mp3',
  [OS.IOS]: 'm4a',
} as const;

export const ENCODING = {
  BASE64: 'base64',
} as const;

export const DB_ENTITY_CHANGE_TYPE = {
  DELETE: 'delete',
  CREATE: 'create',
  UPDATE: 'update',
  NONE: 'none',
  DELETE_UNPUSHED: 'delete_unpushed',
} as const;

export const LEAD_SORT = {
  SURNAME: 'contactSurname',
  SURNAME_DSC: 'contactSurnameDsc',
  CREATED_AT: 'createdAt',
  CREATED_AT_DSC: 'createdAtDsc',
} as const;

export const API_SORT_LEAD = {
  ID: 'id',
  CREATED_AT: 'created_at',
  PRENAME: 'contact.prename',
  SURNAME: 'contact.surname',
} as const;

export const API_SORT_ORDER = {
  ASC: 'asc',
  DESC: 'dsc',
} as const;

export const TABLE_TYPE = {
  BACKEND: 'backend',
  CHANGE: 'change',
  VIEW: 'view',
} as const;

export const VALIDATION_MESSAGE = {
  DATE_END_BEFORE_START: 'validation.endDateBeforeStartDate',
} as const;

export const USER_ROLE = {
  SALES: 'SALES',
} as const;

export const MIME_TYPE = {
  APPLICATION_EPUB_ZIP: 'application/epub+zip',
  APPLICATION_PDF: 'application/pdf',
  APPLICATION_MS_WORD: 'application/msword',
  APPLICATION_VND_MS_EXCEL: 'application/vnd.ms-excel',
  APPLICATION_VND_MS_POWERPOINT: 'application/vnd.ms-powerpoint',
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_PRESENTATION:
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_SHEET:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_DOCUMENT:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  APPLICATION_XML: 'application/xml',
  APPLICATION_ZIP: 'application/zip',
  AUDIO_M4A: 'audio/m4a',
  AUDIO_MPEG: 'audio/mpeg',
  AUDIO_OPUS: 'audio/opus',
  AUDIO_WAV: 'audio/wav',
  IMAGE_BMP: 'image/bmp',
  IMAGE_GIF: 'image/gif',
  IMAGE_HEIC: 'image/heic',
  IMAGE_HEIF: 'image/heif',
  IMAGE_JPEG: 'image/jpeg',
  IMAGE_JPG: 'image/jpg',
  IMAGE_PNG: 'image/png',
  IMAGE_SVG_XML: 'image/svg+xml',
  TEXT_CSV: 'text/csv',
  TEXT_PLAIN: 'text/plain',
  TEXT_XML: 'text/xml',
  VIDEO_MP4: 'video/mp4',
  VIDEO_MPEG: 'video/mpeg',
  VIDEO_MOV: 'video/quicktime',
} as const;

export const TIME_FILTER_VALUE = {
  ALL: 'ALL',
  TODAY: 'TODAY',
} as const;

export const FCM_DEVICE_TOKEN_ERROR = {
  FCM_UNAVAILABLE: 'FCM_UNAVAILABLE',
} as const;

// The order inside is important
export const MEASUREMENT_PROTOCOL_STEPS = {
  CONSUMPTION: 'CONSUMPTION',
  PROPERTY: 'PROPERTY',
  ROOF: 'ROOF',
  OBSTACLE: 'OBSTACLE',
  SCAFFOLD: 'SCAFFOLD',
  ENERGY_STORAGE_AND_METER: 'ENERGY_STORAGE_AND_METER',
  CONNECTIONS: 'CONNECTIONS',
  CURRENT_CHECKLIST: 'CURRENT_CHECKLIST',
  CONCLUSION: 'CONCLUSION',
} as const;

export const MEASUREMENT_PROTOCOL_STEP_SUBSECTIONS = {
  PERFORMANCE_AND_STORAGE: 'PERFORMANCE_AND_STORAGE',
  LIGHT: 'LIGHT',
  WARMTH: 'WARMTH',
  LOAD_PROFILE: 'LOAD_PROFILE',
  CONSUMPTION_COMMENT: 'CONSUMPTION_COMMENT',
  NEIGHBORING_PROPERTY: 'NEIGHBORING_PROPERTY',
  AERIAL_PHOTO_OR_SKETCH: 'AERIAL_PHOTO_OR_SKETCH',
  MONUMENT_PROTECTION: 'MONUMENT_PROTECTION',
  PLANT_DELIVERY: 'PLANT_DELIVERY',
  STORAGE_FACILITY_AT_GROUND_LEVEL: 'STORAGE_FACILITY_AT_GROUND_LEVEL',
  PROPERTY_COMMENT: 'PROPERTY_COMMENT',
  INITIAL_DATA: 'INITIAL_DATA',
  ROOF_COVERING: 'ROOF_COVERING',
  DC_CABLE_ROUTING: 'DC_CABLE_ROUTING',
  ROOF_STRUCTURES: 'ROOF_STRUCTURES',
  ROOF_TRUSS: 'ROOF_TRUSS',
  ROOF_AREA: 'ROOF_AREA',
  ROOF_COMMENT: 'ROOF_COMMENT',
  OBSTACLES: 'OBSTACLES',
  OBSTACLES_COMMENT: 'OBSTACLES_COMMENT',
  STORAGE_AND_METER_CABINET: 'STORAGE_AND_METER_CABINET',
  STORAGE_LOCATION: 'STORAGE_LOCATION',
  METER_CABINET: 'METER_CABINET',
  METER_CONCEPT: 'METER_CONCEPT',
  STORAGE_AND_METER_COMMENT: 'STORAGE_AND_METER_COMMENT',
  HOUSE_CONNECTION_BOX_LOCATION: 'HOUSE_CONNECTION_BOX_LOCATION',
  LINE_TYPE: 'LINE_TYPE',
  CONNECTIONS_COMMENT: 'CONNECTIONS_COMMENT',
  CURRENT_CHECKLIST: 'CURRENT_CHECKLIST',
  CURRENT_CHECKLIST_COMMENT: 'CURRENT_CHECKLIST_COMMENT',
  SCAFFOLD_OBSTACLES: 'SCAFFOLD_OBSTACLES',
  SCAFFOLD_POSITION: 'SCAFFOLD_POSITION',
  SCAFFOLD_COMMENT: 'SCAFFOLD_COMMENT',
  CONCLUSION: 'CONCLUSION',
  CONCLUSION_COMMENT: 'CONCLUSION_COMMENT',
} as const;

export const STEP_TO_VISUAL_DOCUMENTATION = {
  [MEASUREMENT_PROTOCOL_STEPS.PROPERTY]: (measurementProtocol, files) =>
    !(
      measurementProtocol.propertyHasSafeStorageFacility &&
      !validateTrueOrFalse(measurementProtocol.storageFacilityAtGroundLevel)
    ) ||
    files.some(
      file =>
        file.measurement_protocol_id === measurementProtocol.persistId &&
        file.category ===
          IMAGE_CATEGORIES.MEASUREMENT_PROTOCOL_STORAGE_FACILITY_AT_GROUND_LEVEL,
    ),
  [MEASUREMENT_PROTOCOL_STEPS.OBSTACLE]: (measurementProtocol, files) =>
    !measurementProtocol.obstaclesExist ||
    files.some(
      file =>
        file.measurement_protocol_id === measurementProtocol.persistId &&
        file.category === IMAGE_CATEGORIES.MEASUREMENT_PROTOCOL_OBSTACLE,
    ),
  [MEASUREMENT_PROTOCOL_STEPS.SCAFFOLD]: (measurementProtocol, files) =>
    (!measurementProtocol.obstaclesToScaffoldSafetyExist ||
      files.some(
        file =>
          file.measurement_protocol_id === measurementProtocol.persistId &&
          file.category ===
            IMAGE_CATEGORIES.MEASUREMENT_PROTOCOL_SCAFFOLD_OBSTACLE,
      )) &&
    ((!measurementProtocol.scaffoldHindersPublicAccess &&
      !measurementProtocol.scaffoldOnPublicFootpath) ||
      files.some(
        file =>
          file.measurement_protocol_id === measurementProtocol.persistId &&
          file.category ===
            IMAGE_CATEGORIES.MEASUREMENT_PROTOCOL_SCAFFOLD_PUBLIC_OBSTACLE,
      )),
  [MEASUREMENT_PROTOCOL_STEPS.ENERGY_STORAGE_AND_METER]: (
    measurementProtocol,
    files,
    isInitialAnalysisProtocol,
  ) =>
    (!measurementProtocol.energyMeterCabinetSizeSufficient ||
      files.some(
        file =>
          file.measurement_protocol_id === measurementProtocol.persistId &&
          file.category ===
            IMAGE_CATEGORIES.MEASUREMENT_PROTOCOL_ENERGY_METER_CABINET,
      )) &&
    (isInitialAnalysisProtocol ||
      files.some(
        file =>
          file.category ===
          IMAGE_CATEGORIES.MEASUREMENT_PROTOCOL_ENERGY_STORAGE_METER,
      )) &&
    (!measurementProtocol.energyMeterCabinetSizeSufficient &&
    measurementProtocol.spaceForAdditionalEnergyMeterCabinetExists
      ? files.some(
          file =>
            file.category ===
            IMAGE_CATEGORIES.MEASUREMENT_PROTOCOL_SPACE_FOR_ADDITIONAL_ENERGY_METER_CABINET,
        )
      : true),
  [MEASUREMENT_PROTOCOL_STEPS.CURRENT_CHECKLIST]: (
    mp,
    files,
    isInitialAnalysisProtocol,
  ) => {
    if (isInitialAnalysisProtocol) {
      return isInitialAnalysisProtocol;
    }
    const valvesVisualDocumentation =
      !(
        mp.smartHome === SMART_HOME_VALUES.HEATER_ONLY.value ||
        mp.smartHome === SMART_HOME_VALUES.HEATER_AND_UNDERFLOOR_HEATING.value
      ) ||
      files.some(
        file =>
          file.measurement_protocol_id === mp.persistId &&
          file.category === IMAGE_CATEGORIES.MEASUREMENT_PROTOCOL_VALVE,
      );
    const heatingCircuitDistributorsVisualDocumentation =
      !(
        mp.smartHome === SMART_HOME_VALUES.UNDERFLOOR_HEATING_ONLY.value ||
        mp.smartHome === SMART_HOME_VALUES.HEATER_AND_UNDERFLOOR_HEATING.value
      ) ||
      files.some(
        file =>
          file.measurement_protocol_id === mp.persistId &&
          file.category ===
            IMAGE_CATEGORIES.MEASUREMENT_PROTOCOL_HEATING_CIRCUIT_DISTRIBUTOR,
      );
    const roomControllersVisualDocumentation =
      !(
        mp.smartHome === SMART_HOME_VALUES.UNDERFLOOR_HEATING_ONLY.value ||
        mp.smartHome === SMART_HOME_VALUES.HEATER_AND_UNDERFLOOR_HEATING.value
      ) ||
      files.some(
        file =>
          file.measurement_protocol_id === mp.persistId &&
          file.category ===
            IMAGE_CATEGORIES.MEASUREMENT_PROTOCOL_ROOM_CONTROLLER,
      );
    return (
      valvesVisualDocumentation &&
      heatingCircuitDistributorsVisualDocumentation &&
      roomControllersVisualDocumentation
    );
  },
  [MEASUREMENT_PROTOCOL_STEPS.ROOF]: (mp, files, isInitialAnalysisProtocol) =>
    mp.roofs.every(
      roof =>
        ((roof.flatRoof &&
          files.some(
            file =>
              file.category ===
                IMAGE_CATEGORIES.MEASUREMENT_PROTOCOL_FLAT_ROOF &&
              file.measurement_protocol_roof_id === roof.persistId &&
              file.measurement_protocol_id === mp.persistId,
          )) ||
          (!roof.flatRoof &&
            roof.areas.every(area =>
              files.some(
                file =>
                  file.measurement_protocol_id === mp.persistId &&
                  file.measurement_protocol_roof_area_id === area.persistId &&
                  file.category ===
                    IMAGE_CATEGORIES.MEASUREMENT_PROTOCOL_ROOF_AREA,
              ),
            ))) &&
        (isInitialAnalysisProtocol ||
          ((!roof.roofStructuresExist ||
            files.some(
              file =>
                file.category ===
                  IMAGE_CATEGORIES.MEASUREMENT_PROTOCOL_ROOF_STRUCTURES &&
                file.measurement_protocol_roof_id === roof.persistId &&
                file.measurement_protocol_id === mp.persistId,
            )) &&
            (!roof.dcCableRoutingAlongOutsideFacadePossible ||
              files.some(
                file =>
                  file.category ===
                    IMAGE_CATEGORIES.MEASUREMENT_PROTOCOL_DC_CABLE_ROUTING_ALONG_OUTSIDE_FACADE &&
                  file.measurement_protocol_roof_id === roof.persistId &&
                  file.measurement_protocol_id === mp.persistId,
              )) &&
            (!roof.dcCableRoutingAlongRoofCladdingPossible ||
              files.some(
                file =>
                  file.category ===
                    IMAGE_CATEGORIES.MEASUREMENT_PROTOCOL_DC_CABLE_ROUTING_ALONG_ROOF_CLADDING &&
                  file.measurement_protocol_roof_id === roof.persistId &&
                  file.measurement_protocol_id === mp.persistId,
              )))),
    ),
  [MEASUREMENT_PROTOCOL_STEPS.CONNECTIONS]: (mp, files) =>
    mp.houseConnectionBoxes.every(
      houseConnectionBox =>
        files.some(
          file =>
            file.measurement_protocol_id === mp.persistId &&
            file.measurement_protocol_house_connection_box_id ===
              houseConnectionBox.persistId &&
            file.category ===
              IMAGE_CATEGORIES.MEASUREMENT_PROTOCOL_HOUSE_CONNECTION_BOX,
        ) &&
        (houseConnectionBox.cableRoutingType !==
          CABLE_ROUTING_TYPE_OPTIONS.OVERHEAD_LINE ||
          files.some(
            file =>
              file.measurement_protocol_id === mp.persistId &&
              file.measurement_protocol_house_connection_box_id ===
                houseConnectionBox.persistId &&
              file.category ===
                IMAGE_CATEGORIES.MEASUREMENT_PROTOCOL_OVERHEAD_LINE,
          )),
    ),
} as const;

export const COMPRESSION_ERROR_KEY = {
  NOOP: 'NOOP',
  COMPRESSION_FAILURE: 'COMPRESSION_FAILURE',
  LOCKED_BY_OTHER_COMPRESSION: 'LOCKED_BY_OTHER_COMPRESSION',
  FILE_HANDLING: 'FILE_HANDLING',
} as const;

export const MEASUREMENT_PROTOCOL_DIALOGS_CONTENT = {
  INITIAL: 'INITIAL',
  ADD_EDIT_ITEMS: 'ADD_EDIT_ITEMS',
  CONSUMPTION: {
    PHOTOGRAPHY: 'PHOTOGRAPHY',
  },
  PROPERTY: {
    AERIAL_VIEW: 'AERIAL_VIEW',
  },
  ROOF: {
    MAIN_BUILDING: 'MAIN_BUILDING',
    BRICK_MEASURE: 'BRICK_MEASURE',
    EXECUTION_SUBSTRUCTURE: 'EXECUTION_SUBSTRUCTURE',
    ROOF_STRUCTURES: 'ROOF_STRUCTURES',
  },
  OBSTACLE: 'OBSTACLE',
  CONNECTIONS: {
    HAK_PHOTODOCUMENTATION: 'HAK_PHOTODOCUMENTATION',
  },
  SCAFFOLD: {
    SCAFFOLD_OBSTACLE: 'SCAFFOLD_OBSTACLE',
    PUBLIC_WALKWAY: 'PUBLIC_WALKWAY',
  },
  ENERGY_STORAGE_AND_METER: {
    SPACE_REQUIREMENTS: 'SPACE_REQUIREMENTS',
    EQUIPOTENTIAL_BONDING_BAR: 'EQUIPOTENTIAL_BONDING_BAR',
  },
} as const;

export const ITEM_TO_DELETE = {
  ADDRESS: 'address',
  CALENDAR_ENTRY: 'calendarEntry',
  EMAIL: 'email',
  FILE: 'file',
  PHONE_NUMBER: 'phoneNumber',
} as const;

export const DEEP_LINK_TYPE = {
  INVALID: 'invalid',
  UNKNOWN: 'unknown',
  VIEW_ENTITY: 'viewEntity',
} as const;

export const UNITS = {
  kWp: 'kWp',
  kWh: 'kWh',
  euro: '€',
  cent: 'cent',
  piece: (translate: (key: string) => string) =>
    translate('measurementProtocolSteps.energyStorageAndMeter.piece'),
  kW: 'kW',
  m: 'm',
  cm: 'cm',
  mm: 'mm',
  degrees: '°',
} as const;

export const SNACKBAR_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
} as const;

// ids of lead sources with visual indicators
export const OWN_LEAD_SOURCE_ID = 33;
export const FAIR_LEAD_SOURCE_ID = 99;
export const CONTACT_PAGE_SOURCE_ID = 44;

export const LEAD_SOURCE_TO_LABEL_MAP = {
  [OWN_LEAD_SOURCE_ID]: 'lead.source.ownLead',
  [FAIR_LEAD_SOURCE_ID]: 'lead.source.fairLead',
  [CONTACT_PAGE_SOURCE_ID]: 'lead.source.contactPage',
} as const;

export const TRANSLATION_LOCALE_CODES = {
  DE: 'DE',
} as const;

export const MEASUREMENT_PROTOCOL_ENTITY_TO_NUMBER_PROPERTIES_MAP = {
  [ENTITY.MEASUREMENT_PROTOCOL]: MEASUREMENT_PROTOCOL_NUMBER_PROPERTIES,
  [ENTITY.MEASUREMENT_PROTOCOL_ROOF]:
    MEASUREMENT_PROTOCOL_ROOF_NUMBER_PROPERTIES,
  [ENTITY.MEASUREMENT_PROTOCOL_ROOF_AREA]:
    MEASUREMENT_PROTOCOL_ROOF_AREA_NUMBER_PROPERTIES,
} as const;

export const HTTP_METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
} as const;
