import { MEASUREMENT_PROTOCOL_STEPS } from '../../config/constants';

// eslint-disable-next-line
export default {
  translation: {
    dummy: 'EN Dummy',
    misc: {
      pdfDropdown: {
        measurementProtocolPdf: 'Measurement protocol PDF',
        initialAnalysis: 'Initial analysis',
        initialAnalysisPdf: 'Initial analysis PDF',
        signedProtocol: 'Signed protocol',
        downloadPdf: 'Download PDF',
        notConcluded: 'Please conclude measurement protocol',
        current: 'Current result',
      },
    },
    snackbar: {
      formSubmitSuccess: 'Measurement protocol updated successfully!',
      formSubmitError:
        'Measurement protocol could not be updated! Check for errors.',
      stepError: 'Error in tab',
      formFirstSubmitError: 'Measurement protocol could not be created',
      alertTitleSuccess: 'Success!',
      alertTitleError: 'Caution!',
    },
    MP: {
      yes: 'Yes',
      no: 'No',
      roofStructuresVisualDocumentation: 'Photo/Video documentation',
      step: {
        [MEASUREMENT_PROTOCOL_STEPS.CONSUMPTION]: 'Consumption',
        [MEASUREMENT_PROTOCOL_STEPS.PROPERTY]: 'Property',
        [MEASUREMENT_PROTOCOL_STEPS.ROOF]: 'Roof',
        [MEASUREMENT_PROTOCOL_STEPS.OBSTACLE]: 'Obstacles',
        [MEASUREMENT_PROTOCOL_STEPS.SCAFFOLD]: 'Scaffold',
        [MEASUREMENT_PROTOCOL_STEPS.ENERGY_STORAGE_AND_METER]:
          'Storage & Meter',
        [MEASUREMENT_PROTOCOL_STEPS.CONNECTIONS]: 'Connections',
        [MEASUREMENT_PROTOCOL_STEPS.CURRENT_CHECKLIST]: 'Checklist AC/DC',
        [MEASUREMENT_PROTOCOL_STEPS.CONCLUSION]: 'Signature',
      },
      dialogs: {
        filePreview: 'File preview',
        downloadFile: 'Download file',
        downloadVideo: 'Please download the video to watch',
        deleteFile: 'Delete file',
        editFile: 'Edit File',
        save: 'Save',
        cancel: 'Cancel',
        namingError: 'Name could not be changed',
      },
      header: {
        mpCompleted: 'Measurement protocol completed',
        mpInProgress: 'Measurement protocol in progress',
        mpNotStarted: 'Measurement protocol not started',
      },
      // whole sales-app translation file pasted under this line
      error: {
        default: 'Something went wrong',
        noConnection: 'No internet connection',
        permissionMic: 'Please give the app permission to use the microphone',
        permissionSpeechRecognition:
          'Please give the app permission to use speech recognition',
        permissionLocation:
          'Please give the app permission to access the location data',
        userNotAllowed: 'Account type incompatible',
        leadNotFound: 'Lead could not be found',
        contactsSync: {
          permission: 'Please give the app permission to access your contacts',
          notFound: 'No contact found',
          deleteFailed: 'Failed to delete contact',
        },
        fileOpenFailed: 'Failed to open file',
        fileExists: 'File already exists. The file will not be uploaded.',
        numberNotRecognized: 'Failed to recognize number',
        photoTooBig: "The image shouldn't be larger than %{size}.",
        videoTooBig: "The video shouldn't be larger than %{size}.",
        fileTooBig: "The file shouldn't be larger than %{size}.",
        fileDeleteFailed: 'Failed to deleted the file.',
      },
      notification: {
        test: 'Test notification',
      },
      entity: {
        name: {
          CALENDAR_ENTRY: 'Calendar entry',
          IMAGE: 'Image',
          PHONE_NUMBER: 'Contact',
          ADDRESS: 'Address',
          MAP: 'Satellite image',
          LOCAL_BINARY_FILE: 'File',
          MISC_FILE: 'File',
          NOTE: 'Note',
          FILE: 'Datei',
        },
        action: {
          success: {
            create: '%{entity} created successfully',
            update: '%{entity} updated successfully',
            delete: '%{entity} deleted successfully',
          },
          error: {
            create: 'Failed to create %{entity}',
            update: 'Failed to update %{entity}',
            delete: 'Failed to delete %{entity}',
          },
        },
      },
      success: {
        signatureAdded: 'Signature added successfully',
        numberRecognized: 'Number recognized successfully',
      },
      validation: {
        emailOpenFail: 'Failed to open email client',
        endDateBeforeStartDate:
          'The beginning of the appointment must not be after its end',
        invalidEmailAddress: 'Invalid email address',
        invalidPhoneNumber: 'Invalid phone number',
        leadPhaseChangeOnlyAllowedForOpenLeads:
          'Changing the lead phase is only allowed for open leads',
        leadPhaseFillInMandatoryFields: 'Please fill out the mandatory fields',
        lengthZip: 'Maximum 5 characters',
        numberRequired: 'Required number',
        onlyPositiveNumbers: 'Only integer, positive values',
        onlyIntegerNumbers: 'Required integer number',
        onlyIntegerNumberFrom1: 'Only integer values greater than 0',
        onlyIntegerNumbersFrom0to5: 'Required integer number from 0 to 5',
        onlyIntegerNumbersFrom1to5: 'Required integer number from 1 to 5',
        onlyIntegerNumbersFrom0to10: 'Required integer number from 0 to 10',
        onlyIntegerNumbersFrom1to10: 'Required integer number from 1 to 10',
        onlyIntegerNumbersFrom10to30: 'Required integer number from 10 to 30',
        onlyIntegerNumbersFrom1to50: 'Only integer values between 1 and 50',
        onlyIntegerNumbersFrom1to100: 'Required integer number from 1 to 100',
        onlyIntegerNumbersFrom0to100: 'Required integer number from 0 to 100',
        onlyIntegerNumbersFrom0to1000: 'Required integer number from 0 to 1000',
        onlyIntegerNumbersFrom0to2000: 'Required integer number from 0 to 2000',
        onlyIntegerNumbersFrom0to100000:
          'Required integer number from 0 to 100000',
        onlyIntegerNumbersFrom0to300000:
          'Required integer number from 0 to 300000',
        onlyIntegerNumbersFrom0to90: 'Required integer number from 0 to 90',
        onlyNumbersFrom0to30000noDecimal:
          'Required integer number from 0 to 30000',
        onlyNumbersFrom0to100000noDecimal:
          'Required integer number from 0 to 100000',
        onlyNumbersFrom0to100: 'Required number from 0 to 100',
        onlyNumbersFrom10to100oneDecimal: 'Required number from 10,0 to 100,0',
        onlyNumbersFrom1to100oneDecimal: 'Required number from 1,0 to 100,0',
        onlyNumbersFrom0to100twoDecimal: 'Required number from 0,00 to 100,00',
        onlyNumbersFrom0to100oneDecimal: 'Required number from 0,0 to 100,0',
        onlyNumbersFrom0to150oneDecimal: 'Required number from 0,0 to 150,0',
        onlyNumbersFrom1to100twoDecimal: 'Required number from 1,00 to 100,00',
        onlyNumbersFrom0to1000twoDecimal:
          'Required number from 0,00 to 1000,00',
        onlyNumbersFrom0to10000twoDecimal:
          'Required number from 0,00 to 10.000,00',
        onlyNumbersFrom0to100000twoDecimal:
          'Required number from 0,00 to 100.000,00',
        positiveNumberRequired: 'Only positive values',
        required: 'Needed',
        atLeastOneRequired: 'At least 1 required',
        emptyMessage: 'Empty message not allowed',
        oneRequired: 'An info needed',
        textLength: 'Maximum 50 characters',
        textLengthRequired50: 'Needed. Maximum 50 characters',
        textLengthRequired100: 'Needed. Maximum 100 characters',
        textLengthRequired200: 'Needed. Maximum 200 characters',
        textLengthRequired500: 'Needed. Maximum 500 characters',
        textLength100: 'Maximum 100 characters',
        textLengthNonRequired500: 'Maximum 500 characters',
        textLength1500: 'Maximum 1500 characters',
        validate0to100: 'Number should be from 0 to 100',
        validate0to200: 'Number should be from 0 to 200',
        onlyNumbersFrom10to100: 'Number should be from 10 to 100',
        onlyIntegerNumbersFrom10to100: 'Number should be from 10 to 100',
        validateValueMustBeTrue: 'Value must be true',
        measurementProtocol: {
          visualRepresentationInPropertyStepNeeded:
            'First photo/sketch needed on the property',
          visualRepresentationAndDeliveryZoneNeeded:
            'Photo/Draft and delivery zone required',
          visualRepresentationNeeded: 'Photo or draft required',
          visualDocumentationNeeded: 'Visual documentation required',
          visualRepresentationInStepsNeeded:
            'Photo or draft in other steps required',
          visualRepresentationScaffoldNeeded:
            'Photo/sketch and frame lengths between 0.00 and 100.00 required',
          adjustmentsToSolarThermalSystem: 'Required',
          abortMeasurementProtocol:
            'The facility can not be built. The measurement protocol has to be aborted.',
          finishDrawing: 'Finish the drawing or delete it',
        },
      },
      action: {
        login: 'Log in',
        logout: 'Logout',
        submit: 'Confirm',
        save: 'Save',
        close: 'Close',
        openDetails: 'Open details',
        upload: 'Upload',
        download: 'Download',
        delete: 'Delete',
        back: 'Back',
        continue: 'Continue',
        proceed: 'Continue',
        finish: 'Ready',
        sync: 'Sync Now',
        record: 'Start recording audio',
        recordLimit: '(maximum of one minute)',
        playAudio: 'Play audio recording',
        addDescription: 'Specify description',
        addAudio: 'Add audio recording',
        addAddress: 'Add address',
        addPhoneNumber: 'Add phone number',
        addEmailAddress: 'Add an email address',
        add: 'Add',
        manageNotifications: 'Manage notifications',
        searchAddress: 'Find address (Str., Nr., ZIP)',
        applyFilter: 'Apply filters',
        addSatelliteImage: 'Add aerial image',
        showVisualRepresentation: 'View aerial photo',
        select: 'Choose',
        edit: 'Edit',
        cancel: 'Abort',
        stop: 'Stop',
        downloadAll: 'Download all',
        skip: 'skip',
        send: 'Send',
      },
      title: {
        focus: 'Focus',
        leads: 'Leads',
        activities: 'Activities',
        map: 'Card',
        focusHeader: 'Your schedule',
        leadsHeader: 'Your leads',
        leadDetails: 'Your Lead',
        activitiesHeader: 'Your activities',
        settings: 'Settings',
        audioRecorder: 'Add audio recording',
        calendarEntryDetails: 'Appointment Details',
        calendarEntriesOverdue: 'Overdue dates',
        confirmationEmail: 'Optional appointment confirmation',
        emails: 'Emails',
        calendar: 'Calendar',
        mailbox: 'Post office box',
        info: 'Info',
        timeline: 'Timeline',
        files: 'Files',
        fileEdit: 'File edit',
        addAddress: 'Add location',
        createCalendarEntry: 'New date',
        noteDetails: 'Note Details',
        theme: 'Theme',
        lastSync: 'Finally: ',
        syncing: 'Synchronizing...',
        filter: 'Filter',
        leadEdit: 'Lead edit',
        calendarEntryEdit: 'Calendar entry edit',
        noteEdit: 'Note edit',
        editMeasurementProtocol: 'Edit Measurement Protocol',
        startMeasurementProtocol: 'Start Measurement Protocol',
      },
      theme: {
        device: 'Device',
        light: 'Light Modus',
        dark: 'Dark Mode',
        dialogTitle: 'Theme Settings',
      },
      dialog: {
        activity: 'Activity',
        addOrEditSatelliteImage: 'Create / change aerial photograph',
        call: 'Call',
        confirm: 'Confirm',
        confirmLowerValue:
          'Are you sure that the count should be reduced? Existing data can get lost through this action.',
        chooseFile: 'Select File',
        choosePhotos: 'Select photos',
        chooseVideos: 'Select videos',
        chooseStatus: 'Select status',
        choosePhotosOrVideosFromGallery: 'Select from gallery',
        choosePhotosOrVideosFromFiles: 'Select from files',
        chooseSatelliteImageAddress: 'Select address for aerial photography',
        chooseVisualRepresentation: 'Select address for aerial photography',
        create: 'Create New',
        createEntry: 'New date',
        createLead: 'New Lead',
        createNote: 'New note',
        file: 'Photo / File',
        message: 'Message',
        distribution: 'Sales',
        phone: 'Telephone',
        sms: 'SMS',
        takePhoto: 'Take a photo',
        takeVideo: 'Take a video',
        whatsApp: 'WhatsApp',
        writeMessage: 'Write a message',
        newAddress: 'New address',
        confirmLogoutTitle: 'Are you sure you want to log out ?',
        confirmLogoutMessage:
          'There are data / files that have not yet been successfully transferred. Logging out means that these data / files are lost and have to be uploaded again.',
        newMessage: 'New internal message to planners',
      },
      deleteDialog: {
        titleStart: 'Are you sure you have this',
        altTitleStart: 'Are you sure you have this',
        titleEnd: 'want to delete?',
        subtitleStart: 'After deletion, the',
        altSubtitleStart: 'After deletion, the',
        subtitleEnd: 'cannot be restored. Proceed anyway?',
        address: 'Address',
        calendarEntry: 'Termin',
        email: 'E-mail address',
        file: 'File',
        note: 'Note',
        phoneNumber: 'Phone number',
        delete: 'delete',
      },
      warningDialog: {
        unsavedChangesTitle: 'Are you sure you want to leave this view?',
        unsavedChangesSubtitle:
          'Your changes will not be saved. Do you still want to continue?',
        unsavedChangesConfirm: 'Continue without saving',
        lowerValueTitle: 'Are you sure you want to decrease the number?',
        lowerValueSubtitle:
          'Existing data may be lost as a result of this action.',
        lowerValueConfirm: 'Continue anyway',
        unfinishedInitialAnalysisTitle: 'Complete initial analysis',
        unfinishedInitialAnalysisSubtitle:
          'A complete initial analysis is necessary for this phase change.',
      },
      eventButton: {
        done: 'Appointment done',
        overdue: 'Overdue done',
        open: 'Open appointment',
      },
      date: {
        name: 'Date',
        start: 'Start',
        end: 'End',
        format: 'dd.MM.yyyy',
        formatShort: 'MM.yyyy',
        formatWithoutZero: 'd.M.yyyy',
        timeFormat: 'HH:mm',
        dateTimeFormat: 'dd.MM.yyyy HH:mm',
        dateTimeFormatWithSeconds: 'dd.MM.yyyy HH:mm:ss',
        today: 'Today',
        sunday: 'Sunday',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        allDay: 'All-day',
        mon: 'Mo',
        tue: 'Tu',
        wed: 'We',
        thu: 'Thu',
        fri: 'Fr',
        sat: 'Sat',
        sun: 'Sun',
      },
      calendarEntry: {
        address: 'Address',
        allDay: 'Full day',
        audio: 'Audio',
        overdue: 'Overdue date',
        emptyFocusSection: 'No appointments for this day',
        entriesForDate: 'Your dates for the %{date}',
        lead: 'Lead',
        leadNotChangeable: 'Lead cannot be changed',
        nothingOverdue: 'No overdue appointments',
        period: 'Period',
        recordButtonLimit: 'Start recording (maximum of one minute)',
        recording: 'Recording in progress (maximum of one minute)',
        type: {
          call: 'Call',
          email: 'Email',
          task: 'Task',
          meeting: 'Meeting',
          offerMeeting: 'Offer Meeting',
          measurementMeeting: 'Measurement',
        },
        unknownOwner: 'Unknown owner',
        selectAddress: 'Select address for appointment',
        create: 'Appointment has been successfully created!!',
      },
      lead: {
        addAdditionalEmailAddress: 'Add another email address',
        addAdditionalPhoneNumber: 'Add another phone number',
        address: 'Address',
        basicFeeElectricity: 'Basic fee (gross) electricity (in euros)',
        basicFeeHeat: 'Basic fee (gross) heat (in euros)',
        business: 'Work',
        contact: 'Contact details',
        currentLocation: 'Current location',
        customerData: 'Customer',
        customerType: 'Customer Type',
        desiredBSSCapacity: 'Desired memory size (in kWh)',
        desiredPVOutput: 'Desired power of the system (in kWp)',
        distanceGroundGutter: 'Height from floor to gutter (in m)',
        distanceGutterRidge: 'Distance Eaves-First (in m)',
        done: 'Done',
        emptyTimeline: 'No appointments for this lead',
        familyAndFriendsEnergyConsumption:
          'Family and friends electricity consumption per year (in kWh)',
        heatElectricity: 'Heat-electricity consumption per year (in kWh)',
        heatElectricityMeterNumber: 'Heat electricity meter number',
        smartHome: 'Installation Smart Home (Homematic)',
        smartHomeAnswer1: 'No interest',
        smartHomeAnswer2: 'Radiators only',
        smartHomeAnswer3: 'Underfloor heating only',
        smartHomeAnswer4: 'Radiators and underfloor heating',
        houseElectricity: 'Light-electricity consumption per year (in kWh)',
        houseElectricityMeterNumber: 'Light electricity meter number',
        housing: 'Real Estate Data',
        housingDescription: 'Property Designation',
        idLink: 'ID Link',
        leadInfo: 'Lead Info',
        length: 'Length',
        linkCopied: 'Link has been copied!',
        loadProfile: 'Load Profile',
        lost: 'Lost',
        mm: 'mm',
        measurementProtocol: 'Measurement protocol',
        measurementProtocolStart: 'Start measurement protocol',
        measurementProtocolContinue: 'Continue measurement protocol',
        measurementProtocolShow: 'Show measurement protocol',
        name: 'Customer Name',
        open: 'Open',
        source: {
          ownLead: 'Own Lead',
          fairLead: 'Fair Lead',
          contactPage: 'Contact Page',
        },
        planned: 'Agreed',
        prename: 'Forename',
        priceHeat: 'Heat-electricity price (gross) per kWh (in cents)',
        priceLight: 'Light electricity price (gross) per kWh (in cents)',
        private: 'Private',
        roofAlign: 'Roof alignment',
        roofPitchDegree: 'Roof pitch (in degrees)',
        roofTileCoverage: 'Brick deck size length x width (in mm)',
        roofTileLength: 'Brick deck size length (in mm)',
        roofTileType: 'Roof Tile Type',
        roofType: 'Roof Type',
        roofTileWidth: 'Brick deck size width (in mm)',
        sales: 'Sales',
        salutation: 'Salutation',
        secondaryAddress: 'Different real estate address',
        solarEdgeWidthM: 'Width of solar roof edge (in m)',
        surname: 'Surname',
        title: 'Title',
        uploaded: 'Uploaded',
        width: 'Width',
        won: 'Won',
        deleteContactPrompt: {
          question:
            'Do you want to delete the lead phone number from the device?',
          yes: 'Yes',
          no: 'No',
        },
        congratulations: 'Congratulations!',
        wonALead: 'You have won a lead',
        keepUp: 'Keep it up!',
        created: 'Lead has been successfully created!',
      },
      leadFileEdit: {
        title: 'File name edit',
        previousLabel: 'Previous file name',
        newLabel: 'New file name',
        currentName: 'File name',
      },
      email: {
        newEmails: 'New Email ||||  New emails',
        noNewEmails: 'No new emails',
      },
      measurementProtocolSteps: {
        property: {
          headerTitle: 'Property',
          propertyNeighborDistanceLawsKnown:
            'The requirements of the state building code are known with regard to the distance to the neighbouring property.',
          propertyNeighborDistanceKept:
            'Is the distance to the neighbouring property maintained?',
          neighboringProperty: 'Neighboring property',
          aerialPhotoSketch: 'Aerial photo / sketch',
          plantDelivery: 'Plant delivery',
          annotatedSatelliteImage: 'Aerial photo including all markings',
          yes: 'Yes',
          no: 'No',
          customerBearsRisk:
            'I nevertheless commission EKD with the construction of the facility. The client indemnifies the contractor and bears the risk alone.',
          abortProcess: 'Plant cannot be built.',
          propertyUnderMonumentalProtectionTitle: 'Monument protection',
          propertyUnderMonumentalProtectionQuestion:
            'Restrictions due to monument or ensemble protection?',
          propertyUnderMonumentalProtectionYes:
            'The client assumes responsibility for obtaining permission from the heritage conservation office.',
          deliveryZoneTitle: 'Delivery of the plant',
          deliveryZoneSubTitle: 'Delivery zone',
          propertyHasDeliveryZone:
            'A delivery zone for 3-4 pallets is available.',
          markDeliveryZone:
            'Please mark the delivery zone in the aerial photograph.',
          markPosition: 'Tap to mark the desired position.',
          propertyHasSafeStorageFacility:
            'Is a secure (protection from rain and theft) storage facility available?',
          googleMapsPhoto: 'Aerial photo with Google Maps',
          sketchOrDronePhoto: 'Photo of sketch/drone shot',
          makeSketch: 'Make a sketch in the app',
          showDeliveryZone: 'Show delivery zone',
          editDeliveryZone: 'Edit delivery zone',
          addDeliveryZone: 'Add delivery zone',
          storageFacilityAtGroundLevelTitle: 'Storage facility at ground level',
          storageFacilityAtGroundLevel:
            'Can the storage facility be accessed at ground level (no steep slope, curb or stairs)?',
          storageFacilityAtGroundLevelVisualDocumentation:
            'Photo/Video documentation',
        },
        obstacles: {
          headerTitle: 'Obstacles for scaffolding',
          addImage: 'Add house photo',
          info: 'Please mark obstacles in the graphic.',
          obstacles: 'Obstacles',
          additionalCosts: 'Additional costs may arise.',
          editObstacles: 'Edit obstacles',
          addObstacles: 'Add obstacles',
          showObstacles: 'Show obstacles',
          obstaclesExist:
            'Are there trees/houses/obstacles (west, south or east) that are higher than the gutter or within a maximum radius of 20 m?',
          addPhotosVideos: 'Add photos/videos',
          markObstacles: 'Please mark obstacles in the graphic.',
          tapToMark: 'Tap to mark the desired position.',
          drawObstacles: 'Draw obstacles',
          ventilationPipesExist: 'Are ventilation pipes available?',
          ventilationPipeRelocationByCustomerKnownCheckbox:
            'If a transfer is necessary or desired, it is known that this is to be carried out by the client.',
          unmentionedShadingNotPartOfOfferKnownTitle:
            'All shades not listed are not part of the offer.',
          unmentionedShadingNotPartOfOfferKnownCheckbox:
            'It is known that any shading that is not listed is not part of the offer.',
          radioYes: 'Yes',
          radioNo: 'No',
          obstacleVisualDocumentation: 'Photo/Video documentation',
        },
        roof: {
          radioAnswerRoofTileAttachmentMortar: 'mortared',
          radioAnswerRoofTileAttachmentClamp: 'clamped',
          radioAnswerRoofTileAttachmentGlued: 'glued',
          radioAnswerRoofTileAttachmentCardboardDock: 'on cardboard pads',
          radioAnswerRoofTileAttachmentSlate: 'artificial/natural slate',
          radioAnswerRoofTileAttachmentPantile: 'hollow pan',
          initialData: 'Initial data',
          mainBuilding: 'Main building',
          secondaryBuilding: 'Secondary building',
          buildingsCountTextBold: 'Number of buildings',
          buildingsCountText: 'Amount of buildings to be occupied',
          flatRoofQuestion: 'Is the roof a flat roof?',
          photographTheFlatRoofAreaBeforeIndex: 'Please take a photo for ',
          photographTheFlatRoofAreaAfterIndex:
            ' where the entire roof area is visible.',
          photographTheRoofAreaBeforeIndex: 'Please take a photo where ',
          photographTheRoofAreaAfterIndex: ' is completely visible.',
          distanceGroundGutterTextBold: 'Eave height',
          distanceGroundGutterText: 'Enter the distance from ground to eaves',
          distanceGroundGutter: 'Enter the distance from ground to eaves',
          roofCoveringTitle: 'Roof covering',
          roofCovering: 'What type of roofing is it?',
          brick: 'Brick',
          trapezoidalRoof: 'Trapezoidal sheet',
          prefa: 'PREFA',
          icopal: 'Icopal',
          other: 'Other',
          bitumen: 'Bitumen',
          gravel: 'Gravel or green space',
          hohlpfanne: 'Hohlpfanne',
          pappdocken: 'Pappdocken',
          schiefer: 'Schiefer',
          otherRoofCovering: 'Other roof covering',
          otherRoofStructures: 'Other roof structures',
          whichRoofStructuresExistQuestion:
            'Which roof structures can be found?',
          roofTrussByCustomerKnown:
            'The roof truss cannot be seen. The technical roof construction is carried out by the client and is known to him.',
          roofCoveringTilesLoose: 'The tiles (roof covering) are loosely laid?',
          roofCoveringTilesNotApparent: 'Not apparent',
          roofCoveringAdditionalCostKnown:
            'It is known that there will be additional costs of €150 plus VAT per kWp.',
          chooseRoofTileAttachment: 'Choose attachment',
          notPossible: 'Not possible, ',
          brickMeasure: 'Brick measure',
          brickCoverage: 'Brick coverage (visible part)',
          executionSubstructure: 'Execution substructure',
          facilityCanNotBeBuilt: 'Plant cannot be built:',
          addPhotoOrVideo: 'Please add photo/video documentation.',
          costMayArise: 'Additional costs may arise.',
          checkboxLabel:
            'The types of execution for the substructure with an overhang are known and permissible.',
          roofStructureTitle: 'Roof structures',
          roofStructuresExist: 'Are there roof structures?',
          roofTrussVisibleBold: 'Roof truss',
          roofTrussVisibleQuestion: 'Can the roof truss be viewed?',
          beamDimensions: 'Enter beam dimensions (in cm)',
          barSpacing: 'a) Bar spacing',
          barWidth: 'b) Bar width',
          barHeight: 'c) Bar height',
          material: 'Material',
          roofTrussBeamMaterialWood: 'Wood',
          roofTrussBeamMaterialMetal: 'Metal',
          roofTrussBeamMaterialOther: 'Miscellaneous',
          broad: 'Broad',
          height: 'Height',
          radioYes: 'Yes',
          radioNo: 'No',
          enterMaterials: 'Enter materials',
          roofSurfaces: 'Roof surfaces',
          roofAreaVisualDocumentation: 'Roof area visual documentation',
          roofAmount: 'Amount of roof areas to be covered',
          roofAreaBold: 'Area size',
          roofAreaLengthLabel: 'Length of the roof surface',
          roofAreaWidthLabel: 'Width of the roof surface',
          roofAreaTiltLabel: 'Degrees of incline',
          cableTitle: 'DC cable routing',
          outerFacadeCable: 'Is a DC cable route on the outer facade possible?',
          roofSkinCable:
            'Is it possible to route DC cables through the roof skin?',
          cableLength:
            'Is the DC cable route from the house entrance to the storage/inverter location more than 10 m?',
          storageDistance:
            'How far away is the building with the storage/counter?',
          earthworkKnownQuestion:
            'It is known that earthworks may be necessary here. These are to be carried out by the customer.',
          cableQuestionYesCheckboxLabel:
            'It is known that there are additional costs for the DC overvoltage protection of €599 plus VAT. Possible earthworks are to be taken over by the customer.',
          aluminiumLength:
            'Aluminum equal to or greater than 0.7mm in thickness',
          steelLength: 'Steel equal to or greater than 0.5 mm in thickness',
          aluminiumNotBuildable: 'Aluminum less than 0.7mm thickness',
          steelNotBuildable: 'Steel less than 0.5mm thickness',
          flashUnitExists: 'Flash system',
          satelliteDishExists: 'Satellite dish',
          pipesExist: 'Tube',
          otherRoofStructuresExist: 'Other',
          parapetExistsQuestion: 'Attica available?',
          parapetSizes: 'Size (in cm)',
          parapetWidthLabel: 'Width (a)',
          parapetHeightLabel: 'Height (b)',
          dcCableRoutingAlongRoofCladdingVisualDocumentation:
            'Photo/Video documentation',
          dcCableRoutingAlongOutsideFacadeVisualDocumentation:
            'Photo/Video documentation',
          flatRoofVisualDocumentation: 'Photo/Video documentation',
        },
        scaffold: {
          obstaclesToScaffoldSafetyExist:
            'Are there any obstacles (e.g. pond, cellar steps, flower beds, ditches) that prevent the scaffold from standing safely?',
          yes: 'Yes',
          no: 'No',
          obstacle: 'Obstacle',
          scaffoldPosition: 'Scaffold position',
          scaffoldLength: 'Scaffold length',
          scaffoldObstacleImageIdsSubtitle: 'Additional costs may arise.',
          scaffoldObstacleImageIdsItem: 'Add photos/videos',
          buildingFacadeInsulated: 'Is the building facade insulated?',
          anchoring: 'Scaffolding anchorage',
          anchoragePossiblyNeededKnown:
            'It is known that if the eaves height is more than four metres, anchoring in the building façade might be necessary.',
          isScaffoldFootprintOnPublicFootpath:
            'Is the footprint of the scaffold on public footpaths?',
          isScaffoldBlockingPublicAccess:
            'Would the scaffold erection block a public access (such as an underground car park)?',
          scaffoldOnPublicFootpathFrontendTitle:
            'Would the scaffolding block a public driveway (such as an underground car park)?',
          scaffoldNotFromEkdConditionsKnown:
            "It is known that the client himself has to provide scaffolding that conforms to the employers' liability insurance association (BG). In the event of insufficient scaffolding, additional costs may be charged. In the event that the client provides the scaffolding, longer standstill times due to assembly delays cannot be charged.",
          scaffoldFromEkd:
            'Is the framework provided by Energy Concepts Germany?',
          scaffoldSide: 'On which sides is the scaffolding planned?',
          markScaffolding: 'Please mark scaffolding in the graphic.',
          showScaffold: 'Show scaffold',
          editScaffold: 'Edit scaffold',
          addScaffold: 'Add scaffold',
          markPosition: 'Tap the desired position to mark it.',
          moreNotes: 'Further notes and advice for the scaffolder',
          notesForScaffolder: 'enter notes here',
          scaffoldLengthsA: 'Length scaffold',
          enterLength: 'Enter length',
          permitByCustomerKnown:
            'It is known that necessary permissions have to be obtained by the client.',
          scaffoldPublicObstacleVisualDocumentation:
            'Photo/Video documentation',
          scaffoldObstacleVisualDocumentation: 'Photo/Video documentation',
        },
        consumption: {
          title: 'Consumption',
          outputAndStorage: 'Performance and storage',
          propertyDesiredPvOutputKwp: 'Desired power of the DC system',
          propertyDesiredPvOutputKwpPlaceholder: 'Perfomance',
          propertyDesiredBssCapacityKwh: 'Desired memory size',
          propertyDesiredBssCapacityKwhPlaceholder: 'Memory size',
          propertyDesiredBss: 'Desired memory',
          propertyDesiredBssPlaceholder: 'Desired memory',
          light: 'Light',
          propertyHouseElectricityConsumptionKwhPerYear:
            'Light electricity consumption per year',
          propertyHouseElectricityConsumptionKwhPerYearPlaceholder:
            'Light power consumption',
          propertyHouseElectricityPriceCentsPerKwh:
            'Light electricity price (gross) per kWh',
          propertyHouseElectricityPriceCentsPerKwhPlaceholder:
            'Light electricity price',
          propertyHouseElectricityBasicFeeEuroPerYear:
            'Basic light fee (gross) - per year',
          propertyHouseElectricityBasicFeeEuroPerYearPlaceholder:
            'Light basic fee',
          propertyHouseElectricityMeterNumber: 'Light electricity meter number',
          propertyHouseElectricityMeterNumberPlaceholder:
            'Light electricity meter number',
          warmth: 'Warmth',
          propertyHeatElectricityConsumptionKwhPerYear:
            'Heat electricity consumption per year',
          propertyHeatElectricityConsumptionKwhPerYearPlaceholder:
            'Heat power consumption',
          propertyHeatElectricityPriceCentsPerKwh:
            'Heat electricity price (gross) per kWh',
          propertyHeatElectricityPriceCentsPerKwhPlaceholder:
            'Heat electricity price',
          propertyHeatElectricityBasicFeeEuroPerYear:
            'Heat basic fee (gross) - per year',
          propertyHeatElectricityBasicFeeEuroPerYearPlaceholder:
            'Heat base charge',
          propertyHeatElectricityMeterNumber: 'Heat electricity meter number',
          propertyHeatElectricityMeterNumberPlaceholder:
            'Heat electricity meter number',
          loadProfile: 'Load profile',
          propertyLoadprofileId: 'Load profile',
          private: 'Private',
          commercial: 'Commercial',
          yearly: 'Yearly value',
          monthly: 'Monthly value',
        },
        conclusion: {
          areaOfResponsibility: 'Area of responsibility',
          date: 'Date',
          location: 'Location',
          signature: 'Signature',
          customerSignature: 'Signature customer',
          addSignature: 'Add signature',
          editSignature: 'Edit signature',
          selectDate: 'Please select date',
          assemblyWithEkdComponentsKnown:
            'I hereby confirm that I have taken note of the fact that the installation company of Energiekonzepte Deutschland GmbH will set up a compliant connection point that meets the technical connection requirements of the network operator. The commissioned installation company only installs the components purchased by the customer from Energiekonzepte Deutschland GmbH. The renewal of the Existing electrics are not part of the scope of services of Energiekonzepte Deutschland GmbH, this is the responsibility of the customer.',
        },
        energyStorageAndMeter: {
          multilinePlaceholder: 'Please describe',
          energyStorageAndMeterTitle: 'Storage and meter cabinet',
          whichFloor:
            'On what floor is the planned location for the storage/inverter?',
          secondFloor: '2. Floor',
          firstFloor: '1. Floor',
          groundFloor: 'Ground floor',
          basement: 'Basement',
          meterCabinetFloor: 'What floor is the meter cabinet on?',
          locationStorage: 'Location storage',
          wholeStorageShouldBeVisible:
            'The entire location of the storage must be recognizable.',
          wholeMeterCabinetShouldBeVisible:
            'Entire meter cabinet and meter should be recognizable.',
          isAtticAccessEnough:
            'Is access to the attic space at least 80 x 55 cm?',
          isDistanceEnough:
            'Please confirm that the distances for the memory are observed.',
          energyStorageWaterMeterDistanceSufficientCheckbox:
            'The distance to the water meter is at least 0.50 - 0.80 m (frontal)',
          energyStorageHouseConnectionBoxDistanceSufficientCheckbox:
            'Distance to house connection box is at least 1.20 m (frontal)',
          energyStorageWaterMeterCabinetDistanceSufficientCheckbox:
            'The distance to the meter cabinet is at least 1.20 m (frontal)',
          spaceRequirements: 'The space requirements (see info button) are met',
          standingAgainstTheWall: 'standing and against the wall',
          onlyOnWall: 'only on the wall',
          onlyStanding: 'only standing',
          energyStorageRequirementsHighPressureGasPipeChangeKnownCheckbox:
            "It is known that the minimum distances for high-pressure gas lines increase to 0.40 m at crossings and 1.50 m for parallel laying. A reduction in the distances is only possible after consultation with the network operator's representative.",
          canLocationMaintainTemperature:
            'The location can maintain an operating temperature of +0 °C to +40 °C.',
          meterCabinet: 'Meter cabinet and meter',
          isMeterCabinetSizeEnough:
            'Is a meter cabinet with a minimum size of 60 x 110 cm installed?',
          isAdditionalMeterCabinetNeeded:
            'Is an additional sub-/intermediate meter cabinet necessary?',
          additionalEnergySubMetersCount:
            'Additional necessary sub-/intermediate meters count',
          isThereAnEquipotentialBondingBar:
            'Is there an equipotential bonding bar including a foundation earth electrode?',
          notApparent: 'Not apparent',
          meterConcept: 'Meter concept',
          electricityLength: 'Is there more than one electricity meter?',
          otherElectricityMeters:
            'Are there other electricity meters over 5 kW (electric charge)?',
          finalEnergyMeterInformationKnownCheckbox:
            'It is known that the assembly company is realizing the construction project according to the specified meter concept. The merging takes place immediately before the electricity meter is changed by the network operator. If the network operator wishes the installation company commissioned by Energiekonzepte Germany to be present for the installation of the new electricity meter, the client is obliged to reimburse Energiekonzepte Deutschland for the additional costs incurred as a result up to the amount of € 350 plus VAT against proof. In the course of the AC installation, the house connection box is checked. In rare cases, this may have to be replaced/replaced. The costs to the network operator are to be borne by the client.',
          radioYes: 'Yes',
          radioNo: 'No',
          storageMountPlace:
            'Can the storage unit/inverter be mounted in the room of the meter cabinet?',
          toTwoRoomsAway: ', 1-2 rooms away',
          moreThanTwoRoomsAway: ', more than 2 rooms away',
          isCableLengthEnough:
            'Is a cable length between storage tank and meter of more than 20 m required?',
          energyStorageAndMeterCableDistanceCostsKnownCheckbox:
            'It is known that there are additional costs depending on the cable length: 20-30 m: 550,- € plus VAT 30-40 m: 1.100,- € plus VAT 40-50 m: 1.650,- € plus VAT > 50 m: 55,- € per metre plus VAT',
          isThereAdditionalSpace:
            'Is there space for an additional meter cabinet (minimum size 60 x 110 cm)?',
          inputNumber: 'Input number',
          piece: 'Piece',
          additionalEnergySubMeterCostsKnownCheckbox:
            'It is known that additional costs of 500,- € per piece plus VAT arise.',
          additionalCostsForEquipotentialBondingRailKnownCheckbox:
            'It is known that additional costs of € 1,000 plus VAT may arise.',
          energyMetersCount: 'Existing electricity meter number',
          canMergeElectricityMeters:
            'Should all electricity meters be merged into one electricity meter?',
          whichElectricityMetersAreAffected:
            'Which electricity meters are affected?',
          energyMeterElectronicCarAffectedCheckbox: 'E-Auto',
          energyMeterPoolAffectedCheckbox: 'Pool',
          energyMeterElectronicNightStorageHeatingAffectedCheckbox:
            'Night storage heating',
          energyMeterElectronicHeatPumpAffectedCheckbox: 'Heating pump',
          energyMeterGeyserAffectedCheckbox: 'Instantaneous water heater',
          energyMeterOtherAffectedCheckbox: 'Other',
          outputPerYear: 'Output per year',
          sonstige: 'Other',
          availableElectricityMeters: 'Which electricity meters are available?',
          otherHighChargeEnergyMeterOtherHouseholdCheckbox: 'Further budget',
          otherHighChargeEnergyMeterBusinessCheckbox: 'Business',
          otherHighChargeEnergyMeterElectronicHeatPumpCheckbox: 'Heating pump',
          otherHighChargeEnergyMeterGeyserCheckbox:
            'Instantaneous water heater',
          energyStorageSleepModeBeyondOperatingTemperatureKnownCheckbox:
            'It is known that EKD advises the setup of a system between +0 and +30°C and that the performance beyond this interval can be reduced.',
          energyMeterExtraCostWhenCombinedKnownCheckbox:
            'It is known that if more than three electricity meters are combined, additional costs of € 8,900 plus VAT will be incurred.',
          energyMeterOtherUnaffectedDescription:
            'Description of counters remaining',
          energyMeterOtherAffectedDescription:
            'Description of other energy meters above 5 kW',
          energyStorageMeterVisualDocumentation:
            'Energy storage visual documentation',
          spaceForAdditionalEnergyMeterCabinetVisualDocumentation:
            'Meter cabinet visual documentation',
          energyMeterCabinetVisualDocumentation: 'Photo/Video documentation',
        },
        currentChecklist: {
          checklist: 'Checklist',
          photovoltaicSystemExists:
            'Do you already have a photovoltaic system?',
          powerGeneratingPlantExists: 'Are there other generation plants?',
          solarThermalSystemExists: 'Solar thermal available?',
          wallboxDesired: 'Do you want a wall box?',
          internetConnection: 'Internet connection',
          customerDlanExists: 'Does the customer have D-LAN?',
          internetConnectionForEnergyStorageRequiredKnown:
            'It is known that an Internet connection (LAN) must be present in the memory space.',
          powerInverterManufacturer: 'Inverter manufacturer',
          powerInverterType: 'Inverter type',
          powerInverterCount: 'Number of inverters',
          powerInverterOutput: 'Inverter performance',
          existingPhotovoltaicSystemCommissioningDate: 'Commissioning / Date',
          existingPhotovoltaicSystemPeakOutput: 'Size of the plant:',
          existingPhotovoltaicSystemExcessFeedIn: 'Type of feed?',
          excessFeedIn: 'Excess feed-in',
          fullFeed: 'Full feed',
          existingPhotovoltaicSystemShouldGetIntegrated:
            'Should this be integrated into self-consumption? (Note: There are additional costs for integration.)',
          heatPumpSystemExists: 'Heat pump available?',
          heatPumpSgReadyInterfaceExists: 'Is there an SG-Ready interface?',
          existingHeatPumpSystemShouldGetIntegrated:
            'Should the prerequisites for integrating the heat pump into the energy management system be created?',
          adjustmentsToHeatPumpSystemAdditionalCostKnownTitle:
            'There are additional costs of €149 (net)',
          windPowerPlantExists:
            'What facilities are there? (Note: There are additional costs for integration.)',
          windkraft: 'Wind power',
          blockheizkraftwerk: 'Combined heat and power plant',
          sonstiges: 'Miscellaneous',
          otherPowerPlantDescription: 'Miscellaneous',
          otherPowerPlantDescriptionTitle:
            'Description other power generating plants',
          existingGeneratingPlantShouldGetMonitoringIntegratedTitle:
            'Should the generation plant be integrated into monitoring?',
          generatingPlantMonitoringAdditionalCostKnownTitle:
            'There will be additional costs of €95 (net)',
          adjustmentsToSolarThermalSystemKnown:
            'The client is aware that any offsetting or dismantling including disposal will be carried out by the client.',
          infoBold: 'Please mark the wallbox in the graphic.',
          infoText: 'Tap to mark the desired position.',
          listItem: 'Add wall box',
          smartHome: 'Do you want a Smart Home (Homematic) installation?',
          notInterested: 'No interest',
          heaterOnly: 'Radiators only',
          windowsCount: 'Number of windows',
          heatersCount: 'Number of radiators',
          valvesCount: 'Number of valves',
          photoVideoTitle: 'Please add photo/video documentation.',
          photoVideoValvesSubtitle1:
            'Manufacturer of valves must be recognizable.',
          photoVideoSubtitle1: 'Manufacturer must be recognizable.',
          photoVideoSubtitle2:
            'One photo should be stored for each mailing list.',
          photoVideoLabel: 'Edit photos/videos',
          heatingCircuitDistributorsCount:
            'Number of heating circuit distributors',
          roomControllersCount: 'Number of room controllers',
          underfloorHeatingOnly: 'Underfloor heating only',
          heaterAndUnderfloorHeating: 'Radiators and underfloor heating',
          distanceWallboxEnergyMeterCabinetSufficient:
            'Is the distance to the meter cabinet more than 10 m (not as the crow flies)?',
          distanceWallboxEnergyMeterCabinetTitle:
            'Estimated distance to meter cabinet',
          distanceWallboxEnergyMeterCabinet: 'Estimated distance',
          distanceWallboxEnergyMeterCabinetAdditionalCostsKnown:
            'The client is aware that there will be additional costs of €40 per meter plus VAT.',
          customerDlanModel: 'Model',
          showWallbox: 'Show wall box',
          editWallbox: 'Edit wall box',
          addWallbox: 'Add wall box',
          dlanConnectionWithPowerlineKnown:
            'It is known that the connection is made via a D-LAN Powerline adapter. If another Powerline adapter is available, communication problems may arise.',
          valvesVisualDocumentation: 'Valves visual documentation',
          heatingCircuitDistributorsVisualDocumentation:
            'Please add photo/video documentation.',
          roomControllersVisualDocumentation:
            'Please add photo/video documentation.',
        },
        connections: {
          houseConnectionBox: 'House connection box (HAK)',
          hakSubtitle: 'HAK',
          totalHakNumber:
            'How many HAK are there in total on the property and in the house?',
          hakLocation: 'Where is HAK 1 located',
          houseConnectionBoxVisualDocumentation:
            'House connection box visual documentation',
          atHome: 'at the house',
          inTheHouse: 'in the house',
          atThePropertyLine: 'on the property line',
          addPhotoVideo: 'Please add photo/video documentation.',
          photographTheFuseSizeBeforeIndex:
            'Please photograph a close-up and the fuse size for ',
          photographTheFuseSizeAfterIndex: ' .',
          editPhotoVideo: 'Edit photos/videos',
          hakFreelyAccessibleBeforeIndex: 'Is HAK',
          hakFreelyAccessibleAfterIndex:
            'freely accessible or is there freedom to build?',
          isHakFreelyAccessible:
            'Is the HAK freely accessible or is there freedom to build?',
          radioYes: 'Yes',
          radioNo: 'No',
          lineType: 'Overhead line / underground cable',
          whatIsTheCableType:
            'Are there overhead lines on the building facade or underground cables?',
          whereIsHakOneLocatedBeforeIndex:
            'On which floor and in which room is HAK',
          whereIsHakOneLocatedAfterIndex: 'located?',
          hakLocationInHouseDescription:
            'On which floor and in which room is the HAK located?',
          describe: 'Please describe',
          openOnAssemblyDateKnownCheckbox:
            'The client is aware that the HAK must be accessible and open on the assembly date. Any additional costs that may arise will be borne by the client.',
          isolationApplicationRequiredByCustomerKnownCheckbox:
            'It is known that in the case of an overhead line, insulation (if not available) must be applied for by the client from the grid operator. This must take place before the DC installation of the PV system.',
          newConnectionBoxApplicationRequiredByCustomerKnownCheckbox:
            'It is known that the client must apply for a new HAK from the network operator and bear the costs for this.',
          additionalCostMayArise: 'Additional costs may arise.',
          undergroundCable: 'Underground cable (a)',
          overheadLine: 'Overhead Line (b)',
          overheadLineVisualDocumentation:
            'Please add photo/video documentation.',
          undergroundCableFrontendLabel: 'Underground cable',
          overheadLineFrontendLabel: 'Overhead Line',
        },
      },
      note: {
        assignedSales: 'associated sales',
        calendarEntryType: 'Appointment Type',
        description: 'Description',
        dropdown: 'Dropdown',
        information: 'Information',
        leadNotChangeable: 'Lead cannot be changed',
        management: 'Management',
        noteType: 'Note Type',
        selectLead: 'Select Lead',
        type: {
          note: 'Note',
          closeNote: 'Note to complete appointment',
          reopenNote: 'Note to open appointment',
          minuteCall: 'Call Log',
          minuteMeeting: 'Meeting Minutes',
        },
        relatedCalendarEntry: 'Related Appointment',
        created: 'Note has been successfully created!',
      },
      leads: {
        leadsFromMyLeads: 'Leads from My Leads',
        myLeads: 'My Leads',
        searchMyLeads: 'Search my leads...',
        nearMe: 'My open leads nearby',
        noNearLeads: 'No leads nearby',
      },
      leadPhase: {
        all: 'All',
        callLater: 'Call later',
        delay: 'Delay',
        firstContact: 'First contact',
        leadFrom: 'Leads from',
        leads: 'Leads',
        measureDate: 'Measurement Date',
        myLeads: 'My Leads',
        notReached: 'None achieved',
        offerDone: 'Offer ready',
        offerInProcess: 'Offer in progress',
        offerMeeting: 'Offer Meeting',
        searchMyLeads: 'Search my leads...',
        worth: 'Value',
      },
      login: {
        about: 'Imprint',
        privacyProtection: 'Privacy',
        welcome: 'Welcome',
      },
      filter: {
        sort: 'Sort Leads',
        allEntries: 'All entries',
        onlyCalls: 'Calls only',
        onlyEmails: 'Emails only',
        onlyTasks: 'Tasks only',
        onlyMeeting: 'Meetings only',
        onlyMeasurementMeeting: 'Measurements only',
        allTime: 'Total time',
        today: 'Today',
        me: 'I',
        other: 'Other Sales',
        surname: 'Surname: A-Z',
        surnameDsc: 'Surname: Z-A',
        createdAt: 'Activity: Old-New',
        createdAtDsc: 'Activity: New-Old',
        entryType: 'Activity Type',
        time: 'Period',
        owner: 'Sales',
        searchOwner: 'Search sales...',
        onlyImages: 'Images only',
        onlyFiles: 'Files only',
        urgent: 'Overdue',
        state: 'Status',
        all: 'All',
        allMember: 'Whole team',
        searchUsers: 'Search for sales',
        fileType: 'File type',
      },
      imageCategories: {
        noCategory: 'No category',
        roofBuilding: 'Roof/Building',
        energyMeter: 'Meter cabinet/Counter',
        locationStorage: 'Location storage/Interior',
        locationWallbox: 'Wallbox location/Cable laying',
        houseConnectionBox: 'House connection box',
        obstacle: 'Obstacle',
        scaffoldObstacle: 'Scaffold obstacle',
        scaffoldPublicObstacle: 'Scaffold public obstacle',
        storageMeter: 'Storagemeter',
        meterCabinet: 'Metercabinet',
        spaceForAdditionalEnergyMeterCabinet:
          'Space for additional energy meter cabinet',
        valve: 'Valve',
        heatingCircuitDistributor: 'Heatingcircuit distributor',
        roomController: 'Roomcontroller',
        roofArea: 'Roofarea',
        flatRoof: 'Flatroof',
        roofStructures: 'Roofstructures',
        dcCableRoutingAlongOutsideFacade: 'DC cable routing outside facade',
        dcCableRoutingAlongRoofCladding: 'DC cable routing roof cladding',
        overheadLine: 'Overheadline',
        storageFacilityAtGroundLevel: 'Storage facility at ground level',
      },
      timelineDoneFilter: {
        allEntries: 'All entries',
        onlyCalendarEntries: 'Dates only',
        onlyNotes: 'Notes only',
        onlyMessages: 'Messages only',
      },
      addressSelect: {
        selectedAddress: 'Selected address',
        manualEntry: 'For manual input',
        autocompleteEntry: 'For automatic input',
        street: 'Street',
        number: 'No.',
        zip: 'ZIP CODE',
        locality: 'Place',
        country: 'Land',
        minSearch: '- Minimum 5 characters -',
      },
      labelListItem: {
        mobile: 'Mobile',
        private: 'Private',
        work: 'Work',
        placeholderPhoneNumber: 'Enter your phone number',
        placeholderEmailAddress: 'Enter your e-mail address',
      },
      satelliteImage: {
        savePhoto: 'Save photo only',
        addDrawing: 'Add a drawing',
        addText: 'Add a text',
        addTextPlaceholder: 'Enter a text and click on the map',
        image: 'Aerial photograph',
        removeAllLines: 'Remove all lines',
        removeAllTexts: 'Remove all texts',
        satelliteImageAdded: 'Satellite image has been successfully created!',
        satelliteImageUpdated: 'Satellite image has been successfully updated!',
      },
      imageFile: {
        image: 'Image',
        photo: 'Photo',
        satelliteImage: 'Aerial photograph',
        customImage: 'Custom image',
        sketch: 'Sketch',
        of: 'of',
        changeCategory: 'Change category',
        chooseCategory: 'Choose category',
      },
      misc: {
        of: 'from',
        done: 'done',
        description: 'Description',
        optional: 'optional',
        s2tUnavailable: 'Speech-to-text is not available',
        information: 'Information',
        details: 'Details',
        status: 'Status',
        version: 'Version',
        app: 'App',
        amount: 'Number',
        unspecified: 'Not specified',
        search: 'Browse leads...',
        unkownTeam: 'Unknown team',
        searchSales: 'Find Sales',
        notYetAvailable: 'Not yet available',
        empty: 'read',
        linkToMiddleware: 'Please log in to the Leads middleware',
        relatedLead: 'Linked Lead',
        appUpdateInfo: 'New app version is available',
        downloadUpdate: 'Click here to update the application',
        toggleAnimationGif: 'Congratulations-Animation of the won Leads',
        changesSaved: 'Changes saved successfully.',
        moreInfo: 'More info',
        shouldSaveAllLeadsToContacts: 'Add contact for all own open leads',
      },
      measurementProtocol: {
        comment: 'Comment',
        addPhotoVideo: 'Add photo/video',
        editPhotosVideos: 'Edit photos/videos',
        addPhotosVideos: 'Add photos/videos',
        addPhoto: 'Add photo',
        invalidImageType: 'Invalid image type selected',
        imageUploadError: 'Could not upload the image',
        showPhotosVideos: 'Show photos/videos',
        addVisualDocumentation: 'Please add photo/video documentation',
        addVisualDocumentationReadOnly: 'Photo/Video documentation',
        additionalCosts: 'Additional costs may arise.',
        itemPlaceholder: 'Wallbox location/cable laying',
        next: 'Continue',
        goToHomeScreen: 'Back to Home',
        neighboringProperty: 'Neighboring property',
        governmentRegulationsSpecifications:
          'The specifications of the state building regulations are known with regard to the distance to the neighboring property.',
        isTheDistanceKept:
          'Is the distance to the neighboring property maintained?',
        facilityCannotBeBuilt:
          'The facility cannot be built. The measurement sheet must be broken off.',
        initialAnalysis: 'Output only initial analysis sheet?',
        initialAnalysisProgressIndicatorNotFinished:
          'Initial analysis in progress',
        initialAnalysisProgressIndicatorFinished:
          'Initial analysis in successfully completed',
        inDistanceFromTheNeighbour:
          'The specifications of the state building regulations are known with regard to the distance to the neighboring property.',
        measurementItems: {
          consumption: 'Consumption',
          conclusion: 'Signature',
          connection: 'Connections',
          checklist: 'Checklist AC/DC',
          roof: 'Roof',
          scaffold: 'Scaffold',
          property: 'Property',
          obstacle: 'Obstacles',
          energyStorageAndMeter: 'Storage & Meter',
        },
        visualRepresentation: {
          title: 'Satellite image / Sketch',
          add: 'Add satellite image / Sketch',
          edit: 'Edit satellite image / Sketch',
          provide:
            'At this point, please take a representative aerial photo or provide a sketch.',
        },
        visualRepresentationOnImage: {
          title: 'Image / Sketch',
        },
        dialog: {
          backButton: 'Return',
          goHomeButton: 'To home page',
          errorDialogTitle: 'Plant cannot be built!',
          errorDialogMessage:
            'Please check that the information is correct. Otherwise the measurement sheet cannot be completed.',
          initialTitle: 'Initial analysis',
          initialMessage:
            'The initial analysis will help you to get a good quality offer in a timely manner. If you fill out the complete consultation protocol later, the data will be transferred.',
          addEditItemsTitle: 'Add/edit items',
          addEditItemsMessage:
            'To add items, just tap where you want them. Hold down the item to move it. To delete, tap on the element and select the desired function in the pop-up.',
          saveVisualRepresentation: 'Save aerial photo/sketch',
          saveVisualRepresentationExplanation:
            'At this point you can store an aerial photo, a drone shot or a sketch. Optionally, you have the option of adding all visible elements. To do this, select the desired element from the top bar and then mark it on the image. In the further steps of the consultation protocol, you can add to or edit the individual elements.',
          commentTitle: 'Comment - Plot',
          commentPlaceholder: 'Comment',
          consumption: {
            photographyTitle: 'Scan meter number',
            photographyMessage:
              'Here you can take photos of the existing electricity meters, after which the number will be scanned and automatically entered in the appropriate field. Please make sure that only the meter number is visible and that it is easily recognizable. The meter numbers are important for the powers of attorney with the network operators.',
          },
          roof: {
            mainBuildingTitle: 'Main Building & Outbuildings',
            mainBuildingMessage:
              'First of all, please enter the main building in which meters and storage are to be installed. Any outbuildings, e.g. E.g. a garage, please list them afterwards.',
            brickMeasureTitle: 'Brick coverage',
            brickMeasureMessage:
              'The width of the tile is the simple width of a roof tile. The tile coverage, on the other hand, only refers to the visible part of the roof tile that is not covered by other roof tiles.',
            executionSubstructureTitle: 'Execution substructure',
            executionSubstructureImageLabelA: 'Possible type of execution No.1',
            executionSubstructureImageLabelB: 'Possible type of execution No.2',
            roofStructuresTitle: 'Roof structures',
            roofStructuresMessage:
              'Here you can enter information about existing roof structures, such as chimneys, skylights, ventilation pipes, etc., and document them with a photo. These are taken into account in the planning.',
          },
          obstacle: {
            obstacleTitle: 'Obstacles',
            obstacleMessage:
              'Please look around the area around the roof and note any objects that could cause shading of the system. These include, for example, tall trees, neighboring buildings and the like.',
          },
          scaffold: {
            scaffoldObstacleTitle: 'Obstacles',
            scaffoldObstacleMessage:
              'The obstacles to erecting the scaffolding are documented here. This helps the assembly partners to prepare themselves.',
            publicWalkwayTitle: 'Public walkway',
            publicWalkwayMessage:
              'The picture above shows that a public footpath that is too close could impede the scaffolding. In this case, permits may be required, which the client must obtain.',
          },
          energyStorageAndMeter: {
            spaceRequirementsTitle: 'Space Requirements Storage',
            spaceRequirementsImageLabelA: 'Lineup',
            spaceRequirementsImageLabelB: 'Wall mounting',
            bondingBarTitle: 'Equipotential bonding rail',
          },
          connections: {
            hakPhotoDocumentationDialogTitle: 'HAK Photo documentation',
            hakPhotoDocumentationDialogText:
              'If possible, please photograph the house connection box openly and in such a way that the fuse size, at least 35 amperes, can be seen.',
          },
          deleteDialog: {
            description:
              'After you delete this item, it will be permanently deleted and cannot be recovered. Are you sure you want to delete this item?',
            confirm: 'Delete marks',
            cancel: 'Back',
            mode: {
              roof: 'Are you sure you want to delete all roof marks?',
              door: 'Are you sure you want to delete all door marks?',
              scaffold: 'Are you sure you want to delete all scaffold marks?',
              danger: 'Are you sure you want to delete all danger marks?',
              truck: 'Are you sure you want to delete all truck marks?',
              wallbox: 'Are you sure you want to delete all wallbox marks?',
            },
          },
        },
        buttons: {
          save: 'Save on computer',
          done: 'Done',
          block: 'To graduate',
          finishedAlready: 'Completed',
        },
        snackBarSuccessMessage: 'Section successfully completed',
        snackBarFinalSuccessMessage: 'Measurement sheet successfully completed',
      },
      confirmationEmail: {
        title:
          'Select the email of the lead to which the appointment confirmation should be sent:',
        noEmail: 'No email available on the lead.',
        confirmationToastText: 'The appointment has been created!',
      },
      globalSearch: {
        searchMinLettersNeeded: 'enter at least 3 letters',
        searchResultsEmpty: 'no results found',
      },
      message: {
        title: 'Internal message to planners',
        messageDetails: 'Note details',
        published: 'Sent',
        planning: 'Project planning',
        messageToPlanner: 'Internal message to planners',
        messageFromPlanner: 'Internal message from the planner',
        created: 'Message created successfully!',
      },
      countries: {
        AF: 'Afghanistan',
        AX: 'Åland',
        AL: 'Albania',
        DZ: 'Algeria',
        AS: 'American Samoa',
        AD: 'Andorra',
        AO: 'Angola',
        AI: 'Anguilla',
        AQ: 'Antarctica',
        AG: 'Antigua and Barbuda',
        AR: 'Argentina',
        AM: 'Armenia',
        AW: 'Aruba',
        AU: 'Australia',
        AT: 'Austria',
        AZ: 'Azerbaijan',
        BS: 'Bahamas',
        BH: 'Bahrain',
        BD: 'Bangladesh',
        BB: 'Barbados',
        BY: 'Belarus',
        BE: 'Belgium',
        BZ: 'Belize',
        BJ: 'Benin',
        BM: 'Bermuda',
        BT: 'Bhutan',
        BO: 'Bolivia',
        BA: 'Bosnia and Herzegovina',
        BW: 'Botswana',
        BR: 'Brazil',
        IO: 'British Indian Ocean Territory',
        BN: 'Brunei Darussalam',
        BG: 'Bulgaria',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        KH: 'Cambodia',
        CM: 'Cameroon',
        CA: 'Canada',
        CV: 'Cape Verde',
        KY: 'Cayman Islands',
        CF: 'Central African Republic',
        TD: 'Chad',
        CL: 'Chile',
        CN: 'China',
        CX: 'Christmas Island',
        CC: 'Coconut Islands',
        CO: 'Colombia',
        KM: 'Comoros',
        CG: 'Republic of the Congo',
        CD: 'Congo',
        CK: 'Cook Islands',
        CR: 'Costa Rica',
        CI: 'Ivory Coast',
        HR: 'Croatia',
        CU: 'Cuba',
        CY: 'Cyprus',
        CZ: 'Czech Republic',
        DK: 'Denmark',
        DJ: 'Djibouti',
        DM: 'Dominica',
        DO: 'Dominican Republic',
        EC: 'Ecuador',
        EG: 'Egypt',
        SV: 'El Salvador',
        GQ: 'Equatorial Guinea',
        ER: 'Eritrea',
        EE: 'Estonia',
        ET: 'Ethiopia',
        FK: 'Falkland Islands',
        FO: 'Faroe Islands',
        FJ: 'Fiji',
        FI: 'Finland',
        FR: 'France',
        GF: 'French Guiana',
        PF: 'French Polynesia',
        GA: 'Gabon',
        GM: 'Gambia',
        GE: 'Georgia',
        DE: 'Germany',
        GH: 'Ghana',
        GI: 'Gibraltar',
        GR: 'Greece',
        GL: 'Greenland',
        GD: 'Grenada',
        GP: 'Guadeloupe',
        GU: 'Guam',
        GT: 'Guatemala',
        GG: 'Guernsey',
        GN: 'Guinea',
        GW: 'Guinea-Bissau',
        GY: 'Guyana',
        HT: 'Haiti',
        VA: 'Vatican City',
        HN: 'Honduras',
        HK: 'Hong Kong',
        HU: 'Hungary',
        IS: 'Iceland',
        IN: 'India',
        ID: 'Indonesia',
        IR: 'Iran',
        IQ: 'Iraq',
        IE: 'Ireland',
        IM: 'Isle of Man',
        IL: 'Israel',
        IT: 'Italy',
        JM: 'Jamaica',
        JP: 'Japan',
        JE: 'Jersey',
        JO: 'Jordan',
        KZ: 'Kazakhstan',
        KE: 'Kenya',
        KI: 'Kiribati',
        KP: 'North Korea',
        KR: 'South Korea',
        KW: 'Kuwait',
        KG: 'Kyrgyzstan',
        LA: 'Laos',
        LV: 'Latvia',
        LB: 'Lebanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libya',
        LI: 'Liechtenstein',
        LT: 'Lithuania',
        LU: 'Luxembourg',
        MO: 'Macau',
        MG: 'Madagascar',
        MW: 'Malawi',
        MY: 'Malaysia',
        MV: 'Maldives',
        ML: 'Mali',
        MT: 'Malta',
        MH: 'Marshall Islands',
        MQ: 'Martinique',
        MR: 'Mauritania',
        MU: 'Mauritius',
        YT: 'Mayotte',
        MX: 'Mexico',
        FM: 'Micronesia',
        MD: 'Moldova',
        MC: 'Monaco',
        MN: 'Mongolia',
        ME: 'Montenegro',
        MS: 'Montserrat',
        MA: 'Morocco',
        MZ: 'Mozambique',
        MM: 'Myanmar',
        NA: 'Namibia',
        NR: 'Nauru',
        NP: 'Nepal',
        NL: 'Netherlands',
        NC: 'New Caledonia',
        NZ: 'New Zealand',
        NI: 'Nicaragua',
        NE: 'Niger',
        NG: 'Nigeria',
        NU: 'Niue',
        NF: 'Norfolk Island',
        MP: 'Northern Mariana Islands',
        NO: 'Norway',
        OM: 'Oman',
        PK: 'Pakistan',
        PW: 'Palau',
        PA: 'Panama',
        PG: 'Papua New Guinea',
        PY: 'Paraguay',
        PE: 'Peru',
        PH: 'Philippines',
        PN: 'Pitcairn Islands',
        PL: 'Poland',
        PT: 'Portugal',
        PR: 'Puerto Rico',
        QA: 'Qatar',
        RO: 'Romania',
        RU: 'Russian Federation',
        RW: 'Rwanda',
        RE: 'Reunion',
        BL: 'Saint-Barthelemy',
        SH: 'St. Helena',
        KN: 'Saint Kitts and Nevis',
        LC: 'Saint Lucia',
        MF: 'Saint Martin',
        PM: 'Saint-Pierre and Miquelon',
        VC: 'St. Vincent and the Grenadines',
        WS: 'Samoa',
        SM: 'San Marino',
        ST: 'Sao Tome and Principe',
        SA: 'Saudi Arabia',
        SN: 'Senegal',
        RS: 'Serbia',
        SC: 'Seychelles',
        SL: 'Sierra Leone',
        SG: 'Singapore',
        SK: 'Slovakia',
        SI: 'Slovenia',
        SB: 'Solomon Islands',
        SO: 'Somalia',
        ZA: 'South Africa',
        SS: 'South Sudan',
        GS: 'South Georgia and the South Sandwich Islands',
        ES: 'Spain',
        LK: 'Sri Lanka',
        SD: 'Sudan',
        SR: 'Suriname',
        SJ: 'Svalbard and Jan Mayen',
        SZ: 'Swaziland',
        SE: 'Sweden',
        CH: 'Switzerland',
        SY: 'Syria, Arab Republic',
        TW: 'Taiwan',
        TJ: 'Tajikistan',
        TZ: 'Tanzania, United Republic',
        TH: 'Thailand',
        TL: 'East Timor',
        TG: 'Togo',
        TK: 'Tokelau',
        TO: 'Tonga',
        TT: 'Trinidad and Tobago',
        TN: 'Tunisia',
        TR: 'Turkey',
        TM: 'Turkmenistan',
        TC: 'Turks and Caicos Islands',
        TV: 'Tuvalu',
        UG: 'Uganda',
        UA: 'Ukraine',
        AE: 'United Arab Emirates',
        GB: 'United Kingdom',
        US: 'United States of America',
        UY: 'Uruguay',
        UZ: 'Uzbekistan',
        VU: 'Vanuatu',
        VE: 'Venezuela',
        VN: 'Vietnam',
        VG: 'British Virgin Islands',
        VI: 'US Virgin Islands',
        WF: 'Wallis and Futuna',
        YE: 'Yemen',
        ZM: 'Zambia',
        ZW: 'Zimbabwe',
      },
    },
  },
};
