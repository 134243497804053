const getEnvName = () => {
  if (isDevMode) {
    return getDevSettings().host;
  }
  const host = window.location?.host;
  if (typeof host !== 'string') {
    return 'prod';
  }
  if (host.includes('test3')) {
    return 'test3';
  }
  if (host.includes('test1')) {
    return 'test1';
  }
  if (host.includes('staging1')) {
    return 'staging1';
  }
  if (host.includes('localhost')) {
    return 'dev';
  }
  return 'prod';
};

const isDevMode = process.env.NODE_ENV === 'development';

export const getDevSettings = () => {
  if (isDevMode) {
    return {
      host: 'test3',
      headers: {
        Authorization: `
        Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IjVCM25SeHRRN2ppOGVORGMzRnkwNUtmOTdaRSIsImtpZCI6IjVCM25SeHRRN2ppOGVORGMzRnkwNUtmOTdaRSJ9.eyJhdWQiOiJhcGk6Ly9kZDYzNGY5Yy03ZjcxLTRiNGQtOTc3Yy1jMTVhYjAyNzQxNGUiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC85MjNkZDhiMy0zZjc0LTQ3MGQtYmU3YS01NjY4OWFkY2UyMDMvIiwiaWF0IjoxNzA1MzI0OTgzLCJuYmYiOjE3MDUzMjQ5ODMsImV4cCI6MTcwNTMyOTMzMCwiYWNyIjoiMSIsImFpbyI6IkFUUUF5LzhWQUFBQUpxdEVoOFE2Z1h2cEp6dmtQVTZPMFl6K2ZxMzhWZFF4RXlXMUV1RHNHdk5VWFcvdHppL1d3NHhsNlhlbDlEQSsiLCJhbXIiOlsicHdkIl0sImFwcGlkIjoiNjEzNGU2NjktYmM4MS00ZTgxLWIxOTItMzM3ZjhiODM4MmM3IiwiYXBwaWRhY3IiOiIwIiwiZmFtaWx5X25hbWUiOiJRQTIiLCJnaXZlbl9uYW1lIjoiQXBwIiwiaXBhZGRyIjoiMmEwMDoyMDpiMDAzOmY2YWI6MmM0MDo4ZjIyOmY3MTU6MjI2YyIsIm5hbWUiOiJBcHAgUUEyIiwib2lkIjoiNDBhZjBkNDMtN2ZhZC00YWFhLWE2OTgtMDYyMmU4YjEwNDk1IiwicmgiOiIwLkFUd0FzOWc5a25RX0RVZS1lbFpvbXR6aUE1eFBZOTF4ZjAxTGwzekJXckFuUVU0OEFPay4iLCJzY3AiOiJTYWxlc0FwcC5BY2Nlc3MiLCJzdWIiOiJ6Q05aWGlIYlc0S2l1cUtJRGFsQzN1aEI5ck9zNUJtbGNvMnlPZnRaM3hRIiwidGlkIjoiOTIzZGQ4YjMtM2Y3NC00NzBkLWJlN2EtNTY2ODlhZGNlMjAzIiwidW5pcXVlX25hbWUiOiJhcHAucWEyQGVrZC1zb2xhci5kZSIsInVwbiI6ImFwcC5xYTJAZWtkLXNvbGFyLmRlIiwidXRpIjoiMVhwNWw2cmxIMHVkNk9zT05IRmtBQSIsInZlciI6IjEuMCJ9.jFD1nqG2KC-LLO_6RfFWozrOuyC6Z5LvT0hIn75Dc3hGEWILLhVKIKHCxC9KYS0SBzN0BlW_AIl5b6DFRRXqt4-ukB9XbTssLtO3Q92kz8HqfIvadcxvvUZ1WaPRbjzRdP_BpJpJCpeE7_XSc6lgWwP0Ij-z7dDvhUj49vMvhr5HJsGoKlBD-xncp8VWpw38IKL24291edRb2LT27XTc-7xM-Mf97ls0hmkivAGR5ItG6ZvcgBUQKFTTxiqNKcVEPcvU0_HPlg0QnCRE_QiPi_80GbeFqhPZGtJuNtl0Vwcsh4b3MQHszq4RAYctVd-mXeNedHpzUzIoIlkpVQJdLg
          `,
      },
      leadId: 317120,
      specialRoofCoverings: ['Pappdocken', 'Schiefer'],
    };
  }
  return {};
};

export const reactStrictMode = false;

const envName = getEnvName();

const salesBackendBaseUrl = {
  test1: 'https://sales-api.test1.leads-middleware.de',
  test3: 'https://sales-api.test3.leads-middleware.de',
  staging1: 'https://sales-api.staging1.leads-middleware.de',
  prod: 'https://sales-api.leads-middleware.de',
  dev: 'http://localhost:8080',
} as const;

export const salesBackendApi = {
  url: salesBackendBaseUrl[envName!],
  endpoint: {
    measurementProtocolFull: 'measurementProtocol/full',
    measurementProtocolPdf: 'measurementProtocol/pdf',
    measurementProtocol: 'measurementprotocol',
    measurementProtocolRoof: 'measurementprotocolroof',
    measurementProtocolHouseConnectionBox:
      'measurementprotocolhouseconnectionbox',
    measurementProtocolRoofArea: 'measurementprotocolroofarea',
    lead: 'lead',
    image: 'image',
    file: 'file',
  },
} as const;

const leadsMiddleWareBaseUrl = {
  test1: 'https://test1.leads-middleware.de/salesAPI/v1',
  test3: 'https://test3.leads-middleware.de/salesAPI/v1',
  staging1: 'https://staging1.leads-middleware.de/salesAPI/v1',
  prod: 'https://leads-middleware.de/salesAPI/v1',
  dev: 'https://staging1.leads-middleware.de/salesAPI/v1',
} as const;

export const leadsMiddlewareApi = {
  url: leadsMiddleWareBaseUrl[envName!],
  endpoint: {
    file: 'file',
  },
} as const;
